import React, { useCallback, useEffect, useState } from 'react'
import {Button, Card, Col, Dropdown, Input, Menu, Row, Select, Space, Tag, Tooltip, Typography} from 'antd'
import { SelectorSolo } from '../../components/Selectors'
import TableAnal from './components/anal'
import { defaultOnChangeLikeHide, setLikeHideOnLoadData } from '../components/LikeHide'
import { ButtonExel } from '../../components/Buttons'
import { CiCircleQuestion } from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import SideBarAnal from '../home/sideBar/SideBarAnal'
import {apiAxios} from "../../utils/api_axios";
import {useAuth} from "../../hooks/useAuth";
import {useSidebar} from "../../Context/SideBarContext";
import {applyAllFilters, handleQuickFilter, handleRemoveFilter, useFilterLogic} from "../components/filterUtils";
import {FilterOutlined, ReloadOutlined} from "@ant-design/icons";
import { useSelectorFilter } from '../../Context/SelectorFilterContext'

    const quickFilterButtons = [
        {key: 'showNegativeMargin', label: 'Отрицательная маржа', filter: item => item.marzha < 0},
        //{key: 'showZeroProfit', label: 'Чистый убыток', filter: item => item.profit + item.sebes < 0},
        {key: 'showNoSales', label: 'Нет заказов', filter: item => item.orders_per_day < 0.2 && item.ost > 10},
        {key: 'showLowStock', label: 'Низкий остаток', filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2},
        {key: 'showHighStock', label: 'Высокий остаток', filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2},
        {key: 'showLongTurnover', label: 'Долгая оборачиваемость', filter: item => item.oborot_orders > 60},
        {key: 'showLowBuyout', label: 'Низкий выкуп', filter: item => item.buyout_percent < 30 && item.buyout_percent > 0},
    ];

export default function Anal() {
    const { user } = useAuth()
    const {selectorFilter,setSelectorFilter} = useSelectorFilter()
    const { openSidebar } = useSidebar()
    
    const [cards, setCards] = useState([])
    const [isOzon, setIsOzon] = useState(true)
    const [loading, setLoading] = useState(true)
    const [filters_data, setFiltersData] = useState([])

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarAnal />)
    }, [openSidebar])

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids})
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id})
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await apiAxios.post('anal/get/', {
                'wbacc_ids': [selectorFilter.wbacc_id],
            });
            console.log('fetchedData', fetchedData)
            setCards(fetchedData['cards'])
             setFiltersData(fetchedData['filters']);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

     useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

        if (selectorFilter.wbacc_id) {
            selectorFilter.wbacc_id > 0 ? setIsOzon(false) : setIsOzon(true)
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id])

    const [activeFilters, setActiveFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {setDebouncedSearchText(searchText);}, 1000);
        return () => {clearTimeout(handler);};
    }, [searchText]);
    
    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showZeroProfit: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false,
        showNoPlan: false,
    });


    const filterMenu = <Menu items={useFilterLogic(
        filters_data,
        setActiveFilters
    )}/>;
    const filteredData = applyAllFilters(cards, quickFilters, quickFilterButtons, activeFilters, debouncedSearchText)
    return (
        <>
            <Card>
                <Typography.Title level={1}>Аналитика</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <Select
                            style={{width: '100%'}}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>


                    <ButtonExel data={cards} />
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar />
            <Card>
                <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                    <Space wrap>
                        <Input placeholder="Поиск по артикулу" value={searchText} onChange={(e) => {setSearchText(e.target.value);}}/>
                        <Dropdown overlay={filterMenu} trigger={['click']}><Button icon={<FilterOutlined/>}>Добавить фильтр</Button></Dropdown>
                        <Button icon={<ReloadOutlined/>} onClick={fetchData}>Обновить</Button>
                        {activeFilters.map((filter, index) => (
                            <Tag key={index} closable onClose={() => handleRemoveFilter(index, setActiveFilters)}>{`${filter.type}: ${filter.value.label}`}</Tag>
                        ))}
                    </Space>
                    <Space wrap>
                        {quickFilterButtons.map(button => (
                            <Button key={button.key} type={quickFilters[button.key] ? 'primary' : 'default'}
                                    onClick={() => handleQuickFilter(button.key, quickFilters, setQuickFilters)}>{button.label}</Button>
                        ))}
                    </Space>
                </Space>
                <TableAnal cards={filteredData} loading={loading} isOzon={isOzon} is_our={user.is_our} />
            </Card>
        </>
    )
}
