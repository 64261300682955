import React, {useEffect, useState} from 'react'
import {Card, Col, Row, Select, Tooltip, Typography, Space, Menu, Input, Dropdown, Button, Tag} from 'antd'
import {useAuth} from '../../hooks/useAuth'
import {apiAxios} from "../../utils/api_axios";
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import {ShopOutlined, QuestionCircleOutlined, FilterOutlined, ReloadOutlined} from '@ant-design/icons'
import SideBarPlanFact from "../home/sideBar/SideBarPlanFact";
import dayjs from "dayjs";
import DatePickerRange from "../../components/dateRange";
import {PlanFactTable3} from "./components/PlanFactTableV3";
import {applyAllFilters, handleQuickFilter, handleRemoveFilter, useFilterLogic} from "../components/filterUtils";
import * as XLSX from "xlsx";
import { useSelectorFilter } from '../../Context/SelectorFilterContext';

export default function PlanFactV3() {
    const {user} = useAuth()
    const {selectorFilter,setSelectorFilter} = useSelectorFilter()
    const {openSidebar} = useSidebar()
    
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [sum_stat, setSumStat] = useState([])
    const [selected_hide, setSelectedHide] = useState(2)
    const [selected_like, setSelectedLike] = useState(1)
    const startDate = dayjs().date() == 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month')
    const [period, setPeriod] = useState([startDate, dayjs().add(-1, 'd')])
    const [isOzon, setIsOzon] = useState(true)
    const [loading, setLoading] = useState(true)
    const [drrType, setDrrType] = useState('orders')

    const filter_keys = ['ost', 'way_to', 'way_from', 'full', 'ms_ost', 'orders_sum', 'orders_per_day', 'orders', 'oborot_orders', 'marzha']
    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids})
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id})
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const handleOpenSidebar = () => {
        openSidebar(<SideBarPlanFact/>);
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await apiAxios.get('plan-fact/get/', {
            'wbacc_ids': [selectorFilter.wbacc_id],
            'is_like': 1,
            'is_hide': 2,
            'st': period[0].format('YYYY-MM-DD'),
            'en': period[1].format('YYYY-MM-DD'),
        });
            setFiltersData(fetchedData['filters']);

            setCards(fetchedData['cards']);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

        if (selectorFilter.wbacc_id) {
            selectorFilter.wbacc_id > 0 ? setIsOzon(false) : setIsOzon(true)
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id, selected_hide, selected_like, period])

    const [activeFilters, setActiveFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {setDebouncedSearchText(searchText);}, 1000);
        return () => {clearTimeout(handler);};
    }, [searchText]);
    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showZeroProfit: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false,
        showNoPlan: false,
    });

    const quickFilterButtons = [
        {key: 'showNegativeMargin', label: 'Отрицательная маржа', filter: item => item.marzha < 0},
        //{key: 'showZeroProfit', label: 'Чистый убыток', filter: item => item.profit + item.sebes < 0},
        {key: 'showNoSales', label: 'Нет заказов', filter: item => item.orders_per_day < 0.2 && item.ost > 10},
        {key: 'showLowStock', label: 'Низкий остаток', filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2},
        {key: 'showHighStock', label: 'Высокий остаток', filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2},
        {key: 'showLongTurnover', label: 'Долгая оборачиваемость', filter: item => item.oborot_orders > 60},
        {key: 'showLowBuyout', label: 'Низкий выкуп', filter: item => item.buyout_percent < 30 && item.buyout_percent > 0},
    ];

    const filterMenu = <Menu items={useFilterLogic(
        filters_data,
        setActiveFilters
    )}/>;
    const filteredData = applyAllFilters(cards, quickFilters, quickFilterButtons, activeFilters, debouncedSearchText)
    const downloadExcel = () => {
        // Получаем все уникальные даты
        const dates = new Set();
        cards.forEach(card => {
            if (card.by_days) {
                Object.keys(card.by_days).forEach(date => dates.add(date));
            }
        });

        // Создаем заголовок с датами
        const dateHeaders = {};
        const fieldsHeader = {};
        dates.forEach(date => {
            // Дата
            dateHeaders[`${date} Штук`] = date;
            dateHeaders[`${date} Рублей`] = date;
            dateHeaders[`${date} Маркетинг`] = date;
            dateHeaders[`${date} ДРР`] = date;
            dateHeaders[`${date} CTR`] = date;
            dateHeaders[`${date} Оборачиваемость`] = date;
            dateHeaders[`${date} Выкуп`] = date;
            dateHeaders[`${date} Цена`] = date;

            // Названия полей
            fieldsHeader[`${date} Штук`] = 'Штук';
            fieldsHeader[`${date} Рублей`] = 'Рублей';
            fieldsHeader[`${date} Маркетинг`] = 'Маркетинг';
            fieldsHeader[`${date} ДРР`] = 'ДРР';
            fieldsHeader[`${date} CTR`] = 'CTR';
            fieldsHeader[`${date} Оборачиваемость`] = 'Обор.';
            fieldsHeader[`${date} Выкуп`] = 'Выкуп';
            fieldsHeader[`${date} Цена`] = 'Цена';
        });

        const excelData = cards.map(card => {
            const baseData = {
                // Товар
                'Артикул': card.article,
                'Артикул WB': card.nm_id,
                'Название': card.name,
                'Карточка': card.imt_id,

                // Информация о товаре
                'Модель': card.material,
                'Бренд': card.brand,
                'Категория': card.category,
                'Статус': card.status,
                'Сезон': card.sezon,
                'Цвет': card.color,

                // Складские остатки
                'Всего остатков': card.ost + (card.way_to || 0),
                'FBO': card.ost,
                'В пути от': card.way_from || 0,
                'В пути до': card.way_to || 0,
                'Себестоимость остатков': card.sebes * card.full,
                'Деньги в товаре': card.ost_sum,

                // Другие остатки
                'Остаток МС': card.ms_ost || 0,
                'Транзит': card.ms_transfer || 0,
                'Шьется': card.ms_production || 0,
            };

            // Добавляем статистику по дням
            if (card.by_days) {
                Object.entries(card.by_days).forEach(([date, dayData]) => {
                    baseData[`${date} Штук`] = dayData.orders;
                    baseData[`${date} Рублей`] = dayData.orders_sum;
                    baseData[`${date} Маркетинг`] = dayData.budget;
                    baseData[`${date} ДРР`] = dayData.drr;
                    baseData[`${date} CTR`] = dayData.ctr;
                    baseData[`${date} Оборачиваемость`] = dayData.oborot_orders;
                    baseData[`${date} Выкуп`] = dayData.buyout_percent;
                    baseData[`${date} Цена`] = dayData.price_with_discount;
                });
            }

            return baseData;
        });

        // Добавляем строки с датами и названиями полей в начало массива
        excelData.unshift(
            // Строка с названиями полей
            {
                'Артикул': 'Поле',
                'Артикул WB': '',
                'Название': '',
                'Карточка': '',
                'Модель': '',
                'Бренд': '',
                'Категория': '',
                'Статус': '',
                'Сезон': '',
                'Цвет': '',
                'Всего остатков': '',
                'FBO': '',
                'В пути от': '',
                'В пути до': '',
                'Себестоимость остатков': '',
                'Деньги в товаре': '',
                'Остаток МС': '',
                'Транзит': '',
                'Шьется': '',
                ...fieldsHeader
            },
            // Строка с датами
            {
                'Артикул': 'Дата',
                'Артикул WB': '',
                'Название': '',
                'Карточка': '',
                'Модель': '',
                'Бренд': '',
                'Категория': '',
                'Статус': '',
                'Сезон': '',
                'Цвет': '',
                'Всего остатков': '',
                'FBO': '',
                'В пути от': '',
                'В пути до': '',
                'Себестоимость остатков': '',
                'Деньги в товаре': '',
                'Остаток МС': '',
                'Транзит': '',
                'Шьется': '',
                ...dateHeaders
            }
        );

        // Создаем книгу Excel
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Настраиваем ширину столбцов
        const maxWidth = 20;
        const colWidths = {};
        Object.keys(excelData[0] || {}).forEach(key => {
            colWidths[key] = Math.min(
                maxWidth,
                Math.max(
                    key.length,
                    ...excelData.map(row => String(row[key] || '').length)
                )
            );
        });
        ws['!cols'] = Object.values(colWidths).map(width => ({wch: width}));

        // Добавляем форматирование для заголовков с датами
        const range = XLSX.utils.decode_range(ws['!ref']);
        const dateColumns = new Set();

        // Находим колонки с датами
        Object.keys(dateHeaders).forEach(key => {
            const col = Object.keys(excelData[0]).indexOf(key);
            if (col !== -1) {
                dateColumns.add(col);
            }
        });

        // Создаем стили для ячеек
        if (!ws['!merges']) ws['!merges'] = [];
        if (!ws['!styles']) ws['!styles'] = [];

        // Применяем жирный стиль к колонкам с датами
        for (let col = 0; col <= range.e.c; col++) {
            if (dateColumns.has(col)) {
                const cellRef = XLSX.utils.encode_cell({r: 0, c: col}); // Строка с названиями полей
                const cellRef2 = XLSX.utils.encode_cell({r: 1, c: col}); // Строка с датами

                if (!ws['!styles']) ws['!styles'] = {};
                ws['!styles'][cellRef] = {font: {bold: true}, fill: {fgColor: {rgb: "EFEFEF"}}};
                ws['!styles'][cellRef2] = {font: {bold: true}, fill: {fgColor: {rgb: "EFEFEF"}}};
            }
        }

        // Добавляем лист в книгу
        XLSX.utils.book_append_sheet(wb, ws, "План-факт");

        // Скачиваем файл
        XLSX.writeFile(wb, `План_факт_${new Date().toLocaleDateString()}.xlsx`);
    };
    return (
        <div className="pricing-container">
            {/* Header Section */}
            <Card className="mb-4">
                <Row gutter={[16, 16]} align="middle">
                    <Col xs={24} lg={12}>
                        <Space align="center">
                            <Typography.Title level={2}>План-факт</Typography.Title>
                            <Tooltip title="Информация">
                                <QuestionCircleOutlined
                                    onClick={handleOpenSidebar}
                                    style={{
                                        fontSize: '24px',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s',
                                    }}
                                    className="hover:rotate-180 active:scale-90"
                                />
                            </Tooltip>
                        </Space>
                    </Col>

                </Row>

                {/* Filters Section */}
                <Row gutter={[16, 16]} className="mt-4">

                    <Col xs={24} lg={3}>
                        <Typography.Title level={5}>
                            <ShopOutlined/> Магазины
                        </Typography.Title>
                        <Select
                            style={{width: '100%'}}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col xs={24} lg={3}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                </Row>
            </Card>

            <Card>
                 <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                    <Space wrap>
                        <Input placeholder="Поиск по артикулу" value={searchText} onChange={(e) => {setSearchText(e.target.value);}}/>
                        <Dropdown overlay={filterMenu} trigger={['click']}><Button icon={<FilterOutlined/>}>Добавить фильтр</Button></Dropdown>
                        <Select
                            style={{ width: 180 }}
                            value={drrType}
                            onChange={setDrrType}
                            options={[
                                { value: 'orders', label: 'Дрр по заказам' },
                                { value: 'buyouts', label: 'Дрр по выкупам' }
                            ]}
                        />
                        <Button icon={<ReloadOutlined/>} onClick={fetchData}>Обновить</Button>
                        <Button type="primary" style={{backgroundColor: '#4CAF50', borderColor: '#4CAF50'}} onClick={downloadExcel}>Экспорт в Excel</Button>
                        {activeFilters.map((filter, index) => (
                            <Tag key={index} closable onClose={() => handleRemoveFilter(index, setActiveFilters)}>{`${filter.type}: ${filter.value.label}`}</Tag>
                        ))}
                    </Space>
                    <Space wrap>
                        {quickFilterButtons.map(button => (
                            <Button key={button.key} type={quickFilters[button.key] ? 'primary' : 'default'}
                                    onClick={() => handleQuickFilter(button.key, quickFilters, setQuickFilters)}>{button.label}</Button>
                        ))}
                    </Space>
                </Space>
                <PlanFactTable3
                    cards={filteredData}
                    loading={loading}
                    drrType={drrType}
                    is_our={user.is_our}
                />
            </Card>

            <Sidebar/>
        </div>
    )
}
