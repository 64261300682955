import React, { useCallback, useEffect, useState } from 'react'
import {Card, Col, message, Row, Select, Tooltip, Typography, Tabs, Space, Menu, Input, Dropdown, Button, Tag} from 'antd'
import { SelectorSolo } from '../../components/Selectors'
import { useAuth } from '../../hooks/useAuth'
import { options_hide, options_like } from '../../utils'
import { defaultOnChangeLikeHide, setLikeHideOnLoadData } from '../components/LikeHide'
import { apiAxios } from "../../utils/api_axios";
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import SideBarPrices from '../home/sideBar/SideBarPrices'
import PriceCurrentPrice from './components/PriceCurrentPrice'
import PriceNewPrice from './components/PriceNewPrice'
import PriceSebesAnalysis from './components/PriceSebesAnalysis'
import {ShopOutlined, DollarOutlined, QuestionCircleOutlined, FilterOutlined, ReloadOutlined} from '@ant-design/icons'
import {applyAllFilters, handleQuickFilter, handleRemoveFilter, useFilterLogic} from "../components/filterUtils";
import { useSelectorFilter } from '../../Context/SelectorFilterContext'

const { TabPane } = Tabs

function PricingV2() {
    const { user } = useAuth()
    const {selectorFilter,setSelectorFilter} = useSelectorFilter()
    const { openSidebar } = useSidebar()
    
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [selected_hide, setSelectedHide] = useState(1)
    const [selected_like, setSelectedLike] = useState(1)
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] })
    const [isOzon, setIsOzon] = useState(true)
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('1')

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarPrices />)
    }, [openSidebar])

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList)
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await await apiAxios.get('prices/get/', { wbacc_ids: [selectorFilter.wbacc_id], is_like: selected_like, is_hide: selected_hide })
            setFiltersData(fetchedData['filters'])
            setCards(fetchedData['cards'])
            console.log(fetchedData['cards'])
            // Вычисляем сводную статистику
            const stats = fetchedData['cards'].reduce((acc, card) => {
                acc.totalProducts++
                acc.avgMargin += card.new_marzha || 0
                acc.avgMarkup += card.new_nacenka || 0
                acc.totalProfit += card.new_profit || 0
                return acc
            }, { totalProducts: 0, avgMargin: 0, avgMarkup: 0, totalProfit: 0 })
            
            stats.avgMargin = stats.avgMargin / stats.totalProducts
            stats.avgMarkup = stats.avgMarkup / stats.totalProducts

            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
            message.error('Ошибка при загрузке данных')
        }
    }

    const checkPrices = () => {
        setCards(
            cards.map((card) => {
                card.load_price = card.new_price
                let load_discount = 0
                if (Number(card.load_discount) === 0) {
                    load_discount = card.discount
                } else {
                    load_discount = Number(card.load_discount)
                }
                card.load_price_client = Math.floor((card.new_price * (100 - load_discount)) / 100)
                return card
            })
        )
        message.success('Цены проверены')
    }

    const loadPrices = async () => {
        try {
            const data = {
                cards: cards.map((card) => ({
                    nm_id: card.nm_id,
                    price: card.new_price_with_wb_discount ? card.new_price : 0,
                    discount: card.new_discount != card.discount ? card.new_discount : 0
                })),
                wbacc_id: cards[0].wbacc_id
            }

            await apiAxios.post('prices/load/', data)
            message.success('Цены успешно загружены!')
        } catch (err) {
            console.error('Error loading prices:', err)
            message.error('Не удалось загрузить цены')
        }
    }

    const updatePrices = async () => {
        try {
            setLoading(true)
            const data = { wbacc_id: cards[0].wbacc_id }
            await apiAxios.post('prices/update/', data)
            await fetchData()
            message.success('Цены обновлены')
        } catch (err) {
            console.error('Error updating prices:', err)
            message.error('Ошибка при обновлении цен')
        }
    }

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

        if (selectorFilter.wbacc_id) {
            selectorFilter.wbacc_id > 0 ? setIsOzon(false) : setIsOzon(true)
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id, selected_hide, selected_like])
    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showZeroProfit: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false,
        showNoPlan: false,
    });

    const quickFilterButtons = [
        {key: 'showNegativeMargin', label: 'Отрицательная маржа', filter: item => item.marzha < 0},
        {key: 'showZeroProfit', label: 'Чистый убыток', filter: item => item.profit + item.sebes < 0},
        {key: 'showNoSales', label: 'Нет заказов', filter: item => item.orders_per_day < 0.2 && item.ost_quantity > 10},
        {key: 'showLowStock', label: 'Низкий остаток', filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2},
        {key: 'showHighStock', label: 'Высокий остаток', filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2},
        {key: 'showLongTurnover', label: 'Долгая оборачиваемость', filter: item => item.oborot_orders > 60},
        {key: 'showLowBuyout', label: 'Низкий выкуп', filter: item => item.buyout_percent < 30 && item.buyout_percent > 0},
        {key: 'showNoPlan', label: 'Не указан план', filter: item => item.plan_orders === 0 || item.plan_orders_sum === 0},
    ];
    const [activeFilters, setActiveFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {setDebouncedSearchText(searchText);}, 1000);
        return () => {clearTimeout(handler);};
    }, [searchText]);

    const filterMenu = <Menu items={useFilterLogic(
        filters_data,
        setActiveFilters
    )}/>;
    const filteredData = applyAllFilters(cards, quickFilters, quickFilterButtons, activeFilters, debouncedSearchText)

    return (
        <div className="pricing-container">
            {/* Header Section */}
            <Card className="mb-4">
                <Row gutter={[16, 16]} align="middle">
                    <Col xs={24} lg={12}>
                        <Space align="center">
                            <Typography.Title level={2}>
                                <DollarOutlined /> Ценообразование
                            </Typography.Title>
                            <Tooltip title="Информация">
                                <QuestionCircleOutlined
                                    onClick={handleOpenSidebar}
                                    style={{
                                        fontSize: '24px',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s',
                                    }}
                                    className="hover:rotate-180 active:scale-90"
                                />
                            </Tooltip>
                        </Space>
                    </Col>

                </Row>

                {/* Filters Section */}
                <Row gutter={[16, 16]} className="mt-4">
                    <Col xs={24} lg={6}>
                        <Typography.Title level={5}>
                            <ShopOutlined /> Магазины
                        </Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo
                            options={options_like}
                            selected={selected_like}
                            setSelected={setSelectedLike}
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <SelectorSolo
                            options={options_hide}
                            selected={selected_hide}
                            setSelected={setSelectedHide}
                        />
                    </Col>
                </Row>
            </Card>

            {/* Main Content */}
            <Card>
                <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                    <Space wrap>
                        <Input placeholder="Поиск по артикулу" value={searchText} onChange={(e) => {setSearchText(e.target.value);}}/>
                        <Dropdown overlay={filterMenu} trigger={['click']}><Button icon={<FilterOutlined/>}>Добавить фильтр</Button></Dropdown>
                        {activeFilters.map((filter, index) => (
                            <Tag key={index} closable onClose={() => handleRemoveFilter(index, setActiveFilters)}>{`${filter.type}: ${filter.value.label}`}</Tag>
                        ))}
                    </Space>
                    <Space wrap>
                        {quickFilterButtons.map(button => (
                            <Button key={button.key} type={quickFilters[button.key] ? 'primary' : 'default'}
                                    onClick={() => handleQuickFilter(button.key, quickFilters, setQuickFilters)}>{button.label}</Button>
                        ))}
                    </Space>
                </Space>
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab="Текущая цена" key="1">
                        <PriceCurrentPrice
                            cards={filteredData}
                            loading={loading}
                            updatePrices={updatePrices}
                        />
                    </TabPane>
                    <TabPane tab="Новая цена" key="2">
                        <PriceNewPrice
                            cards={filteredData}
                            setCards={setCards}
                            loading={loading}
                            updatePrices={updatePrices}
                            loadPrices={loadPrices}
                            checkPrices={checkPrices}
                        />
                    </TabPane>
                    <TabPane tab="Анализ себестоимости" key="3">
                        <PriceSebesAnalysis
                            cards={cards}
                            loading={loading}
                        />
                    </TabPane>

                </Tabs>
            </Card>
            
            <Sidebar />
        </div>
    )
}

export default PricingV2
