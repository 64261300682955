import React from 'react';
import { Table, Select, Typography, Col } from 'antd';
import { formatCurrency, formatPercent } from '../../../utils/formats';

const { Text } = Typography;

export default function GeoTable({ data, loading, geoType, onGeoTypeChange, geoTypeOptions }) {
    return (
            <><Col xs={24} sm={12} md={6} lg={4} style={{ marginBottom: 16 }}>
            <Text strong>Группировка</Text>
            <Select
                style={{ width: '100%' }}
                value={geoType}
                onChange={onGeoTypeChange}
                options={geoTypeOptions} />
        </Col><Table
                dataSource={data}
                loading={loading}
                className='adverts-table2'
                scroll={{ x: 'max-content' }}
                pagination={{
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} товаров`
                }}
                columns={[
                    {
                        title:geoType === 'warehouse' ? 'Склад' : 'Регион',
                        dataIndex: 'location',
                        key: 'location',
                        width: 400,
                    },
                    {
                        title: 'Заказано',
                        dataIndex: 'orders',
                        key: 'orders',
                        render: (value) => `${value} шт`,
                        sorter: (a, b) => a.orders - b.orders,
                    },
                    {
                        title: 'Продано',
                        dataIndex: 'buyouts',
                        key: 'buyouts',
                        render: (value) => `${value} шт`,
                        sorter: (a, b) => a.buyouts - b.buyouts,
                    },
                    {
                        title: 'Процент выкупа',
                        dataIndex: 'buyout_percent',
                        key: 'buyout_percent',
                        render: (value) => `${formatPercent(value)}`,
                        sorter: (a, b) => a.buyout_percent - b.buyout_percent,
                    },
                    {
                        title: 'Выручка',
                        dataIndex: 'buyouts_sum',
                        key: 'buyouts_sum',
                        render: (value) => `${formatCurrency(value)}`,
                        sorter: (a, b) => a.buyouts_sum - b.buyouts_sum,
                    },
                    {
                        title: 'Доля из всей выручки',
                        dataIndex: 'revenue_share',
                        key: 'revenue_share',
                        render: (value) => `${formatPercent(value)}`,
                        sorter: (a, b) => a.revenue_share - b.revenue_share,
                    },
                ]} /></>
    );
}
