import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography

const SideBarPnlDetail = () => {
    const metrics = [
        {
            label: 'Группа',
            description: 'идентификатор группы товаров или операций.'
        },
        {
            label: 'Дата',
            description: 'дата, за которую представлены данные.'
        },
        {
            label: 'Сумма выкупов',
            description: 'общая сумма выкупа товара покупателями за указанный день.'
        },
        {
            label: 'Сумма возвратов',
            description: 'общая сумма возвратов товаров за день.'
        },
        {
            label: 'К перечислению',
            description: 'сумма, подлежащая перечислению после выкупа и вычета возвратов.'
        },
        {
            label: 'Комиссия',
            description: 'комиссия платформы или продавца за продажи.'
        },
        {
            label: 'Логистика',
            description: 'расходы на транспортировку и доставку товаров.'
        },
        {
            label: 'Хранение',
            description: 'затраты на хранение товаров на складе.'
        },
        {
            label: 'Приемка',
            description: 'затраты на проверку и приемку товаров.'
        },
        {
            label: 'Штрафы',
            description: 'любые штрафные санкции, наложенные на компанию.'
        },
        {
            label: 'МРК',
            description: 'маркетинговые расходы для продвижения товаров на платформе.'
        },
        {
            label: 'Другие расходы',
            description: 'все прочие расходы, которые не вошли в основные категории.'
        },
        {
            label: 'Другие выплаты',
            description: 'дополнительные выплаты, не относящиеся к основным статьям затрат.'
        },
        {
            label: 'Итого к выводу',
            description: 'сумма, которую можно вывести после учета всех затрат.'
        },
        {
            label: 'Себестоимость',
            description: 'стоимость производства или закупки товара.'
        },
        {
            label: 'Налог',
            description: 'сумма налога, рассчитанная на основе прибыли.'
        },
        {
            label: 'МРК Внешний',
            description: 'маркетинговые расходы на внешних платформах, помимо основной.'
        },
        {
            label: 'Другие расходы (внешние)',
            description: 'остальные расходы, аналогичные предыдущему пункту, но отличающиеся по происхождению.'
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={3}>Описание страницы "Прибыль и убытки детализация"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница <span className='text-black'>"Прибыль и убытки детализация"</span> предоставляет подробный отчет о финансовых показателях, связанных с продажами товаров, возвратами,
                комиссией, логистикой, хранением и другими расходами по дням для анализа доходов и расходов компании за определенный период.
            </Text>
            <Title level={3}>Расшифровка ключевых показателей:</Title>
            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {metrics.map((metric, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold text-black'>{metric.label}</span> — {metric.description}
                    </li>
                ))}
            </div>
            <Text className='font-bold text-xs'>
                Данная таблица позволяет проанализировать, насколько эффективно компания управляет своими затратами, и помогает определить прибыльность за каждый день.
            </Text>
        </div>
    )
}
export default SideBarPnlDetail
