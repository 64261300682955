import React, {useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Dropdown, Input, Menu, Row, Select, Space, Tag, Tooltip, Typography} from 'antd'
import {useAuth} from '../../hooks/useAuth'
import {apiAxios} from "../../utils/api_axios";
import SalesPlanTable from './components/SalesPlanTable'
import {useSidebar} from '../../Context/SideBarContext'
import {CiCircleQuestion} from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import {applyAllFilters, handleQuickFilter, handleRemoveFilter, useFilteredCards, useFilterLogic} from "../components/filterUtils";
import {FilterOutlined, ReloadOutlined} from "@ant-design/icons";
import { useSelectorFilter } from '../../Context/SelectorFilterContext';

export default function SalesPlan() {
    const {user} = useAuth()
    const {selectorFilter,setSelectorFilter} = useSelectorFilter()
    const {openSidebar} = useSidebar()

    const [data, setData] = useState([])
    const [previousData, setPreviousData] = useState([]);
    const [loading, setLoading] = useState(true)

    const handleOpenSidebar = useCallback(() => {
        openSidebar('fd')
    }, [])
    
    const [filters_data, setFiltersData] = useState({});
    const [imtData, setImtData] = useState({});

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids})
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id})
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const fetchData = async () => {
        setLoading(true)
        const response = await apiAxios.post('sales/plan/get', {wbacc_ids: [selectorFilter.wbacc_id]})
        console.log('fetchedData', response)
        setData(response.data || [])
        setFiltersData(response.filters || {});
        setImtData(response.imt_data || [])
        setLoading(false)
    }
    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

    }, [user.wbacc_ids])
    
    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [selectorFilter.wbacc_id]);



    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false,
        showNoPlan: false,
    });

    const quickFilterButtons = [
        {key: 'showNegativeMargin', label: 'Отрицательная маржа', filter: item => item.marzha_unit < 0},
        {key: 'showNoSales', label: 'Нет заказов', filter: item => item.orders_per_day < 0.2 && item.ost_quantity > 10},
        {key: 'showLowStock', label: 'Низкий остаток', filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2},
        {key: 'showHighStock', label: 'Высокий остаток', filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2},
        {key: 'showLongTurnover', label: 'Долгая оборачиваемость', filter: item => item.oborot_orders > 60},
        {key: 'showLowBuyout', label: 'Низкий выкуп', filter: item => item.buyout_percent < 30 && item.buyout_percent > 0},
        {key: 'showNoPlan', label: 'Не указан план', filter: item => item.plan_orders === 0 || item.plan_orders_sum === 0},
    ];
    const [activeFilters, setActiveFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {setDebouncedSearchText(searchText);}, 1000);
        return () => {clearTimeout(handler);};
    }, [searchText]);

    const filterMenu = <Menu items={useFilterLogic(
        filters_data,
        setActiveFilters
    )}/>;
    const filteredData = applyAllFilters(data, quickFilters, quickFilterButtons, activeFilters, debouncedSearchText)


    const onEditCard = (card) => {
        console.log('edit', card)
        apiAxios.post('sales/plan/set', card)
    }

    return (
        <>
            <Card>
                <Row type='flex' style={{ gap: 10, alignItems: 'center' }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography.Title level={2} style={{ margin: 0 }}>ПЛАН ПРОДАЖ</Typography.Title>
                        <Tooltip title='Информация по таблице'>
                            <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                        </Tooltip>
                        <Select
                            style={{ width: '200px', marginLeft: 5 }}
                            placeholder='Выберите магазин'
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />

                    </Col>
                </Row>

                <Sidebar/>
            </Card>
            <Card>
                <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                    <Space wrap>
                        <Input placeholder="Поиск по артикулу" value={searchText} onChange={(e) => {setSearchText(e.target.value);}}/>
                        <Dropdown overlay={filterMenu} trigger={['click']}><Button icon={<FilterOutlined/>}>Добавить фильтр</Button></Dropdown>
                        <Button icon={<ReloadOutlined/>} onClick={fetchData}>Обновить</Button>
                        <Button type="primary" style={{backgroundColor: '#4CAF50', borderColor: '#4CAF50'}} >Экспорт в Excel</Button>
                        {activeFilters.map((filter, index) => (
                            <Tag key={index} closable onClose={() => handleRemoveFilter(index, setActiveFilters)}>{`${filter.type}: ${filter.value.label}`}</Tag>
                        ))}
                    </Space>
                    <Space wrap>
                        {quickFilterButtons.map(button => (
                            <Button key={button.key} type={quickFilters[button.key] ? 'primary' : 'default'}
                                    onClick={() => handleQuickFilter(button.key, quickFilters, setQuickFilters)}>{button.label}</Button>
                        ))}
                    </Space>
                </Space>

                <SalesPlanTable
                    cards={filteredData}
                    setCards={setData}
                    loading={loading}
                    onEditCard={onEditCard}
                    is_our={user.is_our}
                />
            </Card>
        </>
    )
}
