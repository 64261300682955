import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Card, Typography, Space, Button, Dropdown, Tag, Menu, Select, Tooltip, Row, Col, Table} from 'antd'
import {FilterOutlined, ReloadOutlined, QuestionCircleOutlined, DownloadOutlined} from '@ant-design/icons'
import {formatCurrency, formatDays, formatPercent, formatQuantity} from'../../utils/formats';
import {useAuth} from '../../hooks/useAuth'
import {apiAxios} from "../../utils/api_axios";
import {determineNormalDistributionBounds} from '../../components/Gradient'
import { useSidebar } from '../../Context/SideBarContext'
import SideBarOborot from '../home/sideBar/SideBarOborot'
import {useFilterLogic, useFilteredCards} from '../components/filterUtils'
import * as XLSX from 'xlsx';
import { useSelectorFilter } from '../../Context/SelectorFilterContext';

const {Text, Title} = Typography;

export default function Oborot() {
    const {user} = useAuth();
    const {selectorFilter,setSelectorFilter} = useSelectorFilter()
    const {openSidebar} = useSidebar();
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeFilters, setActiveFilters] = useState([]);
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        article: '',
        material: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    });

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarOborot/>);
    }, [openSidebar]);

    const [filters_data, setFiltersData] = useState({});
    const [gradientData, setGradientData] = useState({});

    const fetchWbAccounts = async () => {
        try {
            const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids})
            let wbaccOptions = []

            for (let i in fetchedDataWbacc.data) {
                wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id})
            }

            setSelectorFilter('wbacc_id', user.wbacc_ids[0])
            setSelectorFilter('wbacc_options', wbaccOptions)
        } catch (error) {
            console.error('Error fetching WB accounts:', error);
        }
    };

    const fetchOborotData = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('sales/oborot/get', {
                wbacc_ids: [selectorFilter.wbacc_id],
                group_type: selectorFilter.group
            });
            setData(response.data);
            setFiltersData(response.filters || {});

            const numericFields = [
                'orders_per_day', 'buyouts_per_day', 'oborot_orders', 'oborot_buyouts',
                'nacenka_unit', 'marzha_unit', 'price_with_discount', 'ost_quantity',
                'ost_way_to', 'ost_way_from', 'ost_sum', 'ost_sebes', 'buyout_percent'
            ];

            const newGradientData = {};
            numericFields.forEach(field => {
                const values = response.data.map(item => item[field]);
                newGradientData[field] = determineNormalDistributionBounds(values);
            });
            setGradientData(newGradientData);

        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchWbAccounts();
        }
    }, [user.wbacc_ids]);

    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchOborotData();
        }
    }, [selectorFilter.wbacc_id, selectorFilter.group]);

    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            );
            if (filterExists) return prev;
            return [...prev, {type, value}];
        });
    };

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index));
    };

    const menuItems = useFilterLogic(
        filters_data,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    );

    const filterMenu = <Menu items={menuItems}/>;

    const getBaseColumns = useMemo(() => {
        const groupColumns = selectorFilter.group === 'nm_id' ? [
            {
                title: 'Товар',
                key: 'product',
                width: 300,
                fixed: 'left',
                render: (_, record) => (
                    <Space direction="vertical" size={0}>
                        <Space align="start" size={12}>
                            <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                                <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                                <Text><strong>Название:</strong> {record.name}</Text>
                                <Text><strong>Карточка:</strong> {record.imt_id}</Text>
                            </Space>
                        </Space>
                    </Space>
                )
            },
            {
                title: 'Информация о товаре',
                width: 300,
                render: (_, record) => (
                    <Space size={24} className="cost-info-cell">
                    <div className='w-[200px]'>
                        <Text><strong>Модель:</strong> {record.material}</Text>
                        <Text><strong>Бренд:</strong> {record.brand}</Text>
                        <Text><strong>Категория:</strong> {record.category}</Text>
                    </div>
                    <div>
                        <Text><strong>Статус:</strong> {record.status}</Text>
                        <Text><strong>Сезон:</strong> {record.sezon}</Text>
                        <Text><strong>Цвет:</strong> {record.color}</Text>
                    </div>
                </Space>
                )
            }
        ] : [
            {
                title: selectorFilter.group_options.find(opt => opt.value === selectorFilter.group)?.label || 'Группа',
                width: 200,
                fixed: 'left',
                render: (_, record) => (
                    <Text strong>{record.group_type}</Text>
                )
            }
        ];

        return [
            ...groupColumns,
            {
                title: 'Остатки',
                width: 100,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Всего:</strong> {formatQuantity(record.ost_full)}</Text>
                        <Text><strong>FBO:</strong> {formatQuantity(record.ost_quantity)}</Text>
                        <Text><strong>В пути от:</strong> {formatQuantity(record.ost_way_from || 0)}</Text>
                        <Text><strong>В пути до:</strong> {formatQuantity(record.ost_way_to || 0)}</Text>
                    </Space>
                )
            },
            {
                title: 'Финансы',
                width: 200,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Себестоимость:</strong> {formatCurrency(record.ost_sebes)}</Text>
                        <Text><strong>В товаре:</strong> {formatCurrency(record.ost_sum)}</Text>
                        <Text><strong>Цена со скидкой:</strong> {formatCurrency(record.price_with_discount)}</Text>
                    </Space>
                )
            },
            {
                title: 'Продажи',
                width: 150,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Заказов в день:</strong> {formatQuantity(record.orders_per_day)}</Text>
                        <Text><strong>Продаж в день:</strong> {formatQuantity(record.buyouts_per_day)}</Text>
                        <Text><strong>Процент выкупа:</strong> {formatPercent(record.buyout_percent)}</Text>
                    </Space>
                )
            },
            {
                title: 'Оборачиваемость',
                width: 150,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text type={record.oborot_orders <= 60 ? 'success' : 'danger'}>
                            <strong>Оборачиваемость:</strong> {formatDays(record.oborot_orders)}
                        </Text>
                        <Text type={record.oborot_buyouts <= 60 ? 'success' : 'danger'}>
                            <strong>Запас дней:</strong> {formatDays(record.oborot_buyouts)}
                        </Text>
                    </Space>
                )
            },
            {
                title: 'Эффективность',
                width: 150,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text type={record.nacenka_unit > 0 ? 'success' : 'danger'}>
                            <strong>Наценка:</strong> {formatPercent(record.nacenka_unit)}
                        </Text>
                        <Text type={record.marzha_unit > 0 ? 'success' : 'danger'}>
                            <strong>Маржа:</strong> {formatPercent(record.marzha_unit)}
                        </Text>
                    </Space>
                )
            }
        ];
    },[])

    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false
    });

    const quickFilterButtons = [
        {
            key: 'showNegativeMargin',
            label: 'Отрицательная маржа',
            filter: item => item.marzha_unit < 0
        },
        {
            key: 'showNoSales',
            label: 'Нет заказов',
            filter: item => item.orders_per_day < 0.2 && item.ost_quantity > 10
        },
        {
            key: 'showLowStock',
            label: 'Низкий остаток',
            filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2
        },
        {
            key: 'showHighStock',
            label: 'Высокий остаток',
            filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2
        },
        {
            key: 'showLongTurnover',
            label: 'Долгая оборачиваемость',
            filter: item => item.oborot_orders > 60 // больше 60 дней
        },
        {
            key: 'showLowBuyout',
            label: 'Низкий выкуп',
            filter: item => item.buyout_percent < 30 && item.buyout_percent > 0 // меньше 50%
        }
    ];

    const handleQuickFilter = (filterKey) => {
        // Сбрасываем все фильтры, если текущий фильтр уже активен
        if (quickFilters[filterKey]) {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: false
            }), {}));
        } else {
            // Включаем только выбранный фильтр
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: key === filterKey
            }), {}));
        }
    };

    const applyQuickFilters = (data) => {
        const activeFilter = Object.entries(quickFilters).find(([_, value]) => value);
        if (!activeFilter) return data;

        const filterConfig = quickFilterButtons.find(btn => btn.key === activeFilter[0]);
        return data.filter(filterConfig.filter);
    };

    const filteredData = applyQuickFilters(useFilteredCards(data, activeFilters));

    const downloadExcel = (data) => {
        // Подготавливаем данные для Excel

        const excelData = data.map(card => ({
            'Артикул': card.article,
            'Артикул WB': card.nm_id,
            'Название': card.name,
            'Карточка': card.imt_id,
            'Бренд': card.brand,
            'Категория': card.category,
            'Модель': card.model,
            'Материал': card.material,
            'Статус': card.status,
            'Сезон': card.material,
            'Цвет': card.color,

            'Остатки Всего': card.ost_full,
            'Остатки FBO': card.ost_quantity,
            'В пути от': card.ost_way_from || 0,
            'В пути до': card.ost_way_to || 0,
            'Себестоимость': card.ost_sebes,
            'Деньги в товаре:': card.ost_sum,
            'Цена': card.price_with_discount,

            'Заказов в день': card.orders_per_day,
            'Продаж в день': card.buyouts_per_day,
            'Процент выкупа': card.buyout_percent,

            'Оборачиваемость': card.oborot_orders,
            'Запас дней': card.oborot_buyouts,

            'Маржинальность': card.marzha_unit,
            'Наценка': card.nacenka_unit,

        }));

        // Создаем книгу Excel
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Настраиваем ширину столбцов
        const maxWidth = 20;
        const colWidths = {};
        Object.keys(excelData[0] || {}).forEach(key => {
            colWidths[key] = Math.min(
                maxWidth,
                Math.max(
                    key.length,
                    ...excelData.map(row => String(row[key] || '').length)
                )
            );
        });
        ws['!cols'] = Object.values(colWidths).map(width => ({wch: width}));

        // Добавляем лист в книгу
        XLSX.utils.book_append_sheet(wb, ws, "Статистика");

        // Скачиваем файл
        XLSX.writeFile(wb, `Статистика_${new Date().toLocaleDateString()}.xlsx`);
    };

    return (
        <>
            <Card>
                <Space direction="vertical" size={16} style={{width: '100%'}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Space>
                                <Title level={4} style={{margin: 0}}>Оборачиваемость</Title>
                                <Tooltip title="Информация">
                                    <Button
                                        icon={<QuestionCircleOutlined/>}
                                        onClick={handleOpenSidebar}
                                    />
                                </Tooltip>
                            </Space>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} align="middle">
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Text strong>Магазин</Text>
                            <Select
                                style={{width: '100%'}}
                                placeholder="Выберите магазин"
                                value={selectorFilter.wbacc_id}
                                onChange={value => setSelectorFilter('wbacc_id', value)}
                                options={selectorFilter.wbacc_options}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Text strong>Группировка</Text>
                            <Select
                                style={{width: '100%'}}
                                value={selectorFilter.group}
                                onChange={value => setSelectorFilter('group', value)}
                                options={selectorFilter.group_options}
                            />
                        </Col>
                    </Row>
                </Space>
            </Card>

            <Card style={{marginTop: 16}}>
                <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16}}>
                    <Space wrap>
                        <Dropdown overlay={filterMenu} trigger={['click']}>
                            <Button icon={<FilterOutlined/>}>
                                Добавить фильтр
                            </Button>
                        </Dropdown>
                        <Button
                            icon={<ReloadOutlined/>}
                            onClick={fetchOborotData}
                        >
                            Обновить
                        </Button>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined/>}
                            style={{backgroundColor: '#4CAF50', borderColor: '#4CAF50'}}
                            onClick={() => downloadExcel(filteredData)}
                        >
                            Экспорт в Excel
                        </Button>

                        {activeFilters.map((filter, index) => (
                            <Tag
                                key={index}
                                closable
                                onClose={() => handleRemoveFilter(index)}
                            >
                                {`${filter.type}: ${filter.value.label}`}
                            </Tag>
                        ))}
                    </Space>
                    <Space wrap>
                        {quickFilterButtons.map(button => (
                            <Button
                                key={button.key}
                                type={quickFilters[button.key] ? 'primary' : 'default'}
                                onClick={() => handleQuickFilter(button.key)}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Space>
                </Space>

                <Table
                    dataSource={filteredData}
                    columns={getBaseColumns}
                    loading={loading}
                    scroll={{x: 'max-content'}}
                    size="small"
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} товаров`
                    }}
                    rowKey="nm_id"
                />
            </Card>
        </>
    );
}
