import { Col, Row, Select, Space, Table, Typography } from 'antd';
import { Option } from 'antd/es/mentions';
import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { ButtonExel } from '../../../components/Buttons';

const {Text, Title} = Typography;

const expandData = (data, selectedMetric) => {
    return data.map((item, index) => {
        const metrics = Object.keys(item.periods || {}).reduce((acc, date) => {
            const periodData = item.periods[date] || {};
            Object.keys(periodData).forEach(metric => {
                if (metric !== selectedMetric && metric !== 'period' && metric !== 'ren') {
                    if (!acc[metric]) acc[metric] = { name: metric, values: {} };
                    acc[metric].values[date] = periodData[metric];
                }
            });
            return acc;
        }, {});

        const children = Object.entries(metrics).map(([metricName, metricData]) => {
            const row = {
                key: `${item.nm_id}-${metricName}`,
                metricName: metricsOptions.find(option => option.value === metricName)?.label || metricName,
                periods: metricData.values
            };
            Object.keys(metricData.values).forEach(date => {
                row[date] = metricData.values[date];
            });
            return row;
        });

        return {
            ...item,
            key: index,
            children
        };
    });
};

const getChartData = (periods, selectedMetric) => {
    const chartData = Object.keys(periods || {}).reduce((acc, date) => {
        const value = periods[date]?.[selectedMetric] || periods[date] || 0;
        if (typeof value === 'number') {
            acc.push({ date, value });
        }
        return acc;
    }, []);
    chartData.sort((a, b) => new Date(a.date) - new Date(b.date));
    return chartData;
};

const renderChart = (record, selectedMetric) => {
    const periods = record.periods || {};
    const chartData = getChartData(periods, selectedMetric);
    if (!chartData.length) return null;

    return (
        <ResponsiveContainer width="100%" height={50}>
            <BarChart data={chartData}>
                <XAxis dataKey="date" hide />
                <YAxis hide />
               <Tooltip
                    formatter={(value) => [value, "Значение"]}
                    wrapperStyle={{ zIndex: 1000 }} // Задаем высокий z-index
                />
                <Bar dataKey="value">
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.value < 0 ? 'red' : 'green'} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

const getColumns = (data, selectedMetric,selectorFilter) => {
    const uniqueDates = new Set();
    data.forEach(item => {
        if (item.periods) {
            Object.keys(item.periods).forEach(date => {
                uniqueDates.add(date);
            });
        }
    });

    const dateColumns = Array.from(uniqueDates).sort().map(date => ({
        title: date,
        dataIndex: date,
        render: (text, record) => {
            if (record.metricName) {
                return record[date] !== undefined ? record[date] : '-';
            } else {
                const periodData = record.periods ? record.periods[date] : null;
                const currentMetricValue = periodData ? periodData[selectedMetric] : '-';
                return <span>{currentMetricValue}</span>;
            }
        },
        width: 100
    }));

    const graphColumn = {
        title: 'Динамика',
        dataIndex: 'chart',
        render: (text, record) => renderChart(record, selectedMetric),
        width: 200
    };

    const isMobile = window.innerWidth <= 769;
    const columns = [
        { title: '', dataIndex: '', width: 20, fixed: isMobile ? '' : 'left' },
        {
            title: selectorFilter.group === 'nm_id' ? 'Товар' : 'Группа',
            dataIndex: selectorFilter.group === 'nm_id' ? 'nm_id' : selectorFilter.group,
            key: selectorFilter.group,
            width: 300,
            fixed: 'left',
            render: (text, record) => {
                if (selectorFilter.group === 'nm_id' && record.children) {
                    return  <Space direction="vertical" size={0}>
                    <Space align="start" size={12}>
                        <img src={record.img} className='min-w-14 max-w-14 mt-2 h-14 object-contain' alt="Product"/>
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                            <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                            <Text><strong>Название:</strong> {record.name}</Text>
                        </Space>
                    </Space>
                </Space>
                }
                return text;
            }
        },
        {
            title: 'Информация о товаре',
            dataIndex: 'details',
            key: 'details',
            width: 350,
            render: (_, record) => {
                if (record.children) {
                    return (
                        <Space size={24} className="cost-info-cell">
                            <div className='w-[200px]'>
                                <Text><strong>Модель:</strong> {record.material}</Text>
                                <Text><strong>Бренд:</strong> {record.brand}</Text>
                                <Text><strong>Категория:</strong> {record.category}</Text>
                            </div>
                            <div>
                                <Text><strong>Статус:</strong> {record.status}</Text>
                                <Text><strong>Сезон:</strong> {record.sezon}</Text>
                                <Text><strong>Цвет:</strong> {record.color}</Text>
                            </div>
                        </Space>
                    );
                }
                return null;
            }
        },
        {
            title: 'Метрика',
            dataIndex: 'metricName',
            width: 160,
            fixed:'left',
            render: (text, record) => {
                return record.metricName
                    ? metricsOptions.find(option => option.value === record.metricName)?.label || record.metricName
                    : metricsOptions.find(option => option.value === selectedMetric)?.label || selectedMetric;
            }
        },
        graphColumn,
        ...dateColumns
    ];

    return columns;
};


const metricsOptions = [
    { value: 'orders', label: 'Заказы, шт' },
    { value: 'buyouts', label: 'Выкупы, шт' },
    { value: 'commission', label: 'Комиссия' },
    { value: 'delivery', label: 'Доставка' },
    { value: 'drr', label: 'Доходность' },
    { value: 'margin_percent', label: 'Маржинальность, %' },
    { value: 'profit', label: 'Прибыль' },
    { value: 'roi', label: 'ROI' },
    { value: 'sebes', label: 'Себестоимость' },
    { value: 'store', label: 'Склад' },
    { value: 'buyouts_sum', label: 'Сумма выкупа' }
];

export default function DynamicsTable({ loading, data ,selectorFilter,setSelectorFilter}) {
    const [selectedMetric, setSelectedMetric] = useState('sebes');
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const columns = getColumns(data, selectedMetric,selectorFilter);
    const dataSource = expandData(data, selectedMetric);

    const onExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, record.key]);
        } else {
            setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
        }
    };

    return (
        <>
      <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
        <Col lg={3} xs={24}> 
                        <Typography.Title level={5}>Группировка</Typography.Title>
                        <Select
                style={{ width: '100%' }}
                value={selectorFilter.group}
                onChange={value => setSelectorFilter('group', value)}
                options={selectorFilter.group_options} />
      </Col>
                <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Метрика</Typography.Title>
                        <Select
                defaultValue={selectedMetric}
                onChange={(value) => setSelectedMetric(value)}
                style={{ width: '100%' }}
            >
                {metricsOptions.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
            </Select>
            
      </Col>
      <Col lg={3} xs={24}> 
                        <Typography.Title level={5}>Группировка по дням</Typography.Title>
                        <Select
                style={{ width: '100%' }}
                value={selectorFilter.period}
                onChange={value => setSelectorFilter('period', value)}
                options={selectorFilter.period_options} />
      </Col>
                </Row>
            <Table
                columns={columns}
                dataSource={dataSource}
                bordered
                size={'small'}
                className='adverts-table2 mt-5'
                scroll={{
                    y: 800
                }}
                loading={loading}
                pagination={{
                    defaultPageSize: 20,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`
                }}
                expandedRowKeys={expandedRowKeys}
                onExpand={onExpand}
            />
        </>
    );
}
