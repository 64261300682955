import React, {useState} from 'react';
import {Input, Menu} from 'antd';

const MENU_HEIGHT = 400; // Maximum height for the menu
const SEARCH_HEIGHT = 56; // Height of search input with padding

export const FilterSearch = ({placeholder, onChange}) => (
    <div onClick={e => e.stopPropagation()} style={{padding: '8px', position: 'sticky', top: 0, background: 'white', zIndex: 1}}>
        <Input
            placeholder={placeholder}
            onChange={e => onChange(e.target.value)}
            autoFocus
        />
    </div>
);
export const handleRemoveFilter = (index, setActiveFilters) => {
    setActiveFilters(prev => prev.filter((_, i) => i !== index));
};
export const handleQuickFilter = (filterKey, quickFilters, setQuickFilters) => {
    if (quickFilters[filterKey]) {
        setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
            ...acc,
            [key]: false
        }), {}));
    } else {
        setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
            ...acc,
            [key]: key === filterKey
        }), {}));
    }
};
export const useFilterLogic = (filters, setActiveFilters) => {
    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            );
            if (filterExists) return prev;
            return [...prev, {type, value}];
        });
    };
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        article: '',
        material: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    });
    return React.useMemo(() => {
        const createSubMenu = (type, title) => {
            const options = filters?.[type] || [];
            const searchText = filterSearchTexts[type] || '';

            const filteredOptions = options.filter(option =>
                option?.label?.toLowerCase?.()?.includes?.(searchText?.toLowerCase?.() || '') || false
            );

            const maxHeight = MENU_HEIGHT - SEARCH_HEIGHT;

            return {
                key: type,
                label: title,
                children: [
                    {
                        key: `${type}-search`,
                        label: (
                            <FilterSearch
                                placeholder={`Поиск по ${title.toLowerCase()}`}
                                onChange={(value) => {
                                    setFilterSearchTexts(prev => ({
                                        ...prev,
                                        [type]: value
                                    }));
                                }}
                            />
                        )
                    },
                    {
                        key: `${type}-options`,
                        type: 'group',
                        label: null,
                        style: {
                            maxHeight: `${maxHeight}px`,
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        },
                        children: filteredOptions.map(option => ({
                            key: `${type}-${option?.value}`,
                            label: option?.label || '',
                            onClick: () => handleAddFilter(type, {
                                label: option?.value || '',
                                value: option?.value || ''
                            })
                        }))
                    }
                ],
                popupClassName: 'scrollable-filter-menu'
            };
        };

        return [
            createSubMenu('brand', 'Бренд'),
            createSubMenu('model', 'Модель'),
            createSubMenu('material', 'Материал'),
            createSubMenu('category', 'Категория'),
            createSubMenu('status', 'Статус'),
            createSubMenu('sezon', 'Сезон'),
            createSubMenu('color', 'Цвет'),
        ];
    }, [filters, filterSearchTexts, handleAddFilter, setFilterSearchTexts]);
};
export const applyQuickFilters = (data, quickFilters, quickFilterButtons) => {
    const activeFilter = Object.entries(quickFilters).find(([_, value]) => value);
    if (!activeFilter) return data;

    const filterConfig = quickFilterButtons.find(btn => btn.key === activeFilter[0]);
    if (!filterConfig) return data;
    const filteredData = data.filter(filterConfig.filter);
    return filteredData;
};

export const useFilteredCards = (cards, activeFiltersState) => {
    return React.useMemo(() => {
        if (!activeFiltersState?.length) return cards || [];

        // Group filters by type
        const filterGroups = activeFiltersState.reduce((groups, filter) => {
            if (!groups[filter.type]) {
                groups[filter.type] = [];
            }
            groups[filter.type].push(filter);
            return groups;
        }, {});

        return (cards || []).filter(card => {
            // Card must match at least one filter in each filter type group
            return Object.entries(filterGroups).every(([type, filtersOfType]) => {
                if (!card || !type) return false;

                // Check if card matches any filter of this type (OR logic within group)
                return filtersOfType.some(filter => {
                    if (!filter?.value?.value) return false;
                    const cardValue = (card[type] || '').toString().toLowerCase();
                    const filterValue = filter.value.value.toString().toLowerCase();
                    return cardValue === filterValue;
                });
            });
        });
    }, [cards, activeFiltersState]);
};


export const applyAllFilters = (data, quickFilters, quickFilterButtons, activeFiltersState, searchText) => {
    let resultCards = []
    const applyActiveFilters = (data) => {
        if (!activeFiltersState?.length) return data || [];

        // Group filters by type
        const filterGroups = activeFiltersState.reduce((groups, filter) => {
            if (!groups[filter.type]) {
                groups[filter.type] = [];
            }
            groups[filter.type].push(filter);
            return groups;
        }, {});
        return (data || []).filter(card => {
            // Card must match at least one filter in each filter type group
            return Object.entries(filterGroups).every(([type, filtersOfType]) => {
                if (!card || !type) return false;

                // Check if card matches any filter of this type (OR logic within group)
                return filtersOfType.some(filter => {
                    if (!filter?.value?.value) return false;
                    const cardValue = (card[type] || '').toString().toLowerCase();
                    const filterValue = filter.value.value.toString().toLowerCase();
                    return cardValue === filterValue;
                });
            });
        });
    }
    const applyQuickFilters = (data) => {
        const activeFilter = Object.entries(quickFilters).find(([_, value]) => value);
        if (!activeFilter) return data;

        const filterConfig = quickFilterButtons.find(btn => btn.key === activeFilter[0]);
        if (!filterConfig) return data;
        const filteredData = data.filter(filterConfig.filter);
        return filteredData;
    };
    const applySearchFilters = (data) => {
        return data.filter(record =>
            record.article?.toLowerCase().includes(searchText.toLowerCase()) || record.nm_id?.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    }
    resultCards = applyActiveFilters(data);
    resultCards = applyQuickFilters(resultCards);
    resultCards = applySearchFilters(resultCards);
    return resultCards
};