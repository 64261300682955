import React, {useEffect, useState} from 'react'
import {Card, Typography, Space, Menu, Select, Row, Col, Tabs} from 'antd'
import {useAuth} from '../../hooks/useAuth'
import {apiAxios} from "../../utils/api_axios";
import {useFilterLogic} from '../components/filterUtils'
import dayjs from 'dayjs'
import GeoTable from './components/GeoTable'
import AbcTable from './components/AbcTable'
import OrdersTable from './components/OrdersTable'
import SalesTable from './components/SalesTable';
import ReturnsTable from './components/ReturnsTable';
import DynamicsTable from './components/DynamicsTable';
import './components/OrdersTabs.css'
import { ButtonExel } from '../../components/Buttons'
import DatePickerRange from '../../components/dateRange'
import { useSelectorFilter } from '../../Context/SelectorFilterContext';

const { Title} = Typography;

export default function Oborot_v2() {
    const {user} = useAuth();
    const {selectorFilter,setSelectorFilter} = useSelectorFilter()
    const [dataDiff, setDataDiff] = useState([]);
    const [dataGeo, setDataGeo] = useState([]);
    const [dataAbc, setDataAbc] = useState([]);
    const [dataOrders, setDataOrders] = useState([]);
    const [dataSales, setDataSales] = useState([]);
    const [dataReturns, setDataReturns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeFilters, setActiveFilters] = useState([]);
    const [activeTab, setActiveTab] = useState('orders');
    const startDate = dayjs().date() === 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month');
    const [period, setPeriod] = useState([startDate, dayjs()]);
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        article: '',
        material: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    });



    const [selectedMetric, setSelectedMetric] = useState('Прибыль');

    // const { openSidebar } = useSidebar()

    // const handleOpenSidebar = useCallback(() => {
    //     openSidebar(<SideBarOborot />)
    // }, [openSidebar])

    const [filters_data, setFiltersData] = useState({});

    const fetchWbAccounts = async () => {
        try {
            const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids})
            let wbaccOptions = []

            for (let i in fetchedDataWbacc.data) {
                wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id})
            }

            setSelectorFilter('wbacc_id', user.wbacc_ids[0])
            setSelectorFilter('wbacc_options', wbaccOptions)
        } catch (error) {
            console.error('Error fetching WB accounts:', error);
        }
    };

    const fetchDiff = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('orders-sales/diff/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
                group_type: selectorFilter.group,
                period_type:selectorFilter.period
            });
            console.log('responseDiff', response)
            setDataDiff(response);

            setFiltersData(response.filters || {});

        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchOrders= async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('orders-sales/orders/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
                data_type:'orders'
            });
            console.log('responseOrders', response)
            setDataOrders(response);

            setFiltersData(response.filters || {});

        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSales= async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('orders-sales/orders/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
                data_type:'sales'
            });
            console.log('responseSales', response)
            setDataSales(response);

            setFiltersData(response.filters || {});

        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchReturns= async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('orders-sales/orders/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
                data_type:'returns'
            });
            console.log('responseReturns', response)
            setDataReturns(response);

            setFiltersData(response.filters || {});

        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchGeo = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('orders-sales/geo/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
                geo_type: selectorFilter.geo_type
            });
            setDataGeo(response.data);
            setFiltersData(response.filters || {});

        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const fetchABC = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('orders-sales/abc/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
                group_type: selectorFilter.group
            });
            console.log('responseAbc', response)
            setDataAbc(response.data);
            setFiltersData(response.filters || {});

        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchWbAccounts();
        }
    }, [user.wbacc_ids]);

    useEffect(() => {
        if (selectorFilter.wbacc_id && activeTab) {
            fetchData(activeTab);
        }
    }, [selectorFilter.wbacc_id, selectorFilter.group, activeTab,period,selectorFilter.geo_type,selectorFilter.period]);

    const fetchData = (tab) => {
        if (!selectorFilter.wbacc_id) return;
        
        switch(tab) {
            case 'orders':
                fetchOrders()
                break;
            case 'sales':
                fetchSales()
                break;
            case 'returns':
                fetchReturns();
                break;
            case 'abc':
                fetchABC();
                break;
            case 'dynamics':
                fetchDiff();
                break;
            case 'geography':
                fetchGeo();
                break;
        }
    };

    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            );
            if (filterExists) return prev;
            return [...prev, {type, value}];
        });
    };
    
    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
        fetchData(newTab);
    };

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index));
    };

    const menuItems = useFilterLogic(
        filters_data,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    );

    const filterMenu = <Menu items={menuItems}/>;
    const renderContent = () => {
        switch (activeTab) {
            case 'orders':
                return <OrdersTable 
                    data={dataOrders?.data} 
                    loading={loading} 
                    onRefresh={() => fetchOrders()}
                    chartData={dataOrders?.chart_data}
                />;
            case 'sales':
                return <SalesTable 
                    data={dataSales?.data}
                    loading={loading}
                    onRefresh={() => fetchSales()}
                    chartData={dataSales?.chart_data}
                />;
            case 'returns':
                return <ReturnsTable 
                    data={dataReturns?.data}
                    loading={loading}
                    onRefresh={() => fetchReturns()}
                />;
            case 'abc':
                return <AbcTable data={dataAbc} loading={loading} selectorFilter={selectorFilter} setSelectorFilter={setSelectorFilter} group={selectorFilter.group} filters_data={filters_data} />;
            case 'dynamics':
                return (
                    <div>
                        <DynamicsTable 
                            data={dataDiff?.data || []} 
                            loading={loading}
                            metric={selectedMetric}
                            selectorFilter={selectorFilter}
                            setSelectorFilter={setSelectorFilter}
                            activeFilters={activeFilters}
                            period={period}
                        />
                    </div>
                );
            case 'geography':
                return <GeoTable data={dataGeo} loading={loading} geoType={selectorFilter.geo_type} onGeoTypeChange={value => setSelectorFilter('geo_type', value)} geoTypeOptions={selectorFilter.geo_type_options} />;
            default:
                return null;
        }
    };

    return (
        <>
        <Card>
        <Col>
                            <Space>
                                <Title level={3} style={{margin: 0}}>Заказы и продажи</Title>
                                {/* <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip> */}
                            </Space>
                        </Col>
            <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                <Col lg={3} className='mt-4' xs={24}>
                <Typography.Title level={5} className="!mb-4">Период данных</Typography.Title>
                                    <DatePickerRange
                                        selected={period}
                                        setSelected={(dates) => {
                                            if (dates) {
                                                setPeriod([dates[0].startOf('day'), dates[1].endOf('day')])
                                            } else {
                                                setPeriod([dayjs().add(-30, 'd').startOf('day'), dayjs().add(-1, 'd').endOf('day')])
                                            }
                                        }}
                                    />
                </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='Выберите магазин'
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
            
      </Col>
                    <ButtonExel />
                </Row>
        </Card>
            <Card bodyStyle={{ padding: '0 24px 24px' }}>
           
                <Tabs
                    defaultActiveKey="orders"
                    items={[
                        {
                            key: 'orders',
                            label: 'Заказы',
                            children: renderContent()
                        },
                        {
                            key: 'sales',
                            label: 'Продажи',
                            children: renderContent()
                        },
                        {
                            key: 'returns',
                            label: 'Возвраты',
                            children: renderContent()
                        },
                        {
                            key: 'abc',
                            label: 'ABC-Анализ',
                            children: renderContent()
                        },
                        {
                            key: 'dynamics',
                            label: 'Динамика',
                            children: renderContent()
                        },
                        {
                            key: 'geography',
                            label: 'География',
                            children: renderContent()
                        }
                    ]}
                    onChange={handleTabChange}
                    className="orders-tabs"
                />
            </Card>
        </>
    );
}
