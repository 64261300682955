import React, { useEffect, useState, useCallback } from 'react'
import {Button, Card, Col, Dropdown, Input, Menu, Row, Select, Space, Tag, Tooltip, Typography} from 'antd'
import { useAuth } from '../../hooks/useAuth'
import dayjs from 'dayjs'
import DatePickerRange from '../../components/dateRange'
import { apiAxios } from "../../utils/api_axios";
import FinDetailTable from './components/finDetailTable'
import { ButtonExel } from '../../components/Buttons'
import { CiCircleQuestion } from 'react-icons/ci'
import { useSidebar } from '../../Context/SideBarContext'
import SideBarPnlDetail from '../home/sideBar/SideBarPnlDetail'
import Sidebar from '../home/sideBar/SideBar'
import {applyAllFilters, handleQuickFilter, handleRemoveFilter, useFilterLogic} from "../components/filterUtils";
import {FilterOutlined, ReloadOutlined} from "@ant-design/icons";
import { useSelectorFilter } from '../../Context/SelectorFilterContext'

export default function FinDetail() {
    const { user } = useAuth()
    const {selectorFilter,setSelectorFilter} = useSelectorFilter()
    const { openSidebar } = useSidebar()
    
    const [data, setData] = useState([])
    const [filters_data, setFiltersData] = useState([])
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')])

    const handleOpenSidebar = useCallback(() => {
        console.log('Button clicked')
        openSidebar(<SideBarPnlDetail />)
    }, [openSidebar])

    const [loading, setLoading] = useState(true)

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []
        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }
        // console.log(user.wbacc_ids)
        // console.log(wbaccOptions)
        setSelectorFilter('wbacc_ids', [user.wbacc_ids[0]])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const fetchData = async () => {
        setLoading(true)
        const fetchedData = await apiAxios.post('fin/detail/get/', {
            wbacc_ids: selectorFilter.wbacc_ids,
            st: period[0].format('YYYY-MM-DD'),
            en: period[1].format('YYYY-MM-DD'),
            is_week: selectorFilter.week === 2,
            group_type: selectorFilter.group
        })
        console.log('fetchedData', fetchedData)
        setData(fetchedData.data)
        setFiltersData(fetchedData.filters)
        setLoading(false)
    }
    useEffect(() => {
        if (!selectorFilter.wbacc_ids && user.wbacc_ids) {
            fetchData1()
        }
        if (selectorFilter.wbacc_ids) {
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_ids, selectorFilter.group, selectorFilter.week, period])

    const [activeFilters, setActiveFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {setDebouncedSearchText(searchText);}, 1000);
        return () => {clearTimeout(handler);};
    }, [searchText]);
  const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showZeroProfit: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false,
        showNoPlan: false,
    });

    const quickFilterButtons = [
        {key: 'showNegativeMargin', label: 'Отрицательная маржа', filter: item => item.marzha < 0},
        //{key: 'showZeroProfit', label: 'Чистый убыток', filter: item => item.profit + item.sebes < 0},
        {key: 'showNoSales', label: 'Нет заказов', filter: item => item.orders_per_day < 0.2 && item.ost > 10},
        {key: 'showLowStock', label: 'Низкий остаток', filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2},
        {key: 'showHighStock', label: 'Высокий остаток', filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2},
        {key: 'showLongTurnover', label: 'Долгая оборачиваемость', filter: item => item.oborot_orders > 60},
        {key: 'showLowBuyout', label: 'Низкий выкуп', filter: item => item.buyout_percent < 30 && item.buyout_percent > 0},
    ];

    const filterMenu = <Menu items={useFilterLogic(
        filters_data,
        setActiveFilters
    )}/>;
    const filteredData = applyAllFilters(data, quickFilters, quickFilterButtons, activeFilters, debouncedSearchText)
    return (
        <>
            <Card>
                <Typography.Title level={1}>Прибыль и убытки детализация</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            mode='multiple'
                            maxTagCount={'responsive'}
                            style={{ width: '100%' }}
                            placeholder='Выберите магазин'
                            value={selectorFilter.wbacc_ids}
                            onChange={(value) => setSelectorFilter('wbacc_ids', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Группировка</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.group}
                            onChange={(value) => {
                                setSelectorFilter('group', value)
                            }}
                            options={selectorFilter.group_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Группировка по дням</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.week}
                            onChange={(value) => {
                                setSelectorFilter('week', value)
                            }}
                            options={selectorFilter.week_options}
                        />
                    </Col>

                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar />

            <Card>
                <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                    <Space wrap>
                        <Input placeholder="Поиск по артикулу" value={searchText} onChange={(e) => {setSearchText(e.target.value);}}/>
                        <Dropdown overlay={filterMenu} trigger={['click']}><Button icon={<FilterOutlined/>}>Добавить фильтр</Button></Dropdown>
                        <Button icon={<ReloadOutlined/>} onClick={fetchData}>Обновить</Button>
                        <ButtonExel data={filteredData} />
                        {activeFilters.map((filter, index) => (
                            <Tag key={index} closable onClose={() => handleRemoveFilter(index, setActiveFilters)}>{`${filter.type}: ${filter.value.label}`}</Tag>
                        ))}
                    </Space>
                    <Space wrap>
                        {quickFilterButtons.map(button => (
                            <Button key={button.key} type={quickFilters[button.key] ? 'primary' : 'default'}
                                    onClick={() => handleQuickFilter(button.key, quickFilters, setQuickFilters)}>{button.label}</Button>
                        ))}
                    </Space>
                </Space>
                <FinDetailTable data={filteredData} loading={loading} />
            </Card>
        </>
    )
}
