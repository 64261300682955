import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'

const SelectorFilter = createContext()

export const useSelectorFilter = () => useContext(SelectorFilter)

export const SelectorFilterProvider = ({ children }) => {
const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
        group: 'nm_id',
        group_options: [
            {label: 'Без группировки', value: 'nm_id'},
            {label: 'Категория', value: 'category'},
            {label: 'Сезон', value: 'sezon'},
            {label: 'Модель', value: 'model'},
            {label: 'Материал', value: 'material'},
            {label: 'Статус', value: 'status'},
            {label: 'Цвет', value: 'color'}
        ],
        period: 'day',
        period_options: [
            {label: 'По дням', value: 'day'},
            {label: 'По неделям', value: 'week'},
            {label: 'По месяцам', value: 'month'},
            {label: 'По кварталам', value: 'quarter'},
        ],
        geo_type: 'warehouse',
        geo_type_options: [
            {label: 'По складам', value: 'warehouse'},
            {label: 'По регионам', value: 'region'}
        ],
        week: 1,
        week_options: [
            { label: 'По дням', value: 1 },
            { label: 'По неделям', value: 2 }
        ]
    });

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const value = useMemo(
        () => ({
            selectorFilter,
            setSelectorFilter1,
            setSelectorFilter,
        }),
        [selectorFilter, setSelectorFilter1, setSelectorFilter,]
    )

    return <SelectorFilter.Provider value={value}>{children}</SelectorFilter.Provider>
}
