// import React, {useState, useRef, useMemo} from 'react'
// import {Table, Typography, Space, Button, Dropdown, Tag, Menu, Input} from 'antd'
// import {FilterOutlined, CloseOutlined, ReloadOutlined, PlusOutlined, MinusOutlined} from '@ant-design/icons'
// import {formatCurrency, formatDays2, formatPercent, formatPercent2, formatQuantity} from '../../../utils/formats';
// import '../../products/components/PriceTable.css'
// import {useFilterLogic, applyAllFilters, handleRemoveFilter, handleQuickFilter} from '../../components/filterUtils'
// import * as XLSX from "xlsx";

// const {Text} = Typography

// function getDayColumns(dayStat, drrType) {
//     if (!dayStat || dayStat.length === 0) return [];
//     let resultColumns = []

//     dayStat.forEach((day, indexDay) => {
//         const newColumn = {
//             title: day['date_show'],
//             className: 'border_left',
//             width: 180,
//             render: (record) => {
//                 const dayData = record.by_days[day.date];
//                 if (!dayData) {
//                     return <div></div>
//                 }
//                 const ordersStyle = dayData.orders > dayData.orders_per_day ? {color: '#52c41a'} : {color: '#ff4d4f'};
//                 const otklStyle = dayData.otkl === 0 ? {} :
//                     dayData.otkl > 100 ? {color: '#52c41a'} :
//                         {color: '#ff4d4f'};
//                 const drrStyle = dayData.drr === 0 ? {} :
//                     dayData.drr > dayData.budget_percent ? {color: '#ff4d4f'} :
//                         {color: '#52c41a'};
//                 const buyoutStyle = dayData.buyout_percent === 0 ? {} :
//                     dayData.buyout_percent > dayData.plan_buyout_percent ? {color: '#52c41a'} :
//                         {color: '#ff4d4f'};
//                 const oborotStyle = dayData.oborot_orders === 0 ? {} :
//                     dayData.oborot_orders < 60 ? {color: '#52c41a'} :
//                         {color: '#ff4d4f'};

//                 return (
//                     <Space direction="vertical" size={0}>
//                         <Space align="start" size={12}>
//                             <Space direction="vertical" size={0} className="cost-info-cell">
//                                 <Text><strong>Штук:</strong> <span style={ordersStyle}>{formatQuantity(dayData.orders)}</span> <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>({formatCurrency(dayData.price_with_discount)})</span></Text>
//                                 <Text><strong>Рублей:</strong> {formatCurrency(dayData.orders_sum)} <span style={otklStyle}>({formatPercent(dayData.otkl)})</span></Text>
//                                 <Text><strong>Маркетинг:</strong> {formatCurrency(dayData.budget)} <span style={drrStyle}>({formatPercent(drrType==='orders' ? dayData.drr * dayData.buyout_percent / 100 :dayData.drr)})</span></Text>
//                                 <Text><strong>CTR:</strong> {formatPercent(dayData.ctr)}</Text>
//                                 <Text><strong>Оборачиваемость:</strong> <span style={oborotStyle}>{formatDays2(dayData.oborot_orders)}</span></Text>
//                                 <Text><strong>Выкуп:</strong> <span style={buyoutStyle}>{formatPercent(dayData.buyout_percent)}</span></Text>
//                             </Space>
//                         </Space>
//                     </Space>
//                 );
//             }
//         }
//         resultColumns.push(newColumn)
//     })

//     return resultColumns
// }

// const expandDaysStat = (cards) => {
//     if (!cards || cards.length === 0 || !cards[0].days_stat) return [];

//     const expandedCards = cards.map(card => {
//         const newCard = {...card}
//         card.days_stat.forEach((day, index) => {
//             newCard[`col${index}_plan`] = day.plan
//             newCard[`col${index}_fact`] = day.fact
//             newCard[`col${index}_completion`] = (day.fact / day.plan) * 100
//             newCard[`col${index}_revenue`] = day.revenue
//             newCard[`col${index}_profit`] = day.profit
//         })
//         return newCard
//     })
//     return expandedCards
// }

// export const PlanFactTable3 = ({cards, loading, drrType, is_our}) => {
//     const isMobile = window.innerWidth <= 769

//     const expandedCards = expandDaysStat(cards)

//     const baseColumns = useMemo(() => [
//         // Товар
//         {
//             title: 'Товар',
//             key: 'product',
//             width: 300,
//             fixed: isMobile ? '' : 'left',
//             render: (_, record) => (
//                 <Space direction="vertical" size={0}>
//                     <Space align="start" size={5}>
//                         <img src={record.img || 'assets/images/logo.svg'} style={{width: '60px', height: 'auto', marginTop: '8px'}} alt="Product"/>
//                         <Space direction="vertical" size={0} className="cost-info-cell">
//                             <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
//                             <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
//                             <Text><strong>Название:</strong> {record.name}</Text>
//                             <Text><strong>Карточка:</strong> {record.imt_id}</Text>
//                             <Text><strong>Категория:</strong> {record.category}</Text>
//                         </Space>
//                     </Space>
//                 </Space>
//             )
//         },
//         // Информация о товаре
//         {
//             title: 'Информация о товаре',
//             width: 250,
//             render: (_, record) => {
//                 const empty = ['', '-', ' ']
//                 const hasEmptyField = empty.includes(record.material) || empty.includes(record.status) || empty.includes(record.sezon) || empty.includes(record.color) || empty.includes(record.model);
//                 const cellStyle = hasEmptyField ? {backgroundColor: '#faad14', padding: '8px'} : {padding: '8px'};

//                 return (
//                     <Space size={24} className="cost-info-cell" style={{width: '100%', ...cellStyle}}>
//                         <Space direction="vertical" size={0}>
//                             <Text><strong>Бренд:</strong> {record.brand}</Text>
//                             <Text><strong>Модель:</strong> {record.model}</Text>
//                             <Text><strong>Материал:</strong> {record.material}</Text>
//                             <Text><strong>Статус:</strong> {record.status}</Text>
//                             <Text><strong>Сезон:</strong> {record.sezon}</Text>
//                             <Text><strong>Цвет:</strong> {record.color}</Text>
//                         </Space>
//                     </Space>
//                 );
//             }
//         },

//         // Складские остатки
//         {
//             title: 'Остатки',
//             width: 200,
//             render: (_, record) => (
//                 <Space direction="vertical" size={0} className="cost-info-cell">
//                     <Text><strong>Всего:</strong> {formatQuantity(record.full)}</Text>
//                     <Text><strong>FBO:</strong> {formatQuantity(record.ost)}</Text>
//                     <Text><strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}</Text>
//                     <Text><strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}</Text>
//                     <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes * record.full)}</Text>
//                     <Text><strong>Деньги в товаре:</strong> {formatCurrency(record.ost_sum)}</Text>
//                 </Space>
//             )
//         },
//         // Складские остатки
//         ...(is_our ? [{
//             title: 'Другие остатки',
//             width: 150,
//             render: (_, record) => (
//                 <Space direction="vertical" size={0} className="cost-info-cell">
//                     <Text><strong>Остаток МС:</strong> {formatQuantity(record.ms_ost || 0)}</Text>
//                     <Text><strong>Транзит:</strong> {formatQuantity(record.ms_transfer || 0)}</Text>
//                     <Text><strong>Шьется:</strong> {formatQuantity(record.ms_production || 0)}</Text>
//                 </Space>
//             )
//         }] : []),
//         // Аналитика
//         {
//             title: 'Показатели',
//             width: 170,
//             render: (_, record) => (
//                 <Space direction="vertical" size={0} className="cost-info-cell">
//                     <Text><strong>Цена:</strong> {formatCurrency(record.price_with_wb_discount)}</Text>
//                     <Text><strong>Маржинальность:</strong> {formatPercent(record.marzha)}</Text>
//                     <Text><strong>Оборачиваемость:</strong> {formatDays2(record.oborot_orders)}</Text>
//                     <Text><strong>ABC:</strong> {record.abc}</Text>
//                 </Space>
//             )
//         },
//         {
//             title: 'Факт',
//             width: 170,
//             render: (_, record) => (
//                 <Space direction="vertical" size={0} className="cost-info-cell">
//                     <Text><strong>Шт:</strong> {formatQuantity(record.orders)}</Text>
//                     <Text><strong>Руб:</strong> {formatCurrency(record.orders_sum)}</Text>
//                     <Text><strong>Шт в день:</strong> {formatQuantity(record.orders_per_day)}</Text>
//                     <Text><strong>Руб в день:</strong> {formatCurrency(record.orders_per_day * record.price_with_wb_discount)}</Text>
//                     <Text><strong>Выкуп руб:</strong> {formatCurrency(record.buyouts_sum)}</Text>
//                     <Text><strong>Маркетинг:</strong> {formatCurrency(record.budget)}</Text>

//                 </Space>
//             )
//         },
//         // План
//         {
//             title: 'План',
//             width: 200,
//             render: (_, record) => (
//                 <Space direction="vertical" size={0} className="cost-info-cell">
//                     <Text><strong>Шт:</strong> {formatQuantity(record.plan_orders)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
//                         ({formatPercent2(record.plan_orders_percent || (record.orders / record.plan_orders * 100))})
//                     </span></Text>
//                     <Text><strong>Руб:</strong> {formatCurrency(record.plan_orders_sum)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
//                         ({formatPercent2(record.plan_orders_sum_percent || (record.orders_sum / record.plan_orders_sum * 100))})
//                     </span></Text>
//                     <Text><strong>Шт в день:</strong> {formatQuantity(record.plan_orders_day)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
//                         ({formatPercent2(record.plan_orders_day_percent || (record.orders_per_day / record.plan_orders_day * 100))})
//                     </span></Text>
//                     <Text><strong>Руб в день:</strong> {formatCurrency(record.plan_orders_sum_day)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
//                         ({formatPercent2(record.plan_orders_sum_day_percent || (record.orders_per_day * record.price_with_wb_discount / record.plan_orders_sum_day * 100))})
//                     </span></Text>
//                     <Text><strong>Выкуп руб:</strong> {formatCurrency(record.plan_buyouts_sum)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
//                         ({formatPercent2(record.plan_buyouts_sum_percent || (record.buyouts_sum / record.plan_buyouts_sum * 100))})
//                     </span></Text>
//                     <Text><strong>Маркетинг:</strong> {formatCurrency(record.plan_budget)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
//                         ({formatPercent2(record.plan_budget_percent || (record.budget / record.plan_budget * 100))})
//                     </span></Text>
//                 </Space>
//             )
//         },
//     ], [is_our])

//     console.log('cards', cards)
//     const dayColumns = getDayColumns(cards[0]?.days_stat || [], drrType)
//     const allColumns = [...baseColumns, ...dayColumns]

//     // Функция для подсчета суммарных значений
//     const calculateSummary = (data) => {
//         if (!data || data.length === 0 || !cards[0]?.days_stat) return null;

//         const summary = {
//             article: 'Итого',
//             by_days: {},
//             // Добавляем поля для отображения в колонке товара
//             material: 'Суммарная информация',
//             brand: `Всего товаров: ${data.length}`,
//             category: '',
//             status: '',
//             sezon: '',
//             color: '',
//             // Добавляем суммарные значения для остатков
//             ost: 0,
//             way_to: 0,
//             way_from: 0,
//             sebes: 0,
//             full: 0,
//             ost_sum: 0,
//             // Другие остатки
//             ms_ost: 0,
//             ms_transfer: 0,
//             ms_production: 0,
//             // Добавляем суммарные значения для показателей
//             price_with_wb_discount: 0,
//             orders: 0,
//             orders_sum: 0,
//             buyouts_sum: 0,
//             budget: 0,
//             orders_per_day: 0,
//             marzha: 0,
//             oborot_orders: 0,
//             abc: '',
//             // Добавляем суммарные значения для плана
//             plan_orders: 0,
//             plan_orders_sum: 0,
//             plan_orders_day: 0,
//             plan_orders_sum_day: 0,
//             plan_buyouts_sum: 0,
//             plan_budget: 0,
//             fact_orders: 0,
//             fact_orders_day: 0,
//             fact_orders_sum: 0,
//             fact_buyouts_sum: 0,
//             fact_budget: 0,
//             plan_orders_percent: 0,
//             plan_orders_sum_percent: 0,
//             plan_orders_day_percent: 0,
//             plan_orders_sum_day_percent: 0,
//             plan_buyouts_sum_percent: 0,
//             plan_budget_percent: 0,

//         };

//         let totalOrders = 0;

//         // Инициализируем структуру для каждого дня
//         let totalDays = 0
//         cards[0].days_stat.forEach(day => {
//             totalDays += 1
//             return summary.by_days[day.date] = {
//                 orders: 0,
//                 orders_sum: 0,
//                 budget: 0,
//                 orders_per_day: 0,
//                 budget_percent: 5,
//             };
//         });

//         // Суммируем значения по дням и общие показатели
//         data.forEach(record => {
//             // Суммируем остатки
//             summary.ost += record.ost || 0;
//             summary.way_to += record.way_to || 0;
//             summary.way_from += record.way_from || 0;
//             summary.ost_sum += record.ost_sum || 0;
//             if (record.sebes && record.full) {
//                 summary.sebes += record.sebes * record.full;
//             }

//             // Другие остатки
//             summary.ms_ost += record.ms_ost || 0;
//             summary.ms_transfer += record.ms_transfer || 0;
//             summary.ms_production += record.ms_production || 0;

//             // Суммируем показатели
//             summary.orders += record.orders || 0;
//             summary.orders_sum += record.orders_sum || 0;
//             summary.buyouts_sum += record.buyouts_sum || 0;
//             summary.budget += record.budget || 0;
//             summary.orders_per_day += record.orders_per_day || 0;

//             // Считаем взвешенные средние для процентных показателей
//             if (record.orders_per_day > 0) {
//                 summary.marzha += (record.marzha || 0) * record.orders_per_day;
//                 summary.oborot_orders += (record.oborot_orders || 0) * record.orders_per_day;
//                 summary.price_with_wb_discount += (record.price_with_wb_discount || 0) * record.orders_per_day;
//                 totalOrders += record.orders_per_day;
//             }

//             // Суммируем план
//             summary.plan_orders += record.plan_orders || 0;
//             summary.plan_orders_sum += record.plan_orders_sum || 0;

//             summary.plan_orders_day += record.plan_orders_day || 0;
//             summary.plan_orders_sum_day += record.plan_orders_sum_day || 0;
//             summary.plan_buyouts_sum += record.plan_buyouts_sum || 0;
//             summary.plan_budget += record.plan_budget || 0;

//             if (record.plan_orders > 0) {
//                 summary.fact_orders_day += record.orders_per_day;
//             }
//             // Суммируем значения по дням
//             if (record.by_days) {
//                 Object.keys(record.by_days).forEach(date => {
//                     const dayData = record.by_days[date];
//                     if (dayData) {
//                         summary.by_days[date].orders += dayData.orders || 0;
//                         summary.by_days[date].orders_sum += dayData.orders_sum || 0;
//                         summary.by_days[date].budget += dayData.budget || 0;
//                         summary.by_days[date].orders_per_day += dayData.orders_per_day || 0;
//                         summary.fact_budget += dayData.budget || 0;

//                         if (record.plan_orders > 0) {
//                             summary.fact_orders += dayData.orders || 0;
//                             summary.fact_orders_sum += dayData.orders_sum || 0;
//                             summary.fact_buyouts_sum += dayData.buyouts_sum || 0;

//                         }
//                         // Суммируем значения для взвешенного среднего
//                         if (dayData.orders_per_day > 0) {
//                             if (!summary.by_days[date].weighted) {
//                                 summary.by_days[date].weighted = {
//                                     drr: 0,
//                                     ctr: 0,
//                                     buyout_percent: 0,
//                                     oborot_orders: 0,
//                                     price_with_discount: 0,
//                                     totalOrders: 0,
//                                     totalOrdersBudget: 0,
//                                 };
//                             }

//                             summary.by_days[date].weighted.buyout_percent += (dayData.buyout_percent || 0) * dayData.orders_per_day;
//                             summary.by_days[date].weighted.oborot_orders += (dayData.oborot_orders || 0) * dayData.orders_per_day;
//                             summary.by_days[date].weighted.price_with_discount += (dayData.price_with_discount || 0) * dayData.orders_per_day;
//                             summary.by_days[date].weighted.totalOrders += dayData.orders_per_day;
//                         }
//                         if (dayData.drr > 0) {
//                             if (!summary.by_days[date].weighted) {
//                                 summary.by_days[date].weighted = {
//                                     drr: 0,
//                                     ctr: 0,
//                                     buyout_percent: 0,
//                                     oborot_orders: 0,
//                                     price_with_discount: 0,
//                                     totalOrders: 0,
//                                     totalOrdersBudget: 0,
//                                 };
//                             }
//                             summary.by_days[date].weighted.drr += (dayData.drr || 0) * dayData.orders_per_day;
//                             summary.by_days[date].weighted.ctr += (dayData.ctr || 0) * dayData.orders_per_day;
//                             summary.by_days[date].weighted.totalOrdersBudget += dayData.orders_per_day;
//                         }
//                     }
//                 });
//             }
//         });

//         // Вычисляем средние значения для процентных показателей
//         if (totalOrders > 0) {
//             summary.marzha = summary.marzha / totalOrders;
//             summary.oborot_orders = summary.oborot_orders / totalOrders;
//             summary.price_with_wb_discount = summary.price_with_wb_discount / totalOrders;
//         }

//         // Вычисляем проценты для плана
//         if (summary.plan_orders > 0) {
//             summary.plan_orders_percent = (summary.fact_orders / summary.plan_orders) * 100;
//             summary.plan_orders_sum_percent = (summary.fact_orders_sum / summary.plan_orders_sum) * 100;
//             summary.plan_orders_day_percent = (summary.fact_orders_day / summary.plan_orders_day) * 100;
//             summary.plan_orders_sum_day_percent = (summary.fact_orders_sum / summary.plan_orders_sum_day / totalDays) * 100;
//             summary.plan_buyouts_sum_percent = (summary.fact_buyouts_sum / summary.plan_buyouts_sum) * 100;
//             summary.plan_budget_percent = (summary.fact_budget / summary.plan_budget) * 100;
//         }

//         // Вычисляем средние значения для каждого дня
//         Object.keys(summary.by_days).forEach(date => {
//             const dayData = summary.by_days[date];
//             if (dayData.weighted && dayData.weighted.totalOrders > 0) {
//                 dayData.drr = dayData.weighted.drr / dayData.weighted.totalOrdersBudget;
//                 dayData.ctr = dayData.weighted.ctr / dayData.weighted.totalOrdersBudget;
//                 dayData.buyout_percent = dayData.weighted.buyout_percent / dayData.weighted.totalOrders;
//                 dayData.oborot_orders = dayData.weighted.oborot_orders / dayData.weighted.totalOrders;
//                 dayData.price_with_discount = dayData.weighted.price_with_discount / dayData.weighted.totalOrders;
//             }
//             delete dayData.weighted; // Удаляем промежуточные данные
//         });
//         console.log('summary', summary)
//         return summary;
//     };

//     // Добавляем суммарную строку как первый элемент
//     const summaryRow = calculateSummary(expandedCards);
//     const dataWithSummary = summaryRow ? [summaryRow, ...expandedCards] : expandedCards;

//     return (
//         <div className="price-table-container">

//             <Table
//                 dataSource={dataWithSummary}
//                 columns={allColumns}
//                 size="small"
//                 scroll={{x: 1500}}
//                 loading={loading}
//                 rowKey="article"
//                 pagination={{
//                     defaultPageSize: 10,
//                     showSizeChanger: true,
//                     showTotal: (total) => `Всего ${total - 1} товаров`
//                 }}
//                 className="plan-fact-table"
//             />
//         </div>
//     );
// };

import React, { useState, useMemo } from 'react'
import { Table, Typography, Space, Button } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { formatCurrency, formatDays2, formatPercent, formatPercent2, formatQuantity } from '../../../utils/formats'
import '../../products/components/PriceTable.css'

const { Text } = Typography

function getDayColumns(dayStat, drrType, expandedKeys) {
    if (!dayStat || dayStat.length === 0) return []
    let resultColumns = []

    dayStat.forEach((day, indexDay) => {
        const newColumn = {
            title: day['date_show'],
            className: 'border_left',
            width: 180,
            render: (record) => {
                const dayData = record.by_days[day.date]
                if (!dayData) {
                    return <div></div>
                }
                const ordersStyle = dayData.orders > dayData.orders_per_day ? { color: '#52c41a' } : { color: '#ff4d4f' }
                const otklStyle = dayData.otkl === 0 ? {} : dayData.otkl > 100 ? { color: '#52c41a' } : { color: '#ff4d4f' }
                const drrStyle = dayData.drr === 0 ? {} : dayData.drr > dayData.budget_percent ? { color: '#ff4d4f' } : { color: '#52c41a' }
                const buyoutStyle = dayData.buyout_percent === 0 ? {} : dayData.buyout_percent > dayData.plan_buyout_percent ? { color: '#52c41a' } : { color: '#ff4d4f' }
                const oborotStyle = dayData.oborot_orders === 0 ? {} : dayData.oborot_orders < 60 ? { color: '#52c41a' } : { color: '#ff4d4f' }

                return (
                    <Space direction='vertical' size={0}>
                        <Space align='start' size={12}>
                            <Space direction='vertical' size={0} className='cost-info-cell'>
                                <Text>
                                    <strong>Штук:</strong> <span style={ordersStyle}>{formatQuantity(dayData.orders)}</span>{' '}
                                    {expandedKeys.includes(record.key) && <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>({formatCurrency(dayData.price_with_discount)})</span>}
                                </Text>
                                <Text>
                                    <strong>Рублей:</strong> {formatCurrency(dayData.orders_sum)} {expandedKeys.includes(record.key) && <span style={otklStyle}>({formatPercent(dayData.otkl)})</span>}
                                </Text>
                                {expandedKeys.includes(record.key) && (
                                    <>
                                        <Text>
                                            <strong>Маркетинг:</strong> {formatCurrency(dayData.budget)}{' '}
                                            <span style={drrStyle}>({formatPercent(drrType === 'orders' ? (dayData.drr * dayData.buyout_percent) / 100 : dayData.drr)})</span>
                                        </Text>
                                        <Text>
                                            <strong>CTR:</strong> {formatPercent(dayData.ctr)}
                                        </Text>
                                        <Text>
                                            <strong>Оборачиваемость:</strong> <span style={oborotStyle}>{formatDays2(dayData.oborot_orders)}</span>
                                        </Text>
                                        <Text>
                                            <strong>Выкуп:</strong> <span style={buyoutStyle}>{formatPercent(dayData.buyout_percent)}</span>
                                        </Text>
                                    </>
                                )}
                            </Space>
                        </Space>
                    </Space>
                )
            }
        }
        resultColumns.push(newColumn)
    })

    return resultColumns
}

const expandDaysStat = (cards) => {
    if (!cards || cards.length === 0 || !cards[0].days_stat) return []

    const expandedCards = cards.map((card) => {
        const newCard = { ...card }
        card.days_stat.forEach((day, index) => {
            newCard[`col${index}_plan`] = day.plan
            newCard[`col${index}_fact`] = day.fact
            newCard[`col${index}_completion`] = (day.fact / day.plan) * 100
            newCard[`col${index}_revenue`] = day.revenue
            newCard[`col${index}_profit`] = day.profit
        })
        return newCard
    })
    return expandedCards
}

export const PlanFactTable3 = ({ cards, loading, drrType, is_our }) => {
    const [expandedKeys, setExpandedKeys] = useState([]) // Массив для хранения ключей раскрытых строк

    const toggleExpandRow = (key) => {
        setExpandedKeys(
            (prevKeys) =>
                prevKeys.includes(key)
                    ? prevKeys.filter((k) => k !== key) // Удаляем ключ, если он уже раскрыт
                    : [...prevKeys, key] // Добавляем ключ, если он не раскрыт
        )
    }

    const RenderRow = React.memo(({ record }) => {
        const expanded = expandedKeys.includes(record.key) // Проверяем, раскрыта ли строка
        return <Button type='text' icon={expanded ? <MinusOutlined /> : <PlusOutlined />} onClick={() => toggleExpandRow(record.key)} />
    })

    const isMobile = window.innerWidth <= 769
    const expandedCards = useMemo(() => expandDaysStat(cards), [cards])

    const baseColumns = useMemo(
        () => [
            {
                title: '',
                dataIndex: 'expand',
                key: 'expand',
                render: (_, record) => <RenderRow record={record} />,
                width: 50 // Задайте ширину по необходимости
            },
            {
                title: 'Товар',
                key: 'product',
                width: 300,
                fixed: isMobile ? '' : 'left',
                render: (_, record) => (
                    <Space direction='vertical' size={0}>
                        <Space align='start' size={5}>
                            <img src={record.img || 'assets/images/logo.svg'} className={`${expandedKeys.includes(record.key) ? 'w-[60px] h-auto mt-2' : 'w-[30px] h-auto '}`} alt='Product' />
                            <Space direction='vertical' size={0} className='cost-info-cell'>
                                <Text>
                                    <strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a>
                                </Text>
                                <Text>
                                    <strong>Артикул WB:</strong> {record.nm_id}
                                </Text>
                                {expandedKeys.includes(record.key) && (
                                    <>
                                        <Text>
                                            <strong>Название:</strong> {record.name}
                                        </Text>
                                        <Text>
                                            <strong>Категория:</strong> {record.category}
                                        </Text>
                                    </>
                                )}
                            </Space>
                        </Space>
                    </Space>
                )
            },
            // Информация о товаре
            {
                title: 'Информация о товаре',
                width: 250,
                render: (_, record) => {
                    const empty = ['', '-', ' ']
                    const hasEmptyField =
                        empty.includes(record.material) || empty.includes(record.status) || empty.includes(record.sezon) || empty.includes(record.color) || empty.includes(record.model)
                    const cellStyle = hasEmptyField ? { backgroundColor: '#faad14', padding: '8px' } : { padding: '8px' }

                    return (
                        <Space size={24} className='cost-info-cell' style={{ width: '100%', ...cellStyle }}>
                            <Space direction='vertical' size={0}>
                                <Text>
                                    <strong>Бренд:</strong> {record.brand}
                                </Text>
                                <Text>
                                    <strong>Модель:</strong> {record.model}
                                </Text>
                                {expandedKeys.includes(record.key) && (
                                    <>
                                        <Text>
                                            <strong>Материал:</strong> {record.material}
                                        </Text>
                                        <Text>
                                            <strong>Статус:</strong> {record.status}
                                        </Text>
                                        <Text>
                                            <strong>Сезон:</strong> {record.sezon}
                                        </Text>
                                        <Text>
                                            <strong>Цвет:</strong> {record.color}
                                        </Text>
                                    </>
                                )}
                            </Space>
                        </Space>
                    )
                }
            },

            // Складские остатки
            {
                title: 'Остатки',
                width: 200,
                render: (_, record) => (
                    <Space direction='vertical' size={0} className='cost-info-cell'>
                        <Text>
                            <strong>Всего:</strong> {formatQuantity(record.full)}
                        </Text>
                        {expandedKeys.includes(record.key) && (
                            <>
                                <Text>
                                    <strong>FBO:</strong> {formatQuantity(record.ost)}
                                </Text>
                                <Text>
                                    <strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}
                                </Text>
                                <Text>
                                    <strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}
                                </Text>
                                <Text>
                                    <strong>Себестоимость:</strong> {formatCurrency(record.sebes * record.full)}
                                </Text>
                            </>
                        )}
                        <Text>
                            <strong>Деньги в товаре:</strong> {formatCurrency(record.ost_sum)}
                        </Text>
                    </Space>
                )
            },
            // Складские остатки
            ...(is_our
                ? [
                      {
                          title: 'Другие остатки',
                          width: 150,
                          render: (_, record) => (
                              <Space direction='vertical' size={0} className='cost-info-cell'>
                                  <Text>
                                      <strong>Остаток МС:</strong> {formatQuantity(record.ms_ost || 0)}
                                  </Text>
                                  {expandedKeys.includes(record.key) && (
                                      <>
                                          <Text>
                                              <strong>Транзит:</strong> {formatQuantity(record.ms_transfer || 0)}
                                          </Text>
                                          <Text>
                                              <strong>Шьется:</strong> {formatQuantity(record.ms_production || 0)}
                                          </Text>
                                      </>
                                  )}
                              </Space>
                          )
                      }
                  ]
                : []),
            // Аналитика
            {
                title: 'Показатели',
                width: 170,
                render: (_, record) => (
                    <Space direction='vertical' size={0} className='cost-info-cell'>
                        <Text>
                            <strong>Цена:</strong> {formatCurrency(record.price_with_wb_discount)}
                        </Text>
                        {expandedKeys.includes(record.key) && (
                            <Text>
                                <strong>Маржинальность:</strong> {formatPercent(record.marzha)}
                            </Text>
                        )}
                        <Text>
                            <strong>Оборачиваемость:</strong> {formatDays2(record.oborot_orders)}
                        </Text>
                        {expandedKeys.includes(record.key) && (
                            <Text>
                                <strong>ABC:</strong> {record.abc}
                            </Text>
                        )}
                    </Space>
                )
            },
            {
                title: 'Факт',
                width: 170,
                render: (_, record) => (
                    <Space direction='vertical' size={0} className='cost-info-cell'>
                        <Text>
                            <strong>Шт:</strong> {formatQuantity(record.orders)}
                        </Text>
                        <Text>
                            <strong>Руб:</strong> {formatCurrency(record.orders_sum)}
                        </Text>
                        {expandedKeys.includes(record.key) && (
                            <>
                                <Text>
                                    <strong>Шт в день:</strong> {formatQuantity(record.orders_per_day)}
                                </Text>
                                <Text>
                                    <strong>Руб в день:</strong> {formatCurrency(record.orders_per_day * record.price_with_wb_discount)}
                                </Text>
                                <Text>
                                    <strong>Выкуп руб:</strong> {formatCurrency(record.buyouts_sum)}
                                </Text>
                                <Text>
                                    <strong>Маркетинг:</strong> {formatCurrency(record.budget)}
                                </Text>
                            </>
                        )}
                    </Space>
                )
            },
            // План
            {
                title: 'План',
                width: 200,
                render: (_, record) => (
                    <Space direction='vertical' size={0} className='cost-info-cell'>
                        <Text>
                            <strong>Шт:</strong> {formatQuantity(record.plan_orders)}{' '}
                            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>({formatPercent2(record.plan_orders_percent || (record.orders / record.plan_orders) * 100)})</span>
                        </Text>
                        <Text>
                            <strong>Руб:</strong> {formatCurrency(record.plan_orders_sum)}{' '}
                            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>({formatPercent2(record.plan_orders_sum_percent || (record.orders_sum / record.plan_orders_sum) * 100)})</span>
                        </Text>
                        {expandedKeys.includes(record.key) && (
                            <>
                                <Text>
                                    <strong>Шт в день:</strong> {formatQuantity(record.plan_orders_day)}{' '}
                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>({formatPercent2(record.plan_orders_day_percent || (record.orders_per_day / record.plan_orders_day) * 100)})</span>
                                </Text>
                                <Text>
                                    <strong>Руб в день:</strong> {formatCurrency(record.plan_orders_sum_day)}{' '}
                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                                        ({formatPercent2(record.plan_orders_sum_day_percent || ((record.orders_per_day * record.price_with_wb_discount) / record.plan_orders_sum_day) * 100)})
                                    </span>
                                </Text>
                                <Text>
                                    <strong>Выкуп руб:</strong> {formatCurrency(record.plan_buyouts_sum)}{' '}
                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>({formatPercent2(record.plan_buyouts_sum_percent || (record.buyouts_sum / record.plan_buyouts_sum) * 100)})</span>
                                </Text>
                                <Text>
                                    <strong>Маркетинг:</strong> {formatCurrency(record.plan_budget)}{' '}
                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>({formatPercent2(record.plan_budget_percent || (record.budget / record.plan_budget) * 100)})</span>
                                </Text>
                            </>
                        )}
                    </Space>
                )
            }
        ],
        [is_our, expandedKeys]
    )

    const dayColumns = useMemo(() => getDayColumns(cards[0]?.days_stat || [], drrType, expandedKeys), [cards, drrType, expandedKeys])
    const allColumns = useMemo(() => [...baseColumns, ...dayColumns], [baseColumns, dayColumns])

    // Функция для подсчета суммарных значений
    const calculateSummary = (data) => {
        if (!data || data.length === 0 || !cards[0]?.days_stat) return null

        const summary = {
            article: 'Итого',
            by_days: {},
            material: 'Суммарная информация',
            brand: `Всего товаров: ${data.length}`,
            category: '',
            status: '',
            sezon: '',
            color: '',
            ost: 0,
            way_to: 0,
            way_from: 0,
            sebes: 0,
            full: 0,
            ost_sum: 0,
            // Другие остатки
            ms_ost: 0,
            ms_transfer: 0,
            ms_production: 0,
            // Добавляем суммарные значения для показателей
            price_with_wb_discount: 0,
            orders: 0,
            orders_sum: 0,
            buyouts_sum: 0,
            budget: 0,
            orders_per_day: 0,
            marzha: 0,
            oborot_orders: 0,
            abc: '',
            // Добавляем суммарные значения для плана
            plan_orders: 0,
            plan_orders_sum: 0,
            plan_orders_day: 0,
            plan_orders_sum_day: 0,
            plan_buyouts_sum: 0,
            plan_budget: 0,
            fact_orders: 0,
            fact_orders_day: 0,
            fact_orders_sum: 0,
            fact_buyouts_sum: 0,
            fact_budget: 0,
            plan_orders_percent: 0,
            plan_orders_sum_percent: 0,
            plan_orders_day_percent: 0,
            plan_orders_sum_day_percent: 0,
            plan_buyouts_sum_percent: 0,
            plan_budget_percent: 0
        }
        let totalOrders = 0
        // Инициализируем структуру для каждого дня
        let totalDays = 0
        cards[0].days_stat.forEach((day) => {
            totalDays += 1
            return (summary.by_days[day.date] = {
                orders: 0,
                orders_sum: 0,
                budget: 0,
                orders_per_day: 0,
                budget_percent: 5
            })
        })
        // Суммируем значения по дням и общие показатели
        data.forEach((record) => {
            // Суммируем остатки
            summary.ost += record.ost || 0
            summary.way_to += record.way_to || 0
            summary.way_from += record.way_from || 0
            summary.ost_sum += record.ost_sum || 0
            if (record.sebes && record.full) {
                summary.sebes += record.sebes * record.full
            }
            // Другие остатки
            summary.ms_ost += record.ms_ost || 0
            summary.ms_transfer += record.ms_transfer || 0
            summary.ms_production += record.ms_production || 0
            // Суммируем показатели
            summary.orders += record.orders || 0
            summary.orders_sum += record.orders_sum || 0
            summary.buyouts_sum += record.buyouts_sum || 0
            summary.budget += record.budget || 0
            summary.orders_per_day += record.orders_per_day || 0
            // Считаем взвешенные средние для процентных показателей
            if (record.orders_per_day > 0) {
                summary.marzha += (record.marzha || 0) * record.orders_per_day
                summary.oborot_orders += (record.oborot_orders || 0) * record.orders_per_day
                summary.price_with_wb_discount += (record.price_with_wb_discount || 0) * record.orders_per_day
                totalOrders += record.orders_per_day
            }
            // Суммируем план
            summary.plan_orders += record.plan_orders || 0
            summary.plan_orders_sum += record.plan_orders_sum || 0
            summary.plan_orders_day += record.plan_orders_day || 0
            summary.plan_orders_sum_day += record.plan_orders_sum_day || 0
            summary.plan_buyouts_sum += record.plan_buyouts_sum || 0
            summary.plan_budget += record.plan_budget || 0
            if (record.plan_orders > 0) {
                summary.fact_orders_day += record.orders_per_day
            }
            // Суммируем значения по дням
            if (record.by_days) {
                Object.keys(record.by_days).forEach((date) => {
                    const dayData = record.by_days[date]
                    if (dayData) {
                        summary.by_days[date].orders += dayData.orders || 0
                        summary.by_days[date].orders_sum += dayData.orders_sum || 0
                        summary.by_days[date].budget += dayData.budget || 0
                        summary.by_days[date].orders_per_day += dayData.orders_per_day || 0
                        summary.fact_budget += dayData.budget || 0
                        if (record.plan_orders > 0) {
                            summary.fact_orders += dayData.orders || 0
                            summary.fact_orders_sum += dayData.orders_sum || 0
                            summary.fact_buyouts_sum += dayData.buyouts_sum || 0
                        }
                        // Суммируем значения для взвешенного среднего
                        if (dayData.orders_per_day > 0) {
                            if (!summary.by_days[date].weighted) {
                                summary.by_days[date].weighted = {
                                    drr: 0,
                                    ctr: 0,
                                    buyout_percent: 0,
                                    oborot_orders: 0,
                                    price_with_discount: 0,
                                    totalOrders: 0,
                                    totalOrdersBudget: 0
                                }
                            }
                            summary.by_days[date].weighted.buyout_percent += (dayData.buyout_percent || 0) * dayData.orders_per_day
                            summary.by_days[date].weighted.oborot_orders += (dayData.oborot_orders || 0) * dayData.orders_per_day
                            summary.by_days[date].weighted.price_with_discount += (dayData.price_with_discount || 0) * dayData.orders_per_day
                            summary.by_days[date].weighted.totalOrders += dayData.orders_per_day
                        }
                        if (dayData.drr > 0) {
                            if (!summary.by_days[date].weighted) {
                                summary.by_days[date].weighted = {
                                    drr: 0,
                                    ctr: 0,
                                    buyout_percent: 0,
                                    oborot_orders: 0,
                                    price_with_discount: 0,
                                    totalOrders: 0,
                                    totalOrdersBudget: 0
                                }
                            }
                            summary.by_days[date].weighted.drr += (dayData.drr || 0) * dayData.orders_per_day
                            summary.by_days[date].weighted.ctr += (dayData.ctr || 0) * dayData.orders_per_day
                            summary.by_days[date].weighted.totalOrdersBudget += dayData.orders_per_day
                        }
                    }
                })
            }
        })

        // Вычисляем средние значения для процентных показателей
        if (totalOrders > 0) {
            summary.marzha = summary.marzha / totalOrders
            summary.oborot_orders = summary.oborot_orders / totalOrders
            summary.price_with_wb_discount = summary.price_with_wb_discount / totalOrders
        }

        // Вычисляем проценты для плана
        if (summary.plan_orders > 0) {
            summary.plan_orders_percent = (summary.fact_orders / summary.plan_orders) * 100
            summary.plan_orders_sum_percent = (summary.fact_orders_sum / summary.plan_orders_sum) * 100
            summary.plan_orders_day_percent = (summary.fact_orders_day / summary.plan_orders_day) * 100
            summary.plan_orders_sum_day_percent = (summary.fact_orders_sum / summary.plan_orders_sum_day / totalDays) * 100
            summary.plan_buyouts_sum_percent = (summary.fact_buyouts_sum / summary.plan_buyouts_sum) * 100
            summary.plan_budget_percent = (summary.fact_budget / summary.plan_budget) * 100
        }

        // Вычисляем средние значения для каждого дня
        Object.keys(summary.by_days).forEach((date) => {
            const dayData = summary.by_days[date]
            if (dayData.weighted && dayData.weighted.totalOrders > 0) {
                dayData.drr = dayData.weighted.drr / dayData.weighted.totalOrdersBudget
                dayData.ctr = dayData.weighted.ctr / dayData.weighted.totalOrdersBudget
                dayData.buyout_percent = dayData.weighted.buyout_percent / dayData.weighted.totalOrders
                dayData.oborot_orders = dayData.weighted.oborot_orders / dayData.weighted.totalOrders
                dayData.price_with_discount = dayData.weighted.price_with_discount / dayData.weighted.totalOrders
            }

            delete dayData.weighted // Удаляем промежуточные данные
        })
        console.log('summary', summary)
        return summary
    }

    console.log('cards', cards)
    // Добавляем суммарную строку как первый элемент
    const summaryRow = calculateSummary(expandedCards)
    const dataWithSummary = summaryRow ? [summaryRow, ...expandedCards] : expandedCards

    return (
        <div className='price-table-container'>
            <Table
                dataSource={dataWithSummary}
                columns={allColumns}
                size='small'
                scroll={{ x: 1500 }}
                loading={loading}
                rowKey={(record) => record.key || record.article}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `Всего ${total - 1} товаров`
                }}
                className='plan-fact-table'
            />
        </div>
    )
}
