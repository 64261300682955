import {Flex, Input, Space, Table, Typography, Select} from 'antd'
import {formatCurrency, formatDays2, formatPercent, formatPercent2, formatQuantity} from '../../../utils/formats';
import React, { useMemo } from 'react'
import {XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar} from 'recharts'

const {Text} = Typography


export default function SalesPlanTable({cards, loading, setCards, onEditCard, is_our}) {
    const handleSave = (row) => {
        const newData = [...cards];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];


        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        onEditCard(row);
        setCards(newData);
    };

    
    const columns = useMemo(() => {
        const defaultMonthData = {
            month1: 0, month2: 0, month3: 0, month4: 0,
            month5: 0, month6: 0, month7: 0, month8: 0,
            month9: 0, month10: 0, month11: 0, month12: 0
        };

        const renderBarChart = (orders = defaultMonthData) => {
            
            // const data = Object.keys(orders).map((key) => ({
            //     month: Number(key.replace('month', '')),
            //     value: orders[key] || 0
            // }));
 if (!orders) return null; 
            const data = Array.from({ length: 12 }, (_, index) => {
                const monthKey = `month${index + 1}`;
                return {
                    month: index + 1,
                    value: orders[monthKey] || 0 // Используем значение из orders или 0
                };
            });
             data.sort((a, b) => a.month - b.month);
            return (
                <ResponsiveContainer width='100%' height={60}>
                    <BarChart data={data}>
                        <XAxis dataKey='month' hide/>
                        <YAxis hide/>
                        <Tooltip formatter={(value) => value} labelFormatter={(label) => `Месяц ${label}`}/>
                        <Bar dataKey='value' fill='#8884d8'/>
                    </BarChart>
                </ResponsiveContainer>
            );
        };
        return [
            {
                title: 'Товар',
                key: 'product',
                width: 280,
                render: (_, record) => (
                    <Space direction="vertical" size={0}>
                        <Space align="start" size={12}>
                            <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                                <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                                <Text><strong>Название:</strong> {record.name}</Text>
                                <Text><strong>Карточка:</strong> {record.imt_id}</Text>
                            </Space>
                        </Space>
                    </Space>
                )
            },
            // Информация о товаре
            {
                title: 'Информация о товаре',
                width: 200,
                render: (_, record) => (
                    <Space size={0} direction="vertical" className="cost-info-cell" style={{width: '100%'}}>
                        <Text><strong>Модель:</strong> {record.material}</Text>
                        <Text><strong>Бренд:</strong> {record.brand}</Text>
                        <Text><strong>Категория:</strong> {record.category}</Text>
                        <Text><strong>Статус:</strong> {record.status}</Text>
                        <Text><strong>Сезон:</strong> {record.sezon}</Text>
                        <Text><strong>Цвет:</strong> {record.color}</Text>

                    </Space>
                )
            },
            {title: 'Заказы за год', dataIndex: 'orders_year', width: 150, render: (orders) => renderBarChart(orders)},
            {title: 'Средний чек', dataIndex: 'check', width: 150, render: (orders) => renderBarChart(orders)},
            // {
            //     title: 'Выбор карточки',
            //     key: 'imt_select',
            //     width: 150,
            //     render: (_, record) => {
            //         const isImtExists = imtData.some(imt => imt.value === record.imt_id);
            //         return (
            //             <Select
            //                 style={{
            //                     width: '100%',
            //                     backgroundColor: !isImtExists ? '#fff3e8' : 'transparent'
            //                 }}
            //                 value={isImtExists ? record.imt_id : undefined}
            //                 onChange={(value) => {
            //                             record.plan_imt_id = value
            //                             handleSave(record)
            //                         }}
            //                 options={imtData}
            //                 placeholder="Выберите карточку"
            //                 allowClear
            //             />
            //         );
            //     }
            // },
            {
                title: 'План заказов на месяц', dataIndex: 'plan_orders', width: 200, render: (text, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Flex  justify='space-between'>
                            <Text><strong>Штук:</strong></Text>
                                <Input
                                    size="small"
                                    style={{width: 100}}
                                    value={record.plan_orders}
                                    onChange={(e) => {
                                        record.plan_orders = e.target.value
                                        handleSave(record)
                                    }}
                                    suffix="шт"
                                />

                        </Flex>
                        <Flex justify='space-between'>
                            <Text><strong>Рублей:</strong>  </Text>
                                <Input
                                    size="small"
                                    style={{width: 100}}
                                    value={record.plan_orders_sum}
                                    onChange={(e) => {
                                        record.plan_orders_sum = e.target.value
                                        handleSave(record)
                                    }}
                                    suffix="руб"
                                />

                        </Flex>
                        <Flex justify='space-between'>
                            <Text><strong>% выкупа:</strong>  </Text>
                                <Input
                                    size="small"
                                    style={{width: 100}}
                                    value={record.plan_buyout_percent}
                                    onChange={(e) => {
                                        record.plan_buyout_percent = e.target.value
                                        handleSave(record)
                                    }}
                                    suffix="%"
                                />
                        </Flex>
                        <Flex justify='space-between'>
                            <Text><strong>% маркетинг:</strong>  </Text>
                                <Input
                                    size="small"
                                    style={{width: 100}}
                                    value={record.plan_budget_percent}
                                    onChange={(e) => {
                                        record.plan_budget_percent = e.target.value
                                        handleSave(record)
                                    }}
                                    suffix="%"
                                />
                        </Flex>


                    </Space>
                )
            },
            {
                title: 'Остатки',
                width: 110,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Всего:</strong> {formatQuantity(record.full)}</Text>
                        <Text><strong>FBO:</strong> {formatQuantity(record.ost)}</Text>
                        <Text><strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}</Text>
                        <Text><strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}</Text>
                    </Space>
                )
            },

            // Складские остатки
            ...(is_our ? [{
                title: 'Другие остатки',
                width: 110,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>МС:</strong> {formatQuantity(record.ms_ost || 0)}</Text>
                        <Text><strong>Транзит:</strong> {formatQuantity(record.ms_transfer || 0)}</Text>
                        <Text><strong>Шьется:</strong> {formatQuantity(record.ms_production || 0)}</Text>
                    </Space>
                )
            }] : []),
            {
                title: 'Себестоимость',
                width: 180,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Цена:</strong> {formatCurrency(record.price_with_wb_discount)}</Text>
                        <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes * record.full)}</Text>
                        <Text><strong>Деньги в товаре:</strong> {formatCurrency(record.price_with_wb_discount * record.full)}</Text>
                    </Space>
                )
            },
            // Аналитика
            {
                title: 'Показатели',
                width: 170,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Заказов в день:</strong> {formatQuantity(record.orders_per_day)}</Text>
                        <Text><strong>Выкуп:</strong> {formatPercent(record.buyout_percent)}</Text>
                        <Text><strong>ABC:</strong> {record.abc}</Text>
                        <Text><strong>Маржинальность:</strong> {formatPercent2(record.marzha_unit)}</Text>
                        <Text><strong>Оборачиваемость:</strong> {formatDays2(record.oborot_orders)}</Text>
                        <Text><strong>Запас дней:</strong> {formatDays2(record.oborot_buyouts)}</Text>
                    </Space>
                )
            },

        ]
    },[is_our])


    return (
        <>
            <Table
                columns={columns}
                dataSource={cards}
                bordered
                size={'small'}
                className='adverts-table2'
                loading={loading}
                pagination={{defaultPageSize: 10, showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`}}
            />
        </>
    )
}