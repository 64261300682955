import {Card, Typography, Table} from 'antd';
import React, { useMemo } from 'react';
import {formatCurrency, formatPercent} from '../../../utils/formats';

const transformData = (account) => {
    const transformedData = {};

    account.by_period.forEach(entry => {
        transformedData[entry.date] = {
            additional_payment: formatCurrency(entry.additional_payment),
            budget: formatCurrency(entry.budget),
            budget_external: formatCurrency(entry.budget_external),
            commission: formatCurrency(entry.commission),
            delivery: formatCurrency(entry.delivery),
            for_pay: formatCurrency(entry.for_pay),
            itog: formatCurrency(entry.itog),
            nalog: formatCurrency(entry.nalog),
            buyouts_sum: formatCurrency(entry.buyouts_sum),
            other_external: formatCurrency(entry.other_external),
            other_services: formatCurrency(entry.other_services),
            penalty: formatCurrency(entry.penalty),
            percent_budget: formatPercent(entry.percent_budget),
            percent_budget_external: formatPercent(entry.percent_budget_external),
            percent_commission: formatPercent(entry.percent_commission),
            percent_delivery: formatPercent(entry.percent_delivery),
            percent_nalog: formatPercent(entry.percent_nalog),
            percent_supply: formatPercent(entry.percent_supply),
            percent_store: formatPercent(entry.percent_store),
            percent_sebes: formatPercent(entry.percent_sebes),
            percent_penalty: formatPercent(entry.percent_penalty),
            percent_other_services: formatPercent(entry.percent_other_services),
            percent_other_external: formatPercent(entry.percent_other_external),
            profit: formatCurrency(entry.profit),
            returns_sum: formatCurrency(entry.returns_sum),
            sebes: formatCurrency(entry.sebes),
            store: formatCurrency(entry.store),
            supply: formatCurrency(entry.supply),
        };
    });

    return transformedData;
};

const createColumns = (dates) => {
    return dates.map(date => ({
        title: date,
        key: date,
        children: [
            {
                title: 'Рубли',
                dataIndex: `${date}_normal`,
                key: `${date}_normal`,
                width: 120
            },
            {
                title: '%',
                dataIndex: `${date}_percent`,
                key: `${date}_percent`,
                width: 75
            }
        ]
    }));
};

const PnlTable = ({account}) => {
    const transformedData = transformData(account);
    const dates = Object.keys(transformedData);

    const columns = useMemo(() => {
        return [
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
            fixed: 'left',
            width: 200,
        },
        {
            title: 'Всего',
            key: 'total',
            className: 'blue-color',
            children: [
                {
                    title: 'Рубли',
                    dataIndex: 'itog_normal',
                    key: 'itog_normal',
                    className: 'blue-color',
                    width: 120
                },
                {
                    title: '%',
                    dataIndex: 'itog_percent',
                    key: 'itog_percent',
                    className: 'blue-color',
                    width: 75
                }
            ]
        },
        ...createColumns(dates),
    ];
    },[dates])

    const dataSource = useMemo(() => {
         return [
        {
            key: 'buyouts_sum',
            description: <div className=' font-bold'>Сумма выкупов</div>,
            itog_normal: formatCurrency(account.itog.buyouts_sum),
            itog_percent: account.itog.percent_buyouts_sum,
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].buyouts_sum])),
        },
        {
            key: 'returns_sum',
            description: 'Сумма возвратов',
            itog_normal: formatCurrency(account.itog.returns_sum),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].returns_sum])),
        },
        {
            key: 'for_pay',
            description: 'К перечислению за товар',
            itog_normal: formatCurrency(account.itog.for_pay),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].for_pay])),
        },
        {
            key: 'commission' || 'percent_commission',
            description: 'Комиссия',
            itog_normal: formatCurrency(account.itog.commission),
            itog_percent: formatPercent(account.itog.percent_commission),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].commission])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_commission])),
        },
        {
            key: 'delivery' || 'percent_delivery',
            description: 'Логистика',
            itog_normal:   formatCurrency(account.itog.delivery),
            itog_percent : formatPercent(account.itog.percent_delivery),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].delivery])) , 
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_delivery])),
        },
        {
            key: 'store' || 'percent_store',
            description: 'Хранение',
            itog_normal: formatCurrency(account.itog.store),
            itog_percent: formatPercent(account.itog.percent_store),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].store])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_store])),
        },
        {
            key: 'supply' || 'percent_supply',
            description: 'Приемка',
            itog_normal: formatCurrency(account.itog.supply),
            itog_percent: formatPercent(account.itog.percent_supply),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].supply])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_supply])),
        },
        {
            key: 'penalty' || 'percent_penalty',
            description: 'Штраф',
            itog_normal: formatCurrency(account.itog.penalty),
            itog_percent: formatPercent(account.itog.percent_penalty),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].penalty])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_penalty])),
        },
        {
            key: 'budget' || 'percent_budget',
            description: 'МРК',
            itog_normal: formatCurrency(account.itog.budget),
            itog_percent: formatPercent(account.itog.percent_budget),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].budget])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_budget])),
        },
        {
            key: 'other_external' || 'percent_other_external',
            description: 'Другие расходы',
            itog_normal: formatCurrency(account.itog.other_external),
            itog_percent: formatPercent(account.itog.percent_other_external),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].other_external])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_other_external])),
        },
        {
            key: 'additional_payment' || 'percent_additional_payment',
            description: 'Доп. выплаты',
            itog_normal: formatCurrency(account.itog.additional_payment),
            itog_percent: formatPercent(account.itog.percent_additional_payment),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].additional_payment])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_additional_payment])),
        },
        {
            key: 'itog',
            description: <div className=' font-bold'>Итого к выводу</div>,
            itog_normal: formatCurrency(account.itog.itog),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].itog])),
        },
        {
            key: 'sebes' || 'percent_sebes',
            description: 'Себес',
            itog_normal: formatCurrency(account.itog.sebes),
            itog_percent: formatPercent(account.itog.percent_sebes),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].sebes])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_sebes])),
        },
        {
            key: 'nalog' || 'percent_nalog',
            description: 'Налог',
            itog_normal: formatCurrency(account.itog.nalog),
            itog_percent: formatPercent(account.itog.percent_nalog),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].nalog])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_nalog])),
        },
        {
            key: 'budget_external' || 'percent_budget_external',
            description: 'Внешний MRK',
            itog_normal: formatCurrency(account.itog.budget_external),
            itog_percent: formatPercent(account.itog.percent_budget_external),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].budget_external])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_budget_external])),
        },
        {
            key: 'other_services' || 'percent_other_services',
            description: 'Другие расходы',
            itog_normal: formatCurrency(account.itog.other_services),
            itog_percent: formatPercent(account.itog.percent_other_services),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].other_services])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_other_services])),
        },
        {
            key: 'profit' || 'percent_profit',
            description: <div className=' font-bold'>Чистая прибыль</div>,
            itog_normal: formatCurrency(account.itog.profit),
            itog_percent: formatPercent(account.itog.percent_profit),
            ...Object.fromEntries(dates.map(date => [`${date}_normal`, transformedData[date].profit])),
            ...Object.fromEntries(dates.map(date => [`${date}_percent`, transformedData[date].percent_profit])),
        },
    ]},[dates]);

    return (
        <Card style={{ marginBottom: '20px' }}>
            <Typography.Title level={4}>{account.wbacc_name}</Typography.Title>

            <Table
                columns={columns}
                dataSource={dataSource}
                className='adverts-table2'
                pagination={false}
                rowKey='key'
                bordered
                size={'small'}
                scroll={{
                    x: 'max-content'
                }}
            />
        </Card>
    )
};

const PnlComponent = ({data}) => {
    return (
        <div>
            {data && data.length > 0 ? (
                data.map((account, index) => (
                    <PnlTable key={index} account={account}/>
                ))
            ) : (
                <Typography.Text>No data available</Typography.Text>
            )}
        </div>
    );
};

export default PnlComponent;
