import { Typography } from 'antd'
import ReactPlayer from 'react-player/youtube'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography

const SideBarAnal = () => {
    const sections = [
        {
            label: 'Навигация и управление отображением данных',
            description: [
                'Сверху страницы находится переключатель, позволяющий скрывать или показывать некоторые столбцы, чтобы при необходимости уменьшить объем информации и сконцентрироваться только на ключевых метриках.',
                'Имеются чекбоксы с иконками «сердце» и «глаз», которые позволяют пользователю отмечать понравившиеся товары или скрывать ненужные строки для более персонализированного анализа.'
            ]
        },
        {
            label: 'Общие данные о товарах',
            description: [
                'Включены такие ключевые поля, как артикул продавца и артикул товара, категория, модель, цвет, размер, баркод и рейтинг. Эти данные обеспечивают базовую информацию, которую можно фильтровать по категориям, цветам, артикулу и другим параметрам.'
            ]
        },
        {
            label: 'Показатели',
            description: [
                'Этот блок отображает такие показатели, как «Заказы в день», «Выкупы в день», процент выкупа и оборачиваемость. Данные о запасы отображаются в днях, что помогает оценить текущий спрос и запасы.'
            ]
        },
        {
            label: 'Цены и скидки',
            description: [
                'Блок цен состоит из столбцов для отображения цены, скидки, наценки и маржи, а также окончательной цены с учетом скидок. Эти столбцы помогают отслеживать изменения в цене и прибыльности, выделяя данные зеленым цветом для наглядности.'
            ]
        },
        {
            label: 'Расходы',
            description: ['В эту категорию входят данные о себестоимости, логистике, хранении, рекламе, комиссии и налогах. Эти параметры помогают пользователю оценить общий уровень затрат на товар.']
        },
        {
            label: 'Рекламные показатели',
            description: [
                'Включают такие данные, как бюджет, количество просмотров и кликов, заказы, а также коэффициенты CTR и CPC, что дает возможность анализировать эффективность рекламных кампаний.'
            ]
        },
        {
            label: 'Плановые показатели',
            description: ['Включает плановые значения по количеству продаж в штуках, позволяя отслеживать, насколько фактические продажи соответствуют запланированным.']
        },
        {
            label: 'Остатки и запасы',
            description: [
                'Последние группы столбцов отображают данные о запасах на разных складах (в Москве, Бишкеке и на производстве), а также остатках на FBO-складах. Отображаются данные об остатках на пути до клиента и от клиента, что позволяет оценить доступность товаров.'
            ]
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={2}>Описание страницы "Аналитика"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Аналитика"</span> предоставлен функционал для глубокого анализа товарных показателей с помощью детализированной таблицы, включающей обширные
                данные и инструменты для фильтрации и сортировки.
            </Text>

            <Title level={3}>Основные разделы страницы Аналитика:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>
                Таблица также поддерживает фильтрацию и сортировку по многим показателям, что делает ее удобным инструментом для комплексного анализа товарных данных на сайте.
            </Text>
        </div>
    )
}

export default SideBarAnal
