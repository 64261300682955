import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography

const SideBarOrdersYear = () => {
    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={2}>Описание страницы "Продажи за год"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница <span className='text-black'>"Продажи за год"</span> предназначена для отображения информации о годовых продажах товаров, что позволяет анализировать объемы продаж за
                длительный период и выявлять тенденции. Данные представлены в таблице, содержащей показатели, связанные с продажами и доходами за каждый месяц или квартал. Эта информация дает
                возможность анализировать сезонные колебания спроса, определять популярные товары, а также принимать стратегические решения для улучшения продаж.
            </Text>
        </div>
    )
}

export default SideBarOrdersYear
