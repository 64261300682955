import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Warning from '../utils/svgs/warning.svg';
import { IoIosArrowRoundBack } from "react-icons/io";

class ErrorBoundary44 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("Ошибка поймана в ErrorBoundary44:", error, info);
  }

  resetError = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        // {this.state.error.message}
        // <div>
        //   <h1>Неизвестная ошибка</h1>
        //   <button onClick={this.resetError}>Попробовать снова</button>
        // </div>
        <div className="flex justify-between h-[95.5vh] gap-20 items-center mx-20">
            <div className="flex flex-col gap-4">
                <p className="text-[#484848] font-bold text-[60px]">Неизвестная ошибка</p>
                <span className="font- text-2xl  text-[#484848]">Произошла неизвестная ошибка, мы уже в курсе и работаем над её исправлением.</span>
                <span className="font- text-2xl  text-[#484848]">Обновите страницу через несколько минут. Если проблема не решится, обратитесь в тех поддержку.</span>
                <Link to={'/'}  className="relative hover:text-black active:scale-95 duration-700 items-center xl:text-xl transition-all flex w-fit text-black after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-black after:transition-all after:duration-200 hover:after:w-full " ><IoIosArrowRoundBack className='text-4xl' /> На главную</Link>
            </div>
            <img src={Warning} className="w-[60%]" alt="Warning" />
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary44;
