import {Table} from 'antd'
import {formatCurrency,} from '../../../utils/formats';
import React from 'react'

const getColumns = () => {
    return [
        {title: 'Группа', dataIndex: 'group_type', width: 120, fixed: 'left', ellipsis: true},
        {title: 'Дата', dataIndex: 'date', width: 100, fixed: 'left'},
        {title: 'Выручка', dataIndex: 'buyouts_sum', width: 120, render: (text, record) => formatCurrency(record.buyouts_sum - record.returns_sum)},
        {title: 'Комиссия', dataIndex: 'commission', width: 100, render: (text) => formatCurrency(text)},
        {title: 'К перечислению', dataIndex: 'for_pay', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Логистика', dataIndex: 'delivery', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Хранение', dataIndex: 'store', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Приемка', dataIndex: 'supply', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Штрафы', dataIndex: 'penalty', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Маркетинг', dataIndex: 'budget', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Итого к выводу', dataIndex: 'itog', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Себестоимость', dataIndex: 'sebes', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Налог', dataIndex: 'nalog', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Чистая прибыль', dataIndex: 'profit', width: 120, render: (text) => formatCurrency(text)}
    ]
}

const expandData = (cards) => {
    cards.forEach((card) => {
        card['children'] = []
        card.key = card.group_type
        card.date = 'Итого'
        card['children'] = card.by_period
        for (let key in card.summed) {
            card[key] = card.summed[key]
        }
    })
    return cards
}

export default function FinDetailTable({data, loading}) {
    const columns = getColumns()

    let DataSource = data
    if (data) {
        DataSource = expandData(data)
    }
    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                className='adverts-table2'
                scroll={{
                    y: 800
                }}
                loading={loading}
                pagination={{'defaultPageSize': 20, 'showTotal': (total, range) => `${range[0]}-${range[1]} из ${total} кампаний`}}
            />
        </>
    )
}
