import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography

const SideBarAdverts = () => {
    const sections = [
        {
            label: 'Фильтры',
            description: ['В верхней части страницы пользователю доступны фильтры для выбора периода данных и поставщиков.', 'Также есть кнопка "Экспорт в Excel" для выгрузки данных в таблицу Excel.']
        },
        {
            label: 'Таблица рекламных компаний',
            description: [
                'Таблица содержит детализированную информацию о рекламных кампаниях, включая следующие колонки:',
                'ID – уникальный идентификатор кампании.',
                'Название, Тип – название кампании и её тип.',
                'Артикулы WB – идентификаторы товаров в системе WB.',
                'Статус – текущий статус кампании.',
                'Расход – сумма, потраченная на маркетинг.',
                'Показы и Клики – количество показов рекламы и кликов по ней.',
                'Корзина и Заказы – количество добавлений в корзину и оформленных заказов.',
                'Сумма заказов – общая сумма всех заказов.',
                'CTR – показатель кликабельности.',
                'CPC – стоимость за клик.',
                'ДРР – показатель дохода на затраты.',
                'ROMI – возврат на инвестиции в маркетинг.'
            ]
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={2}>Описание страницы "Рекламные компании"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Рекламные компании"</span> представлена таблица с детализированной информацией о рекламных показателях для различных кампаний.
            </Text>

            <Title level={3}>Основные элементы страницы:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-black'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>
                Эта страница позволяет пользователям глубоко анализировать эффективность рекламных компаний и их влияние на продажи, что упрощает контроль за результатами маркетинговых действий.
            </Text>
        </div>
    )
}

export default SideBarAdverts
