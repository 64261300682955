import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography

const SideBarEditCards = () => {
    const sections = [
        {
            label: 'Навигация и управление отображением данных',
            description: [
                'Сверху страницы находятся переключатели и чекбоксы, позволяющие скрывать или показывать определенные столбцы, а также отмечать понравившиеся товары для более персонализированного анализа.',
                'Иконки «сердце» и «глаз» используются для управления видимостью карточек.'
            ]
        },
        {
            label: 'Общие данные о карточках',
            description: ['Включены ключевые поля, такие как артикул продавца, артикул WB, карточка товара, категория, модель, цвет и статус. Эти данные обеспечивают базовую информацию о товаре.']
        },
        {
            label: 'Редактирование данных',
            description: [
                'Пользователи могут редактировать значения в полях, таких как себестоимость, плановые заказы и другие параметры. Поля имеют валидацию для обеспечения корректности вводимых данных.'
            ]
        },
        {
            label: 'Плановые показатели',
            description: ['Отображаются плановые значения по количеству продаж в штуках и сумме продаж. Эти данные помогают отслеживать соответствие фактических продаж запланированным.']
        },
        {
            label: 'Фразы для отслеживания позиций',
            description: ['Пользователи могут вводить фразы для отслеживания позиций товаров. Эти фразы помогают анализировать видимость товаров в поисковых системах.']
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title  className='mt-3'level={2}>Описание страницы "Все товары"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Все товары"</span> предоставляется функционал для редактирования и управления данными о товарах с помощью таблицы, включающей
                обширные данные и инструменты для фильтрации и сортировки.
            </Text>

            <Title level={3}>Основные разделы страницы Все товары:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>Страница поддерживает редактирование данных в реальном времени и позволяет пользователям управлять карточками товаров эффективно.</Text>
        </div>
    )
}

export default SideBarEditCards
