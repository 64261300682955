import React, {useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Dropdown, Input, Menu, Row, Select, Space, Tag, Tooltip, Typography} from 'antd'
import TableEditCards from './editCards'
import {useSidebar} from '../../../Context/SideBarContext'
import SideBarEditCards from '../../home/sideBar/SideBarEditCards'
import Sidebar from '../../home/sideBar/SideBar'
import {apiAxios} from "../../../utils/api_axios";
import {applyAllFilters, handleQuickFilter, handleRemoveFilter, useFilterLogic} from "../../components/filterUtils";
import {FilterOutlined, ReloadOutlined} from "@ant-design/icons";

export default function ProductsSebes({selectorFilter}) {
    const [cards, setCards] = useState([])
    const [filters_data, setFiltersData] = useState({});
    const [loading, setLoading] = useState(true)
    const {openSidebar} = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarEditCards/>)
    }, [openSidebar])

    const onEditCard = (card) => {
        apiAxios.post('edit-cards/update/', {'card': card})
    }
    const savePositions = (nm_id, positions) => {
        apiAxios.post("edit-cards/update/positions/", {'nm_id': nm_id, 'positions': positions});
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('edit-cards/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
            });
            setCards(response.cards);
            setFiltersData(response.filters || {});
        } catch (error) {
            console.error('Error fetching supply data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [selectorFilter.wbacc_id]);


    const [activeFilters, setActiveFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [quickFilters, setQuickFilters] = useState({
        showNoSebes: false,
        showNoModel: false,
        showNoMaterial: false,
        showNoColor: false,
        showNoSezon: false,
        showNoStatus: false,
    });

    const quickFilterButtons = [
        {key: 'showNoSebes', label: 'Не указана себестоимость', filter: item => item.sebes === 0},
        {key: 'showNoModel', label: 'Не указана модель', filter: item => ['', '-', ' '].includes(item.model)},
        {key: 'showNoMaterial', label: 'Не указан материал', filter: item => ['', '-', ' '].includes(item.material)},
        {key: 'showNoColor', label: 'Не указан цвет', filter: item => ['', '-', ' '].includes(item.color)},
        {key: 'showNoSezon', label: 'Не указан сезон', filter: item => ['', '-', ' '].includes(item.sezon)},
        {key: 'showNoStatus', label: 'Не указан статус', filter: item => ['', '-', ' '].includes(item.status)},

    ];

    const filterMenu = <Menu items={useFilterLogic(
        filters_data,
        setActiveFilters
    )}/>;
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {setDebouncedSearchText(searchText);}, 1000);
        return () => {clearTimeout(handler);};
    }, [searchText]);
    const filteredData = applyAllFilters(cards, quickFilters, quickFilterButtons, activeFilters, debouncedSearchText)
    return (
        <>

            <Sidebar/>
            <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                <Space wrap>
                    <Input placeholder="Поиск по артикулу" value={searchText} onChange={(e) => {setSearchText(e.target.value);}}/>
                    <Dropdown overlay={filterMenu} trigger={['click']}><Button icon={<FilterOutlined/>}>Добавить фильтр</Button></Dropdown>
                    <Button icon={<ReloadOutlined/>} onClick={fetchData}>Обновить</Button>

                    {activeFilters.map((filter, index) => (
                        <Tag key={index} closable onClose={() => handleRemoveFilter(index, setActiveFilters)}>{`${filter.type}: ${filter.value.label}`}</Tag>
                    ))}
                </Space>
                <Space wrap>
                    {quickFilterButtons.map(button => (
                        <Button
                            key={button.key}
                            type={quickFilters[button.key] ? 'primary' : 'default'}
                            onClick={() => handleQuickFilter(button.key, quickFilters, setQuickFilters)}
                        >
                            {button.label}
                        </Button>
                    ))}
                </Space>
            </Space>
            <TableEditCards
                cards={filteredData}
                filters={filters_data}
                loading={loading}
                setCards={setCards}
                onEditCard={onEditCard}
                savePositions={savePositions}
            />

        </>
    )
}
