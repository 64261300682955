import { Card, Flex, Tabs, Tooltip, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { TabWBAccs } from './profileTabWBAccs'
import { TabStaff } from './profileTabStaff'
import { TabSub } from './profileTabSub'
import { TabProfile } from './profileTabProfile'
import { ProfileTabPartner } from './profileTabPartner'
import { useLocation } from 'react-router-dom'
import { useSidebar } from '../../Context/SideBarContext'
import { CiCircleQuestion } from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import SideBarProfile from '../home/sideBar/SideBarProfile'
import { apiAxios } from "../../utils/api_axios";



export const Profile = () => {
    const { user, setUser, setIsAuth } = useAuth()
    const TAB_NAMES = ['Информация', 'Магазины', ...(user.is_staff ? [] : ['Сотрудники', 'Подписка'])] // , 'Партнерская программа'
    const [profileUser, setProfileUser] = useState(user)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const initialTab = queryParams.get('tab') === 'stores' ? 'Магазины' : TAB_NAMES[0]
    const [activeTab, setActiveTab] = useState(initialTab)
    const [loading, setLoading] = useState(true)
    const [activeButton, setActiveButton] = useState(null)
    const [tabProfileState, setTabProfile] = useState({ like: [], hide: [] })
    const [tabWBAccState, setTabWBAcc] = useState({ selected_wbacc: {} })
    const [tabStaffState, setTabStaff] = useState({ email: '', wbaccs: [], allowed_wbaccs: [], wbacc_ids: [] })

    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarProfile />)
    }, [openSidebar])

    const setTabProfileState = (name, value) => {
        setTabProfile((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const setTabWBAccState = (name, value) => {
        setTabWBAcc((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const setTabStaffState = (name, value) => {
        setTabStaff((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await apiAxios.get('user/user-info/', {})
            setProfileUser(fetchedData)
            if (fetchedData.params) {
                if (fetchedData.params.hide_articles) {
                    setTabProfile((prev) => ({ ...prev, hide: fetchedData.params.hide_articles }))
                }
                if (fetchedData.params.like_articles) {
                    setTabProfile((prev) => ({ ...prev, like: fetchedData.params.like_articles }))
                }
            }

            console.log('fetchedData', fetchedData)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    
    const [isModalOpen, setIsModal] = useState({
        account_remove: false,
        staff: false,
        staff_edit: false,
        staff_remove: false,
        staff_manager:false,
        wbacc: false,
        wbacc_update: false,
        wbacc_remove: false,
        ozonacc: false,
        ozonacc_update: false,
        nalog: false
    })
    const setIsModalOpen = (name, value) => {
        setIsModal((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    useEffect(() => {
        fetchData()
    }, [])
    const renderTabContent = () => {
        switch (activeTab) {
            case 'Информация':
                return TabProfile(profileUser, setIsAuth, tabProfileState, setTabProfileState, setActiveTab, isModalOpen, setIsModalOpen)
            case 'Магазины':
                return TabWBAccs(profileUser, isModalOpen, setIsModalOpen, tabWBAccState, setTabWBAccState)
            case 'Сотрудники':
                return TabStaff(profileUser, isModalOpen, setIsModalOpen, tabStaffState, setTabStaffState)
            case 'Подписка':
                return <TabSub activeButton={activeButton} setActiveButton={setActiveButton} />
            case 'Партнерская программа':
                return <ProfileTabPartner />
            default:
                return null
        }
    }

    return (
        <Card loading={loading}>
            <Flex className='gap-2'>
                <Typography.Title level={1}>Профиль</Typography.Title>
                <Tooltip className='mt-3' title='Информация по таблице'>
                    <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                </Tooltip>
            </Flex>
            <Tabs defaultActiveKey={TAB_NAMES[0]} activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
                {TAB_NAMES.map((name) => (
                    <Tabs.TabPane tab={name} key={name}>
                        {renderTabContent()}
                    </Tabs.TabPane>
                ))}
            </Tabs>
            <Sidebar />
        </Card>
    )
}
