import React, {useState, useEffect} from 'react';
import {Table, Col, Typography, Button, Space, Input, Dropdown, Menu, Tag, Select} from 'antd';
import {formatNumber, formatPercent, formatQuantity} from '../../../utils/formats';
import {SearchOutlined, FilterOutlined, ReloadOutlined} from '@ant-design/icons';
import './AbcTable.css';
import {applyAllFilters, handleQuickFilter, handleRemoveFilter, useFilteredCards, useFilterLogic} from '../../components/filterUtils';

const {Text} = Typography;


export default function AbcTable({data, loading, filters_data, selectorFilter, setSelectorFilter}) {
    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showNoSales: false,
        showLongTurnover: false,
        showLowBuyout: false
    });

    const quickFilterButtons = [
        {
            key: 'showNegativeMargin',
            label: 'Отрицательная маржа',
            filter: item => item.margin_percent < 0
        },
        {
            key: 'showNoSales',
            label: 'Нет заказов',
            filter: item => item.orders_per_day < 0.2 && item.ost_quantity > 10
        },
        {
            key: 'showLongTurnover',
            label: 'Низкие отмены',
            filter: item => item.cancels < 15 // больше 60 дней
        },
        {
            key: 'showLowBuyout',
            label: 'Низкий выкуп',
            filter: item => item.buyout_percent < 30 && item.buyout_percent > 0 // меньше 50%
        }
    ];

    const [activeFilters, setActiveFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 1000);
        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const filterMenu = <Menu items={useFilterLogic(
        filters_data,
        setActiveFilters
    )}/>;
    const filteredData = applyAllFilters(data, quickFilters, quickFilterButtons, activeFilters, debouncedSearchText)


    const getColumns = () => {
        const groupColumns = [{title: selectorFilter.group, dataIndex: 'group_key', width: 100,}]
        const nmColumns = [
            {
                title: 'Товар',
                dataIndex: 'article',
                width: 300,
                render: (_, record) => (
                    <Space direction="vertical" size={0}>
                        <Space align="start" size={12}>
                            <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain',}} className='mt-3' alt="Product"/>
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                                <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                                <Text><strong>Название:</strong> {record.name}</Text>
                                <Text><strong>Карточка:</strong> {record.imt_id}</Text>
                            </Space>
                        </Space>
                    </Space>
                )
            },
            {
                title: 'Информация о товаре',
                dataIndex: 'info',
                key: 'info',
                width: 300,
                render: (_, record) => (
                    <Space size={24} className="cost-info-cell">
                        <div className='w-[200px]'>
                            <Text><strong>Модель:</strong> {record.model}</Text>
                            <Text><strong>Материал:</strong> {record.material}</Text>
                            <Text><strong>Бренд:</strong> {record.brand}</Text>
                            <Text><strong>Категория:</strong> {record.category}</Text>
                        </div>
                        <div>
                            <Text><strong>Статус:</strong> {record.status}</Text>
                            <Text><strong>Сезон:</strong> {record.sezon}</Text>
                            <Text><strong>Цвет:</strong> {record.color}</Text>
                        </div>
                    </Space>
                )
            },
        ]
        const mainColumns = [
            {
                title: 'Продажи',
                dataIndex: 'sells',
                key: 'sells',
                width: 300,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Заказы:</strong> {formatQuantity(record.orders)}</Text>
                        <Text><strong>Выкупы:</strong> {formatQuantity(record.buyouts)}</Text>
                        <Text><strong>Отмены:</strong> {formatQuantity(record.cancels || 0)}</Text>
                        <Text><strong>Возвраты:</strong> {formatQuantity(record.returns || 0)}</Text>
                    </Space>

                ),
            },
            {
                title: 'ABC',
                dataIndex: 'finances',
                key: 'finances',
                width: 300,
                render: (_, record) => {
                    const green = ['AA', 'AB']
                    const red = ['AC', 'BC', 'CC']
                    const abcStyle = green.includes(record.abc) ? {color: '#52c41a'} : red.includes(record.abc) ? {color: '#ff4d4f'} : {color: '#faad14'}
                    const abc1Style = record.abc_buyouts_sum === 'A' ? {color: '#52c41a'} : record.abc_buyouts_sum === 'C' ? {color: '#ff4d4f'} : {color: '#faad14'}
                    const abc2Style = record.abc_profit === 'A' ? {color: '#52c41a'} : record.abc_profit === 'C' ? {color: '#ff4d4f'} : {color: '#faad14'}
                    return (
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>ABC:</strong><span style={abcStyle}>{record.abc}</span></Text>
                            <Text><strong>Процент выручки:</strong><span style={abc1Style}>{formatPercent(record.abc_percent_buyouts_sum)}</span></Text>
                            <Text><strong>Процент маржи:</strong><span style={abc2Style}>{formatPercent(record.abc_percent_profit)}</span></Text>
                        </Space>
                    )
                },
            },

            {
                title: 'Показатели',
                dataIndex: 'efficiency',
                key: 'efficiency',
                width: 300,
                render: (_, record) => {
                    const marzhaStyle = record.marzha > 0 ? {color: '#52c41a'} : record.marzha === 0 ? {} : {color: '#ff4d4f'}
                    const renStyle = record.ren > 0 ? {color: '#52c41a'} : record.ren === 0 ? {} : {color: '#ff4d4f'}
                    const drrStyle = record.drr === 0 ? {} : record.drr < 5 ? {color: '#52c41a'} : {color: '#ff4d4f'}

                    return (
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>Маржинальность:</strong> <span style={marzhaStyle}>{formatPercent(record.marzha)}</span></Text>
                            <Text><strong>Рентабельность:</strong> <span style={renStyle}>{formatPercent(record.ren)}</span></Text>
                            <Text><strong>DRR:</strong> <span style={drrStyle}>{formatPercent(record.drr)}</span></Text>
                            <Text><strong>ROI:</strong> {formatNumber(record.roi)}</Text>
                        </Space>
                    )
                }
            },
        ];
        let columns = []
        if (selectorFilter.group === 'nm_id') {
            columns = nmColumns
        } else {
            columns = groupColumns
        }
        columns = [
            ...columns,
            ...mainColumns,
        ]
        return columns

    }
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        // First expand the data
        const columns2 = getColumns()
        setColumns(columns2)
    }, [selectorFilter.group]);

    return (
        <>
            <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16}}>

                <Space wrap>
                    <Select style={{width: '200px'}} value={selectorFilter.group} onChange={(value) => {
                        setSelectorFilter('group', value)
                    }} options={selectorFilter.group_options}/>
                    <Input placeholder="Поиск по артикулу" value={searchText} onChange={(e) => {
                        setSearchText(e.target.value);
                    }}/>
                    <Dropdown overlay={filterMenu} trigger={['click']}><Button icon={<FilterOutlined/>}>Добавить фильтр</Button></Dropdown>
                    {activeFilters.map((filter, index) => (
                        <Tag key={index} closable onClose={() => handleRemoveFilter(index, setActiveFilters)}>{`${filter.type}: ${filter.value.label}`}</Tag>
                    ))}
                </Space>
                <Space wrap>
                    {quickFilterButtons.map(button => (
                        <Button key={button.key} type={quickFilters[button.key] ? 'primary' : 'default'} onClick={() => handleQuickFilter(button.key, quickFilters, setQuickFilters)}>{button.label}</Button>
                    ))}
                </Space>

            </Space>
            <Table
                dataSource={filteredData}
                columns={columns}
                loading={loading}
                scroll={{x: 'max-content'}}
                bordered
                size="small"
                pagination={{
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} товаров`
                }}
                className="abc-analysis-table adverts-table2"
            />
        </>
    );
}
