import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography
const SideBarPnl = () => {
    const metrics = [
        {
            label: 'График по процентам (верхний)',
            description:
                'показывает процентное соотношение различных затрат и доходов, таких как комиссия, логистика, хранение и другие, в общей структуре. Это позволяет визуально оценить вклад каждой статьи расходов и доходов в общий финансовый результат и увидеть, какие компоненты занимают наибольший процент от общего объема.'
        },
        {
            label: 'График по деньгам (нижний)',
            description:
                'отображает абсолютные значения различных показателей (в рублях) за каждый день. Это дает возможность отслеживать, как изменяются суммы расходов и доходов по дням, анализировать влияние тех или иных факторов на финансовый результат и выявлять тренды, такие как рост или спад расходов на логистику или комиссию.'
        }
    ]

    const metrics1 = [
        {
            label: 'Логистика',
            description: 'включает все затраты на транспортировку товаров от поставщика до склада и от склада к покупателю.'
        },
        {
            label: 'Приемка ',
            description: 'это расходы, связанные с проверкой и обработкой товаров при их поступлении на склад.'
        },
        {
            label: 'МРК',
            description: 'комплексный подход, который включает в себя сбор и анализ данных о рынке и потребителях, а также разработку стратегий для эффективного взаимодействия с целевой аудиторией.'
        },
        {
            label: 'Внешний MRK',
            description: 'маркетинговые расходы, которые компания несет для продвижения товара за пределами собственной площадки.'
        },
        {
            label: 'Другие расходы ',
            description: 'включает все дополнительные затраты, которые не подходят под основные статьи расходов, но могут быть значимы для анализа финансовой картины.'
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={3}>Описание страницы "Прибыль и убытки"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница <span className='text-black'>"Прибыль и убытки"</span> предоставляет подробный отчет о финансовых показателях, связанных с продажами товаров, возвратами, комиссией,
                логистикой, хранением и другими расходами. Таблица на странице содержит данные о сумме выкупов, комиссиях, затратах на логистику, налогообложении и чистой прибыли, что позволяет
                пользователю анализировать рентабельность и оценивать финансовую эффективность. Сравнение показателей по дням позволяет увидеть динамику изменений в структуре доходов и расходов,
                выявить проблемные области и принять меры для повышения прибыльности.
            </Text>
            <Title level={3}>Описание графиков:</Title>
            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {metrics.map((metric, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold text-black'>{metric.label}</span> — {metric.description}
                    </li>
                ))}
            </div>
            <Title level={3}>Расшифровка ключевых показателей:</Title>
            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {metrics1.map((metric, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold text-black'>{metric.label}</span> — {metric.description}
                    </li>
                ))}
            </div>
        </div>
    )
}

export default SideBarPnl
