import React, {useState, useRef} from 'react'
import {Table, Typography, Space, Button, Dropdown, Tag, Menu} from 'antd'
import {FilterOutlined, CloseOutlined, ReloadOutlined, DownloadOutlined, UploadOutlined} from '@ant-design/icons'
import {formatCurrency, formatPercent} from '../../../utils/formats';
import './PriceTable.css'
import {useFilterLogic, useFilteredCards} from '../../components/filterUtils'
import * as XLSX from "xlsx";

const {Text} = Typography


const PriceSebesAnalysis = ({cards, loading,}) => {
    const isMobile = window.innerWidth <= 769


    const downloadExcel = (data) => {
        // Подготавливаем данные для Excel

        const excelData = data.map(card => ({
            'Артикул': card.article,
            'Артикул WB': card.nm_id,
            'Название': card.name,
            'Карточка': card.imt_id,
            'Бренд': card.brand,
            'Категория': card.category,
            'Модель': card.model,
            'Материал': card.material,
            'Статус': card.status,
            'Сезон': card.material,
            'Цвет': card.color,

            'Остатки Всего': card.full,
            'Остатки FBO': card.ost,
            'В пути от': card.way_from || 0,
            'В пути до': card.way_to || 0,

            'Цена': card.price,
            'Скидка': card.discount,
            'Цена со скидкой': card.price_with_discount,
            'Спп': card.spp,
            'Цена с спп': card.price_client,
            'Кошелек': card.wb_discount,
            'Цена с кошельком': card.price_with_wb_discount,

            'Логистика': card.sebes_delivery,
            'Хранение': card.sebes_store_cost,
            'Приемка': card.sebes_supply,
            'Маркетинг': card.sebes_marketing,
            'Налог': card.sebes_nalog,
            'Комиссия': card.price_with_discount * card.sebes_commission_wb / 100,
            'Себестоимость': card.sebes,
            'ФФ': card.sebes_ff,
            'Другое': card.sebes_other_services,
            'Себес итог': card.sebes_itog,
            'Себес с комиссией': card.sebes_itog_with_commission,

            'Прибыль': card.profit,
            'Наценка': card.nacenka,
            'Маржа': card.marzha,

        }));

        // Создаем книгу Excel
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Настраиваем ширину столбцов
        const maxWidth = 20;
        const colWidths = {};
        Object.keys(excelData[0] || {}).forEach(key => {
            colWidths[key] = Math.min(
                maxWidth,
                Math.max(
                    key.length,
                    ...excelData.map(row => String(row[key] || '').length)
                )
            );
        });
        ws['!cols'] = Object.values(colWidths).map(width => ({wch: width}));

        // Добавляем лист в книгу
        XLSX.utils.book_append_sheet(wb, ws, "Статистика");

        // Скачиваем файл
        XLSX.writeFile(wb, `Статистика_${new Date().toLocaleDateString()}.xlsx`);
    };
    return (
        <div className="price-table-container">
            <div className="table-header">
                <Space size={8} wrap>
                <Button type="primary" icon={<DownloadOutlined/>} style={{backgroundColor: '#4CAF50', borderColor: '#4CAF50'}} onClick={() => downloadExcel(cards)}>Экспорт в Excel</Button>
            </Space>
            </div>
            <Table
                dataSource={cards}
                columns={[
                    // Товар
                    {
                        title: 'Товар',
                        key: 'product',
                        width: 300,
                        render: (_, record) => (
                            <Space direction="vertical" size={0}>
                                <Space align="start" size={12}>
                                    <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                                    <Space direction="vertical" size={0} className="cost-info-cell">
                                        <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                                        <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                                        <Text><strong>Название:</strong> {record.name}</Text>
                                        <Text><strong>Карточка:</strong> {record.imt_id}</Text>

                                    </Space>
                                </Space>
                            </Space>
                        )
                    },
                    // Информация о товаре
                    {
                        title: 'Информация о товаре',
                        fixed: isMobile ? '' : 'left',
                        width: 250,
                        render: (_, record) => (
                            <Space size={24} className="cost-info-cell" style={{width: '100%',}}>
                                <Space direction="vertical" size={0}>
                                    <Text><strong>Модель:</strong> {record.material}</Text>
                                    <Text><strong>Бренд:</strong> {record.brand}</Text>
                                    <Text><strong>Категория:</strong> {record.category}</Text>
                                </Space>
                                <Space direction="vertical" size={0}>
                                    <Text><strong>Статус:</strong> {record.status}</Text>
                                    <Text><strong>Сезон:</strong> {record.sezon}</Text>
                                    <Text><strong>Цвет:</strong> {record.color}</Text>
                                </Space>
                            </Space>
                        )
                    },
                    // Текущая цена
                    {
                        title: 'Текущая цена',
                        key: 'current_price',
                        width: 200,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Цена:</strong> {formatCurrency(record.price)}</Text>
                                <Text style={{whiteSpace: 'nowrap'}}><strong>Со скидкой:</strong> {formatCurrency(record.price_with_discount)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>(скидка {formatPercent(record.discount)})</span></Text>
                                <Text style={{whiteSpace: 'nowrap'}}><strong>С СПП:</strong> {formatCurrency(record.price_client)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>(скидка {formatPercent(record.spp)})</span></Text>
                                <Text style={{whiteSpace: 'nowrap'}}><strong>С ВБ кошельком:</strong> {formatCurrency(record.price_with_wb_discount)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>(скидка {formatPercent(record.wb_discount)})</span></Text>
                            </Space>
                        )
                    },

                    // Логистика
                    {
                        title: 'Логистика',
                        width: 180,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Логистика:</strong> {formatCurrency(record.sebes_delivery)}</Text>
                                <Text><strong>Хранение:</strong> {formatCurrency(record.sebes_store_cost)}</Text>
                                <Text><strong>Приемка:</strong> {formatCurrency(record.sebes_supply)}</Text>
                            </Space>
                        )
                    },
                    // Логистика
                    {
                        title: 'Расходы',
                        width: 180,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Маркетинг:</strong> {formatCurrency(record.sebes_marketing)}</Text>
                                <Text><strong>Налог:</strong> {formatCurrency(record.sebes_nalog)}</Text>
                                <Text><strong>Комиссия:</strong> {formatCurrency(record.price_with_discount * record.sebes_commission_wb / 100)}</Text>
                            </Space>
                        )
                    },
                    // Логистика
                    {
                        title: 'Другие расходы',
                        width: 180,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes)}</Text>
                                <Text><strong>ФФ:</strong> {formatCurrency(record.sebes_ff)}</Text>
                                <Text><strong>Другое:</strong> {formatCurrency(record.sebes_other_services)}</Text>
                            </Space>
                        )
                    },
                    // Себестоимость и прибыль
                    {
                        title: 'Итого',
                        width: 180,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes_itog)}</Text>
                                <Text><strong>Себес с комиссией:</strong> {formatCurrency(record.sebes_itog_with_commission)}</Text>
                                <Text type={record.profit > 0 ? 'success' : 'danger'}><strong>Прибыль:</strong> {formatCurrency(record.profit)}</Text>
                                <Text type={record.nacenka > 0 ? 'success' : 'danger'}><strong>Наценка:</strong> {formatPercent(record.nacenka)}</Text>
                                <Text type={record.marzha > 0 ? 'success' : 'danger'}><strong>Маржа:</strong> {formatPercent(record.marzha)}</Text>
                            </Space>
                        )
                    },

                ]}
                loading={loading}
                pagination={{
                    defaultPageSize: 30,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`
                }}
                size={isMobile ? 'small' : 'middle'}
            />
        </div>
    )
}


export default PriceSebesAnalysis
