import {FallOutlined, RiseOutlined} from '@ant-design/icons'
import {Button, Space, Table, Typography} from 'antd'
import {formatCurrency, formatDays, formatPercent, formatPercent2, formatQuantity} from '../../../utils/formats';
import React, { useState } from 'react'

const {Text} = Typography

const calculatePercentChange = (current, previous) => {
    if (!previous || previous === 0) return null;
    return ((current - previous) / previous) * 100;
}

const ValueWithChange = ({value, previousValue, format, invertColors = false}) => {
    const percentChange = value - previousValue;
    const formattedValue = format ? format(value) : value;

    return (
        <span style={{display: 'inline-flex', alignItems: 'center', gap: '4px', whiteSpace: 'nowrap'}}>
            {formattedValue}
            {percentChange !== null && (
                <span style={{
                    color: invertColors
                        ? (percentChange >= 0 ? '#ff4d4f' : '#52c41a')
                        : (percentChange >= 0 ? '#52c41a' : '#ff4d4f'),
                    fontSize: '12px',
                    marginLeft: '4px'
                }}>
                    {percentChange >= 0 ? <RiseOutlined/> : <FallOutlined/>}{Math.abs(percentChange || 0).toFixed(1)}
                </span>
            )}
        </span>
    );
};

const findPreviousRecord = (record, allData) => {
    // Если это дочерний элемент
    if (!record.wbacc_name && record.date) {
        const parentKey = record.parent_key;
        const parent = allData.find(item => item.key === parentKey);
        if (!parent?.children) return null;

        const currentIndex = parent.children.findIndex(item => item.date === record.date);

        if (currentIndex < parent.children.length - 1) {
            const prevRecord = parent.children[currentIndex + 1];
            return prevRecord;
        }
    }
    // Если это родительский элемент
    else if (record.wbacc_name) {
        if (record.children?.length > 0) {
            return record.children[0];
        }
    }
    return null;
};

const getColumns = (allData,visible) => {
    return [
        {title: 'Поставщик', dataIndex: 'wbacc_name', width: 120, fixed: 'left', ellipsis: true},
        {title: 'Дата', dataIndex: 'date', width: 100, fixed: 'left'},
        {
            title: 'Остатки',
            width: 200,
            render: (_, record) => {
                return (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Всего:</strong> {formatQuantity(record.full)}</Text>
                    {visible && <>
                    <Text><strong>FBO:</strong> {formatQuantity(record.ost)}</Text>
                        <Text><strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}</Text></>}    
                        <Text><strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}</Text>
                    </Space>
                );
            }
        },
        {
            title: 'Оборачиваемость',
            width: 200,
            render: (_, record) => {
                const previousRecord = findPreviousRecord(record, allData);
                return (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Оборачиваемость:</strong> <ValueWithChange
                            value={record.oborot_orders}
                            previousValue={previousRecord?.oborot_orders}
                            format={formatDays}
                            invertColors={true}
                        /></Text>
                        <Text><strong>Запас дней:</strong> <ValueWithChange
                            value={record.oborot_buyouts}
                            previousValue={previousRecord?.oborot_buyouts}
                            format={formatDays}
                            nvertColors={true}
                        /></Text>
                    </Space>
                );
            }
        },
        {
            title: 'Заказы',
            width: 200,
            render: (_, record) => {
                const previousRecord = findPreviousRecord(record, allData);
                return (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Заказов:</strong> <ValueWithChange
                            value={record.orders}
                            previousValue={previousRecord?.orders}
                            format={formatQuantity}
                        /></Text>
                        <Text><strong>Сумма заказов:</strong> <ValueWithChange
                            value={record.orders_sum}
                            previousValue={previousRecord?.orders_sum}
                            format={formatCurrency}
                        /></Text>
                    {visible && <>    <Text><strong>Отмены:</strong> {formatQuantity(record.cancels)}</Text>
                        <Text><strong>Возвраты:</strong> {formatQuantity(record.returns)}</Text></>}

                    </Space>
                );
            }
        },
        {
            title: 'Показатели',
            width: 200,
            render: (_, record) => {
                const previousRecord = findPreviousRecord(record, allData);
                return (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Заказов в день:</strong> <ValueWithChange
                            value={record.orders_per_day}
                            previousValue={previousRecord?.orders_per_day}
                            format={formatQuantity}
                        /></Text>
                    {visible &&    <Text><strong>Выкуп:</strong> <ValueWithChange
                            value={record.buyout_percent}
                            previousValue={previousRecord?.buyout_percent}
                            format={formatPercent2}
                        /></Text>}
                        <Text><strong>Маржинальность:</strong> <ValueWithChange
                            value={record.marzha_unit}
                            previousValue={previousRecord?.marzha_unit}
                            format={formatPercent2}
                        /></Text>
                        {visible &&<Text><strong>Наценка:</strong> <ValueWithChange
                            value={record.nacenka_unit}
                            previousValue={previousRecord?.nacenka_unit}
                            format={formatPercent2}
                        /></Text>}
                    </Space>
                );
            }
        },
        {
            title: 'Расходы',
            width: 200,
            render: (_, record) => {
                return (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Логистика:</strong> {formatCurrency(record.delivery_cost)}</Text>
                        <Text><strong>Хранение:</strong> {formatCurrency(record.store_cost)}</Text>
                        {visible && <>
                        <Text><strong>Приемка:</strong> {formatCurrency(record.supply_cost)}</Text>
                        <Text><strong>Штрафы:</strong> {formatCurrency(record.penalty)}</Text></>}
                    </Space>
                );
            }
        },
        {
            title: 'Реклама',
            width: 200,
            render: (_, record) => {
                const previousRecord = findPreviousRecord(record, allData);
                return (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Маркетинг:</strong> {formatCurrency(record.budget)}</Text>
                        {visible &&<Text><strong>CTR:</strong> <ValueWithChange
                            value={record.ctr}
                            previousValue={previousRecord?.ctr}
                            format={formatPercent}
                        /></Text>}
                        <Text><strong>ДРР:</strong> <ValueWithChange
                            value={record.drr}
                            previousValue={previousRecord?.drr}
                            format={formatPercent}
                            invertColors={true}
                        /></Text>
                        {visible &&<Text><strong>Сумма заказов:</strong> <ValueWithChange
                            value={record.adv_orders_sum}
                            previousValue={previousRecord?.adv_orders_sum}
                            format={formatCurrency}
                        /></Text>}
                    </Space>
                );
            }
        },
    ]
}

const expandData = (cards) => {
    return cards.map((card) => {
        const newCard = {...card};
        newCard.key = card.wbacc_name;

        // Создаем массив дней с parent_key, сортируем по дате (сначала старые)
        const sortedDays = card.by_period
            .map((item, index) => ({
                ...item,
                key: `${card.wbacc_name}_${index}`,
                parent_key: card.wbacc_name
            }))
        //.sort((a, b) => new Date(a.date) - new Date(b.date));

        // Копируем данные самого нового дня в родительскую запись
        if (sortedDays.length > 0) {
            const newestDay = sortedDays[0];
            Object.keys(newestDay).forEach(key => {
                if (key !== 'key' && key !== 'parent_key') {
                    newCard[key] = newestDay[key];
                }
            });

            // В children помещаем все дни кроме самого нового
            newCard.children = sortedDays.slice(1);
        } else {
            newCard.children = [];
        }

        return newCard;
    });
}

export default function TableStatDay2({cards, loading}) {
    console.log(cards)
      const [visible, setVisible] = useState(false);
    const DataSource = cards ? expandData(cards) : [];
    const columns = getColumns(DataSource,visible);

    return (
        <>
        <Button
                            size="medium"
                            onClick={() => setVisible(!visible)}
                            style={{ marginBottom: '8px' }}
                        >
                            {visible ? 'Скрыть' : 'Отобразить'} детали
                        </Button>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800
                }}
                loading={loading}
                pagination={false}
                className='adverts-table2'
            />
        </>
    )
}
