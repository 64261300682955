import {Button, Card, Col, Flex, Form, Input, message, Modal, Row, Select, Typography} from "antd";
import React from "react";
import {apiAxios} from "../../utils/api_axios";


const {Title, Text, Link, Paragraph} = Typography
export const TabProfile = (user, setIsAuth, tabProfileState, setTabProfile, setActiveTab, isModalOpen, setIsModalOpen) => {

    const onFinish = async (values) => {
        console.log('Received values of form: ', values)
        values.like = tabProfileState.like
        values.hide = tabProfileState.hide
        await apiAxios.post('user/update/', values)
        message.open({type: 'success', content: 'Сохранено!', style: {marginTop: '5vh',},});
    }
    const tariffs = {
        0: {
            title: 'Тестовый',
        },
        1: {
            title: 'Базовый',
        },
        2: {
            title: 'Профи',
        },
        3: {
            title: 'Корпоративный',
        },
    }
    return (
        <>
            <Row>
                <Col lg={18}>
                    <Form
                        name='user-profile-details-form'
                        layout='vertical'
                        initialValues={{id: user.id, username: user.username, firstName: user.first_name, lastName: user.last_name, email: user.email, phone: user.phone}}
                        autoComplete='on'
                        requiredMark={false}
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col xs={24} md={12} lg={6}>
                                <Form.Item label='Имя' name='firstName' rules={[{required: true, message: 'Пожалуйста, введите ваше Имя!'}]}>
                                    <Input style={{width: '350px'}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <Form.Item label='Фамилия' name='lastName' rules={[{required: true, message: 'Пожалуйста, введите вашу Фамилию!'}]}>
                                    <Input style={{width: '350px'}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <Form.Item label='Телефон' name='phone'>
                                    <Input style={{width: '350px'}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <Form.Item label='Email' name='email' rules={[{required: true, message: 'Пожалуйста, введите почту!'}]}>
                                    <Input style={{width: '350px'}} disabled/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <Form.Item label='Username' name='username' rules={[{required: true, message: 'Пожалуйста, введите username!'}]}>
                                    <Input style={{width: '350px'}} disabled/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <Form.Item label='Избранное' name='like' style={{maxWidth: '400px'}}>

                                    <Select
                                        mode="tags"
                                        maxTagCount={'responsive'}
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder="Введите артикул"
                                        value={tabProfileState.like}
                                        defaultValue={tabProfileState.like}
                                        onChange={(value) => setTabProfile('like', value)}
                                        options={tabProfileState.like.map((item) => ({label: item, value: item}))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <Form.Item label='Скрытое' name='hide' style={{maxWidth: '400px'}}>
                                    <Select
                                        mode="tags"
                                        maxTagCount={'responsive'}
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder="Введите артикул"
                                        value={tabProfileState.hide}
                                        defaultValue={tabProfileState.hide}
                                        onChange={(value) => setTabProfile('hide', value)}
                                        options={tabProfileState.hide.map((item) => ({label: item, value: item}))}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Form.Item>
                            <Flex gap={20}>
                                <Button type='primary' htmlType='submit' size='large'>
                                    Сохранить данные
                                </Button>
                                <Button danger size='large' onClick={() => setIsModalOpen('account_remove', true)}>
                                    Удалить аккаунт
                                </Button>
                            </Flex>
                        </Form.Item>

                    </Form>
                </Col>
                <Col lg={6}>
                    <Card>
                        <Title level={5} style={{margin: 0,}}>Ваша подписка</Title>
                        <Title level={4} >Тариф: {tariffs[user.tariff_id]?.title}</Title>
                        <Title level={4} style={{margin: 0,}}>Действует до: {user.sub}</Title>
                        <Button type='primary' style={{marginTop: '20px',}} onClick={() => setActiveTab('Подписка')}>
                            Выбрать тариф
                        </Button>
                    </Card>
                </Col>
            </Row>
            <ModalDeleteAccount setIsAuth={setIsAuth} isModalOpen={isModalOpen.account_remove} setIsModalOpen={setIsModalOpen}/>
        </>
    )
}


const ModalDeleteAccount = ({setIsAuth, isModalOpen, setIsModalOpen}) => {

    const handleOk = async () => {
        setIsModalOpen('account_remove', false);
        await apiAxios.post('user/remove/')
        setIsAuth(false);
        localStorage.setItem('isAuth', false);

    };
    return (
        <Modal title="Удаление аккаунта" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('account_remove', false)}
               footer={[
                   <Button key="submit" danger onClick={handleOk}>
                       Удалить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('account_remove', false)}>
                       Отменить
                   </Button>,
               ]}>

            <Paragraph className="small text-muted">
                Вы уверены что хотите удалить свой аккаунт? Это действие невозможно будет отменить
            </Paragraph>

        </Modal>
    )
}