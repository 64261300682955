import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { createContext, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import erorServer from '../utils/svgs/505error.svg'
import Title from 'antd/es/typography/Title';
const ErrorModalContext = createContext();

export const useErrorModal = () => {
    return useContext(ErrorModalContext);
};

let externalShowErrorModal = null; // Внешняя ссылка для вызова

export const ErrorModalProvider = ({ children }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showErrorModal = () => {
        setIsModalVisible(true);
    };

    const hideErrorModal = () => {
        setIsModalVisible(false);
    };

    // Сохраняем ссылку на showErrorModal для использования вне компонента
    externalShowErrorModal = showErrorModal;
console.log("externalShowErrorModal initialized:", !!externalShowErrorModal);

    return (
        <ErrorModalContext.Provider value={{ showErrorModal, hideErrorModal }}>
            {children}
            <ErrorModal isVisible={isModalVisible} hideErrorModal={hideErrorModal} onClose={hideErrorModal} />
        </ErrorModalContext.Provider>
    );
};

const ErrorModal = ({ isVisible, onClose,hideErrorModal }) => {
    return (
        <Modal
            title={<Title level={3}>Ошибка обращения к сервису</Title>}
            visible={isVisible}
            onOk={onClose}
            centered
            onCancel={onClose}
            footer={<div className='flex items-center justify-start gap-1'>
                    <Button key="refresh" style={{ backgroundColor: '#1f1f1f' }} type='primary' onClick={() => window.location.reload()}>
                    <LoadingOutlined /> Обновить страницу
                </Button>
                <Link to={'/'}>
                    <Button onClick={hideErrorModal} key="home" >
                        На главную
                    </Button>
                </Link>
            </div>}
        >
            <div className='flex w-full pt-4 justify-center'><img src={erorServer} className="w-[80%]" alt="error-500" /></div>
            <p className='text-base pt-5 font-medium'>Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время. Приносим извинения за временные неудобства.</p>
            <p className="font-bold pt-2 text-xl">Код ошибки: <span className='text-red-500'>500</span></p>
        </Modal>
    );
};

// Функция для вызова модального окна извне
export const triggerErrorModal = () => {
    console.log("triggerErrorModal called:", !!externalShowErrorModal);
    if (externalShowErrorModal) {
        externalShowErrorModal();
    } else {
        console.error("externalShowErrorModal is not defined");
    }
};
