import '../../output.css'
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import ReactCardFlip from 'react-card-flip'
import { FaRegCheckCircle } from 'react-icons/fa'
import { FiArrowRightCircle } from 'react-icons/fi'
import { BsCalendarX } from 'react-icons/bs'
import { Button, Modal, Typography, Space, message, Form, Input, Switch } from 'antd'
import { apiAxios } from '../../utils/api_axios'
import { formatCurrency } from '../../utils/formats'
const { Title, Text } = Typography

const shouldDisplayDiscount = (activeButton, index) => {
    return activeButton === 3 || activeButton === 6 || activeButton === 12
}

const SubscribeButton = React.memo(({ item, isActive, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={`${
                isActive ? 'bg-grad-button text-white border-transparent duration-300 transition-all' : 'text-gray-500 duration-300 transition-all'
            } font-medium duration-300 active:scale-95 transition-all hover:bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] hover:text-white py-2 ss:min-w-[200px] min-w-[140px] md:px-4 max-w-[230px] md:w-full rounded-full border-[1px] border-gray-500`}
        >
            {item.title}
        </button>
    )
})

const discounts = {
    3: '- 10%',
    6: '- 15%',
    12: '- 25%'
}

const month = [
    { id: 1, title: '1 месяц' },
    { id: 3, title: '3 месяца' },
    { id: 6, title: '6 месяцев' },
    { id: 12, title: '12 месяцев' }
]

export const TabSub = () => {
    const [value, setValue] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [tariffInfo, setTariffInfo] = useState({})
    const [paymentLink, setPaymentLink] = useState('')
    const [paymentId, setPaymentId] = useState('')
    const [selectedMonth, setSelectedMonth] = useState(1)

    const cards = useMemo(
        () => [
            {
                id: 1,
                title: 'Базовый',
                content: 'Селлерам с выручкой до 500 000 ₽/мес',
                cost: '2 990 ₽',
                value1: value ? 2990 : 2513,
                value3: value ? 8490 : 7136,
                value6: value ? 15990 : 13440,
                value12: value ? 29990 : 25208,
                details: 'Подробная информация о тарифе Базовый',
                more: ['90% функций сервиса', '2 магазина']
            },
            {
                id: 2,
                title: 'Профи',
                content: 'Без ограничения по выручке',
                cost: '6 990 ₽',
                value1: value ? 6990 : 5875,
                value3: value ? 19990 : 16803,
                value6: value ? 34990 : 29411,
                value12: value ? 69990 : 58831,
                details: 'Подробная информация о тарифе Профи',
                more: ['100% функций сервиса', 'Экспорт отчетов в Excel', '5 магазинов']
            },
            {
                id: 3,
                title: 'Корпоративный',
                content: 'Без ограничения по выручке',
                cost: '19 990 ₽',
                value1: value ? 19990 : 16803,
                value3: value ? 59990 : 50425,
                value6: value ? 99990 : 84048,
                value12: value ? 199990 : 168105,
                details: 'Подробная информация о тарифе Корпоративный',
                more: ['100% функций сервиса', 'Экспорт отчетов в Google Sheets', '12 магазинов', 'Интеграция МойСклад', 'Приоритетная поддержка']
            }
        ],
        [value]
    )

    const [flippedCards, setFlippedCards] = useState(Array(cards.length).fill(false))

    const handleClick = useCallback((id) => {
        setSelectedMonth(id)
    }, [])

    const handleFlip = useCallback((index) => {
        setFlippedCards((prev) => {
            const newFlippedCards = [...prev]
            newFlippedCards[index] = !newFlippedCards[index]
            return newFlippedCards
        })
    }, [])

    const openModal = useCallback(
        (tariff) => {
            const values = {
                1: tariff.value1,
                3: tariff.value3,
                6: tariff.value6,
                12: tariff.value12
            }
            setTariffInfo({
                title: tariff.title,
                value: values[selectedMonth] || tariff.value1,
                tariff_id: tariff.id,
                month: selectedMonth
            })
            setIsModalOpen(true)
        },
        [selectedMonth]
    )

    const closeModal = () => {
        setIsModalOpen(false)
        setPaymentLink('') // Reset payment link when modal closes
    }

    const intervalRef = useRef(null)

    const handlePayClick = useCallback(async () => {
        try {
            const response = await apiAxios.post('user/sub/generate_link', { tariffInfo })
            console.log(response, 'response!')
            if (response) {
                const paymentLink = response.url
                const paymentId = response.payment_id

                setPaymentId(paymentId)
                window.location.href = paymentLink

                // Проверка статуса оплаты
                setTimeout(() => {
                    intervalRef.current = setInterval(async () => {
                        const statusResponse = await apiAxios.post('user/sub/get_status', { payment_id: paymentId })
                        const status = statusResponse.status

                        if (status === 'paid' || status === 'cancel') {
                            clearInterval(intervalRef.current)
                            intervalRef.current = null
                            closeModal()
                            message.open({
                                type: status === 'paid' ? 'success' : 'error',
                                content: status === 'paid' ? 'Успешная оплата! Продуктивного использования!' : 'Оплата не прошла, либо время на оплату истекло',
                                style: { marginTop: '5vh' }
                            })
                        }
                    }, 20000)

                    setTimeout(() => {
                        if (intervalRef.current) clearInterval(intervalRef.current)
                    }, 1200000)
                }, 5000)
            }
        } catch (error) {
            console.error('Error in handlePayClick:', error)
        }
    }, [tariffInfo, closeModal])

    const handleChange = useCallback(() => {
        setValue((prev) => !prev)
    })

    return (
        <section className='' id='cost'>
            <>
                <h2 className='text-black font-bold ss:text-3xl text-2xl'>Выберите тариф</h2>
                <Switch value={value} onChange={handleChange} checkedChildren='₽' unCheckedChildren='som' />
                <div className='mt-10 flex flex-col gap-10'>
                    <div className='flex gap-5 flex-wrap md:overflow-hidden'>
                        {month.map((item) => (
                            <SubscribeButton key={item.id} item={item} isActive={selectedMonth === item.id} onClick={() => handleClick(item.id)} />
                        ))}
                    </div>
                    <div className={`flex flex-wrap gap-3 ss:gap-5 `}>
                        {cards.map((item, index) => (
                            <ReactCardFlip key={index} isFlipped={flippedCards[index]} flipDirection='horizontal'>
                                <div
                                    className={`p-10 flex flex-col h-[420px] lg:max-w-[340px] lg:min-w-[340px] ss:min-w-[452px] relative gap-5 sm:max-w-[320px] ss:max-w-[600px] md:max-w-[480px] max-w-[300px] w-full bg-[#eaf3fd]  rounded-xl hover:bg-white shadowCard duration-300 transition-all`}
                                >
                                    {shouldDisplayDiscount(selectedMonth, index) && (
                                        <div className='bg-[#2a84ee] absolute right-0 top-0 rounded-se-lg text-white font-medium text-xl p-3 rounded-bl-lg'>{discounts[selectedMonth]}</div>
                                    )}
                                    <div className='flex flex-col gap-3'>
                                        <h3 className='text-2xl font-semibold xl:text-2xl ss:text-3xl text-black'>{item.title}</h3>
                                        <p className='text-gray-500 ss:text-xl text-base'>{item.content}</p>
                                    </div>
                                    <div className='min-h-[68px] flex flex-col justify-end'>
                                        <p className='text-blue-600 text-3xl font-bold'>{formatCurrency(item[`value${selectedMonth}`])}</p>
                                    </div>
                                    <div className='flex flex-col gap-5'>
                                        <button
                                            key={item.id}
                                            className={` font-medium duration-200 active:scale-95 transition-all bg-gradient-to-r from-[#ed8ce3] to-[#6f96dd] text-white py-3 text-xl px-4  w-full rounded-full `}
                                            onClick={() => openModal(item)}
                                        >
                                            Попробовать
                                        </button>
                                        <button
                                            onClick={() => handleFlip(index)}
                                            className={`duration-300 flex gap-3 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-3 text-xl px-4 w-full rounded-full `}
                                        >
                                            Подробнее
                                            <FiArrowRightCircle className='text-xl text-purple-500' />
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={`p-10 flex flex-col h-[520px] sm:h-[420px] lg:max-w-[340px] lg:min-w-[340px] ss:min-w-[452px] relative gap-5 sm:max-w-[320px] ss:max-w-[600px] md:max-w-[480px] max-w-[300px] w-full bg-[#e5fff9]  rounded-xl hover:bg-white shadowCard duration-300 transition-all`}
                                >
                                    <h3 className='text-xl font-semibold text-black'>Возможности</h3>
                                    <div className='flex flex-col gap-3'>
                                        {item.more.map((item, index) => (
                                            <div key={index} className={`${index !== 0 && 'border-t-[1px] border-gray-400'} flex pt-1 items-center gap-3`}>
                                                <FaRegCheckCircle className='text-purple-600 text-base ' />
                                                <p className='text-sm'>{item}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='h-full flex flex-col justify-end '>
                                        <button
                                            onClick={() => handleFlip(index)}
                                            className={`duration-300 flex gap-3 items-center justify-center active:scale-95 transition-all border-[1px] border-purple-500 text-black font-semibold py-3 text-xl px-4 w-full rounded-full `}
                                        >
                                            Назад
                                            <FiArrowRightCircle className='text-xl text-purple-500 rotate-180' />
                                        </button>
                                    </div>
                                </div>
                            </ReactCardFlip>
                        ))}
                    </div>
                </div>
            </>
            <Modal
                title={
                    <Title level={3} className='pt-4'>
                        Оплата
                    </Title>
                }
                visible={isModalOpen}
                onCancel={closeModal}
                centered
                className='ModalSubsctibe'
                footer={[
                    <div className='pb-4 flex gap-4 pl-4 pr-4'>
                        <Button key='pay-button' type='primary' style={{ backgroundColor: '#1f1f1f' }} onClick={handlePayClick}>
                            Оплатить
                        </Button>
                        <Button key='close-button' type='' onClick={closeModal}>
                            Закрыть
                        </Button>
                    </div>
                ]}
                style={{ textAlign: 'center' }}
            >
                <Form layout='vertical' className=' flex flex-col justify-start items-start' style={{ width: '100%' }}>
                    <Title className='pl-4' level={4}>
                        Тариф – {tariffInfo.title}
                    </Title>
                    <div className='flex flex-'>
                        <span className='pl-4 pb-2'>
                            Стоимость – <Text strong>{tariffInfo.value} руб.</Text>
                        </span>
                        <span className='pl-4 pb-2'>
                            Длительность – <Text strong>{tariffInfo.month} мес.</Text>
                        </span>
                    </div>
                    <div className='w-full h-[1px] bg-gray-400' />
                </Form>
            </Modal>
        </section>
    )
}
