import React, {useState} from "react";
import {Button, Divider, Flex, Input, message, Modal, Select, Table, Typography} from "antd";
import {apiAxios} from "../../utils/api_axios";

const {Title, Link, Paragraph} = Typography
export const TabWBAccs = (user, isModalOpen, setIsModalOpen, tabWBAccState, setTabWBAccState) => {
    console.log('render tabWBAccs', user)
    const actions = (wbacc, is_owner) => {
        console.log('render actions');
        return (
            <>
                <Flex wrap gap="small">
                    <Button color="primary" variant="outlined" onClick={() => {
                        wbacc.is_ozon ? setIsModalOpen('ozonacc_update', is_owner) : setIsModalOpen('wbacc_update', is_owner);
                        setTabWBAccState('selected_wbacc', wbacc);
                    }}>Редактировать токены</Button>
                    <Button color="primary" variant="outlined" onClick={() => {
                        setIsModalOpen('nalog', true);
                        setTabWBAccState('selected_wbacc', wbacc);
                    }}>Настройка налога</Button>
                    <Button danger onClick={() => {
                        setIsModalOpen('wbacc_remove', is_owner);
                        setTabWBAccState('selected_wbacc', wbacc);
                    }}>Удалить</Button>
                </Flex>
            </>
        )
    }
    const columns = [
        {title: 'Название', dataIndex: 'name', width: 100,},
        {title: 'Маркетплейс', dataIndex: 'img', width: 100, render: (text) => (<img src={text} style={{height: '20px'}}/>)},
        {title: 'Статус магазина', dataIndex: 'status_wbacc', width: 100,},
        {title: 'Статус токенов', dataIndex: 'status_token', width: 100,},
        {title: 'Управление', dataIndex: 'settings', width: 100, render: (text, record) => actions(record, !user.is_staff)},
    ]
    
    let data = []
    let data2 = []
    if (user.wbaccs) {
        data = user?.wbaccs.map((item) => {
            item.img = item.is_ozon ? 'assets/images/ozon.svg' : 'assets/images/wildberries.svg'
            item.status_wbacc = 'Активный'
            return item
        })
    }
    if (user.allowed_wbaccs) {
        data2 = user?.allowed_wbaccs.map((item) => {
            item.img = item.is_ozon ? 'assets/images/ozon.svg' : 'assets/images/wildberries.svg'
            item.status_wbacc = 'Активный'
            return item
        })
    }

    return (
        <>
            {!user.is_staff ? <Flex wrap gap="small">

                <Button onClick={() => setIsModalOpen('wbacc', true)} variant="outlined" style={{color: 'purple', borderColor: 'purple'}}>Подключить<img src={'assets/images/wildberries.svg'} alt="icon" className="h-[25px]"/></Button>
                <Button onClick={() => setIsModalOpen('ozonacc', true)} color="primary" variant="outlined">Подключить<img src={'assets/images/ozon.svg'} className="h-[25px]" alt="icon"/></Button>
            </Flex> : null}
            <Divider/>
            <Title>Мои магазины</Title>
            {user.wbaccs ? <Table
            className="adverts-table2"
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}

            /> : <span></span>
            }
            <Divider/>
            <Title>Доступные магазины</Title>
            {user.allowed_wbaccs ? <Table
                columns={columns}
                className="adverts-table2"
                dataSource={data2}
                bordered
                pagination={false}

            /> : <span></span>
            }


            <ModalAddWBAcc isModalOpen={isModalOpen.wbacc} setIsModalOpen={setIsModalOpen}/>
            <ModalAddOzonAcc isModalOpen={isModalOpen.ozonacc} setIsModalOpen={setIsModalOpen}/>
            <ModalSetNalog isModalOpen={isModalOpen.nalog} setIsModalOpen={setIsModalOpen} tabWBAccState={tabWBAccState} setTabWBAccState={setTabWBAccState}/>
            <ModalRemoveWbAcc isModalOpen={isModalOpen.wbacc_remove} setIsModalOpen={setIsModalOpen} tabWBAccState={tabWBAccState}/>
            <ModalUpdateWBAcc isModalOpen={isModalOpen.wbacc_update} setIsModalOpen={setIsModalOpen} tabWBAccState={tabWBAccState} setTabWBAccState={setTabWBAccState}/>
            <ModalUpdateOzonAcc isModalOpen={isModalOpen.ozonacc_update} setIsModalOpen={setIsModalOpen} tabWBAccState={tabWBAccState} setTabWBAccState={setTabWBAccState}/>

        </>
    )
        ;
}


const ModalAddWBAcc = ({isModalOpen, setIsModalOpen}) => {
    const [params, setParams1] = useState({name: '', token: '', x_supplier_id: ''})
    const setParams = (name, value) => {
        setParams1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleOk = async () => {
        console.log('save wbacc', params);
        const res = await apiAxios.post('user/wbacc/new/', params)
        if (res.success) {
            setIsModalOpen('wbacc', false);
            message.open({
                type: 'success',
                content: 'Магазин добавлен!',
                style: {
                    marginTop: '5vh',
                },
            });
        } else {
            message.open({
                type: 'error',
                content: `Не удалось добавить магазин! ${res.error}`,
                style: {
                    marginTop: '5vh',
                },
            });
        }
    };
    return (
        <Modal title="Добавить аккаунт" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('wbacc', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Сохранить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('wbacc', false)}>
                       Отменить
                   </Button>,
               ]}>
            <img src={'assets/images/wildberries.svg'} height='40'></img>

            <Title level={5} style={{marginTop: 0}}>Название магазина</Title>
            <Input label={"Field B"} placeholder="Название магазина" value={params.name} onChange={(e) => setParams('name', e.target.value)}/>

            <Title level={5} style={{marginTop: 0}}>Токен</Title>
            <Input placeholder="Токен" value={params.token} onChange={(e) => setParams('token', e.target.value)}/>
            <Title level={5} style={{marginTop: 0}}>x-supplier-id</Title>
            <Input placeholder="x-supplier-id" value={params.x_supplier_id} onChange={(e) => setParams('x_supplier_id', e.target.value)}/>
            <Paragraph className="small text-muted">
                Токен находится в разделе{' '}
                <Link href="https://seller.wildberries.ru/supplier-settings/access-to-api">
                    Доступ к API на сайте Wildberries
                </Link>.
                {' '}

                <br/>
                Для полноценной работы в сервисе необходимо выбрать все настройки (модули).
                Так же необходимо добавить номер +79876146123 в сотрудники, так как wildberries не дает полной статистики по api.
                <br/>
                Подробнее о том, где взять токен и x-supplier-id в{' '}
                <Link href="https://telegra.ph/Kak-dobavit-akkaunt-Wildberries-v-BB-DATA-10-23">
                    статье
                </Link>.
                После добавление нового магазина и успешной верификации ключей, начинается начальная загрузка данных за
                последние 90 дней относительно даты создания магазина в нашем сервисе.

            </Paragraph>
        </Modal>
    )
}


const ModalAddOzonAcc = ({isModalOpen, setIsModalOpen}) => {
    const [wbaccName, setWbaccName] = useState('');
    const [clientId, setClientId] = useState('');
    const [token, setToken] = useState('');
    const [advertClientId, setAdvertClientId] = useState('');
    const [advertSecret, setAdvertSecret] = useState('');
    const handleOk = () => {
        setIsModalOpen('ozonacc', false);
        console.log('save ozonacc', wbaccName, clientId, advertClientId, advertSecret);
    };
    return (
        <Modal title="Добавить аккаунт" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('ozonacc', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Сохранить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('ozonacc', false)}>
                       Отменить
                   </Button>,
               ]}>
            <img src={'assets/images/ozon.svg'} height='40'></img>

            <Title level={5} style={{marginTop: 0}}>Название магазина</Title>
            <Input placeholder="Название магазина" value={wbaccName} onChange={(e) => setWbaccName(e.target.value)}/>

            <Title level={5} style={{marginTop: 0}}>Client ID</Title>
            <Input placeholder="Client ID" value={clientId} onChange={(e) => setClientId(e.target.value)}/>
            <Title level={5} style={{marginTop: 0}}>Api токен</Title>
            <Input placeholder="Api токен" value={token} onChange={(e) => setToken(e.target.value)}/>
            <Paragraph className="small text-muted">
                Ключи доступа находятся в разделе{' '}
                <Link href="https://seller.ozon.ru/app/settings/api-keys">
                    настроек на сайте OZON
                </Link>.
            </Paragraph>
            <Title level={5} style={{marginTop: 0}}>Client ID "Реклама"</Title>
            <Input placeholder='Client ID "Реклама"' value={advertClientId} onChange={(e) => setAdvertClientId(e.target.value)}/>
            <Title level={5} style={{marginTop: 0}}>Client Secret "Реклама"</Title>
            <Input placeholder='Client Secret "Реклама"' value={advertSecret} onChange={(e) => setAdvertSecret(e.target.value)}/>
            <Paragraph className="small text-muted">
                Ключи доступа рекламы находятся в разделе{' '}
                <Link href="https://seller.ozon.ru/app/settings/api-keys?currentTab=performanceApi">
                    Настройки - API-ключи - Performance API
                </Link>.
            </Paragraph>
            <Paragraph className="small text-muted">
                После добавление нового магазина и успешной верификации ключей, начинается начальная загрузка данных за последние 90 дней относительно даты создания магазина в нашем сервисе.
            </Paragraph>
        </Modal>
    )
}


const ModalSetNalog = ({isModalOpen, setIsModalOpen, tabWBAccState, setTabWBAccState}) => {

    const handleOk = async () => {
        setIsModalOpen('nalog', false);
        await apiAxios.post('user/wbacc/update/', {'id': tabWBAccState.selected_wbacc.id, 'nalog_type': tabWBAccState.selected_wbacc.nalog_type, 'nalog_percent': tabWBAccState.selected_wbacc.nalog_percent})
    };
    const nalogOptions = [{label: 'УСН Доходы', value: 1}, {label: 'УСН Доходы минус расходы', value: 2}, {label: 'ОСНО без НДС', value: 3}, {label: 'ОСНО с НДС', value: 4},]
    return (
        <Modal title="Изменить настройки налога" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('nalog', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Сохранить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('nalog', false)}>
                       Отменить
                   </Button>,
               ]}>

            <Paragraph className="small text-muted">
                Установить налог для магазина {tabWBAccState.selected_wbacc.name}
            </Paragraph>
            <Title level={5} style={{marginTop: 0}}>Система налогообложения</Title>
            <Select
                allowClear
                style={{width: '100%'}}
                placeholder="Выберите систему налогообложения"
                value={tabWBAccState.selected_wbacc.nalog_type}
                onChange={(value) => {
                    let wbacc = tabWBAccState.selected_wbacc;
                    wbacc.nalog_type = value;
                    setTabWBAccState('selected_wbacc', wbacc);
                }}
                options={nalogOptions}
            />
            <Title level={5} style={{marginTop: 0}}>Процентная ставка</Title>
            <Input placeholder="Введите процентную ставку" value={tabWBAccState.selected_wbacc.nalog_percent} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.nalog_percent = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>


        </Modal>
    )
}

const ModalRemoveWbAcc = ({tabWBAccState, isModalOpen, setIsModalOpen}) => {
    const handleOk = () => {
        setIsModalOpen('wbacc_remove', false);
        console.log('remove wbacc', tabWBAccState.selected_wbacc);
        message.open({
            type: 'success',
            content: 'Магазин удален!',
            style: {
                marginTop: '5vh',
            },
        });
    };
    return (
        <Modal title="Удалить магазин" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('wbacc_remove', false)}
               footer={[
                   <Button key="submit" danger onClick={handleOk}>
                       Удалить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('wbacc_remove', false)}>
                       Отменить
                   </Button>,
               ]}>
            <Paragraph className="small text-muted">
                Вы действительно хотите удалить магазин {tabWBAccState.selected_wbacc.name}?
            </Paragraph>
        </Modal>
    )
}

const ModalUpdateWBAcc = ({isModalOpen, setIsModalOpen, tabWBAccState, setTabWBAccState}) => {

    const handleOk = () => {
        setIsModalOpen('wbacc_update', false);
        console.log('save wbacc', tabWBAccState.selected_wbacc);
    };
    return (
        <Modal title="Редактировать магазин" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('wbacc_update', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Сохранить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('wbacc_update', false)}>
                       Отменить
                   </Button>,
               ]}>
            <img src={'assets/images/wildberries.svg'} height='40'></img>

            <Title level={5} style={{marginTop: 0}}>Название магазина</Title>
            <Input placeholder="Название магазина" value={tabWBAccState.selected_wbacc.name} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.name = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>

            <Title level={5} style={{marginTop: 0}}>Токен</Title>
            <Input placeholder="Токен" value={tabWBAccState.selected_wbacc.token} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.token = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>
            <Paragraph className="small text-muted">
                Токен находится в разделе{' '}
                <Link href="https://seller.wildberries.ru/supplier-settings/access-to-api">
                    Доступ к API на сайте Wildberries
                </Link>.
                {' '}
                <b>Минимальные требования: модуль "Статистика", модуль "Контент".</b>
                <br/>
                Для более полноценной работы в сервисе необходимые следующие настройки (модули):
                Статистика, Контент, Аналитика (будут передаваться данные по хранению на складах), Цены и скидки
                (будут передаваться данные по ценам),
                Маркетплейс, Продвижение (данные по рекламной статистике, расходы по счету из истории затрат).
                <br/>
                Подробнее о новых токенах в{' '}
                <Link href="">
                    статье
                </Link>.

            </Paragraph>
        </Modal>
    )
}

const ModalUpdateOzonAcc = ({isModalOpen, setIsModalOpen, tabWBAccState, setTabWBAccState}) => {
    const handleOk = () => {
        setIsModalOpen('ozonacc_update', false);
        console.log('save ozonacc', tabWBAccState.selected_wbacc);
    };
    return (
        <Modal title="Редактировать магазин" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('ozonacc_update', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Сохранить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('ozonacc_update', false)}>
                       Отменить
                   </Button>,
               ]}>
            <img src={'assets/images/ozon.svg'} height='40'></img>

            <Title level={5} style={{marginTop: 0}}>Название магазина</Title>
            <Input placeholder="Название магазина" value={tabWBAccState.selected_wbacc.name} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.name = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>

            <Title level={5} style={{marginTop: 0}}>Client ID</Title>
            <Input placeholder="Client ID" value={tabWBAccState.selected_wbacc.clientid} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.clientid = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>
            <Title level={5} style={{marginTop: 0}}>Api токен</Title>
            <Input placeholder="Api токен" value={tabWBAccState.selected_wbacc.token} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.token = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>

            <Paragraph className="small text-muted">
                Ключи доступа находятся в разделе{' '}
                <Link href="https://seller.ozon.ru/app/settings/api-keys">
                    настроек на сайте OZON
                </Link>.
            </Paragraph>
            <Title level={5} style={{marginTop: 0}}>Client ID "Реклама"</Title>
            <Input placeholder='Client ID "Реклама"' value={tabWBAccState.selected_wbacc.client_id} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.client_id = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>
            <Title level={5} style={{marginTop: 0}}>Client Secret "Реклама"</Title>
            <Input placeholder='Client Secret "Реклама"' value={tabWBAccState.selected_wbacc.client_secret} onChange={(e) => {
                let wbacc = tabWBAccState.selected_wbacc;
                wbacc.client_secret = e.target.value;
                setTabWBAccState('selected_wbacc', wbacc);
            }}/>
            <Paragraph className="small text-muted">
                Ключи доступа рекламы находятся в разделе{' '}
                <Link href="https://seller.ozon.ru/app/settings/api-keys?currentTab=performanceApi">
                    Настройки - API-ключи - Performance API
                </Link>.
            </Paragraph>
        </Modal>
    )
}