import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography

const SideBarTraffic = () => {
    const sections = [
        {
            label: 'Фильтры',
            description: [
                'В верхней части страницы пользователю доступны фильтры для выбора периода данных и источников трафика.',
                'Также есть кнопка "Экспорт в Excel" для выгрузки данных в таблицу Excel.'
            ]
        },
        {
            label: 'Таблица внешнего трафика',
            description: [
                'Таблица содержит детализированную информацию о внешнем трафике, включая следующие колонки:',
                'Название – название источника трафика.',
                'ID – уникальный идентификатор источника.',
                'Артикул – артикул товара, связанного с источником трафика.',
                'Ссылка – URL, по которому осуществляется переход.',
                'Переходы – количество переходов по ссылке.',
                'Заказы – количество оформленных заказов.',
                'Сумма заказов – общая сумма всех заказов, оформленных через источник.'
            ]
        },
        {
            label: 'Интерактивные элементы',
            description: [
                'Пользователь может взаимодействовать с таблицей, например, скрывать или показывать определенные колонки для удобства анализа.',
                'Каждая строка таблицы может быть кликабельной для перехода к детальной информации о конкретном источнике трафика.'
            ]
        },
        {
            label: 'Модальные окна',
            description: ['Есть возможность добавления новых источников трафика через модальное окно.', 'Также предусмотрено удаление существующих источников с подтверждением действия.']
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={2}>Описание страницы "Внешний трафик"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Внешний трафик"</span> представлена таблица с детализированной информацией о внешних источниках трафика.
            </Text>

            <Title level={3}>Основные элементы страницы:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>
                Эта страница позволяет пользователям глубоко анализировать данные о внешнем трафике, что упрощает контроль за показателями и помогает принимать более обоснованные решения.
            </Text>
        </div>
    )
}

export default SideBarTraffic
