import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography

const SideBarOborot = () => {
    const sections = [
        {
            label: 'Фильтры',
            description: [
                'В верхней части страницы пользователю доступны фильтры для выбора периода данных и групп товаров.',
                'Также есть кнопка "Экспорт в Excel" для выгрузки данных в таблицу Excel.'
            ]
        },
        {
            label: 'Таблица оборачиваемости',
            description: [
                'Таблица содержит детализированную информацию о оборачиваемости товаров, включая следующие колонки:',
                'Группа – категория товаров.',
                'Остатки FBO – текущее количество остатков на складе.',
                'В пути до – количество товаров, находящихся в пути до клиента.',
                'В пути от – количество товаров, находящихся в пути от клиента.',
                'Себестоимость – стоимость товаров.',
                'Деньги в товаре – общая сумма денег, вложенная в товары.',
                'Заказов в день – среднее количество заказов за день.',
                'Продажи в день – среднее количество выкупов за день.',
                'Процент выкупа – процент выкупа по сравнению с общим количеством заказов.',
                'Оборачиваемость – количество дней, необходимых для полного оборота запасов.',
                'Запас дней – количество дней, на которые хватает текущих остатков.'
            ]
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={2}>Описание страницы "Оборачиваемость"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Оборачиваемость"</span> представлена таблица с детализированной информацией о показателях оборачиваемости товаров.
            </Text>

            <Title level={3}>Основные элементы страницы:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>
                Эта страница позволяет пользователям глубоко анализировать оборачиваемость товаров, что упрощает контроль за запасами и помогает принимать более обоснованные решения.
            </Text>
        </div>
    )
}

export default SideBarOborot
