import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import ReactPlayer from 'react-player/youtube'
import React from 'react'

const { Text } = Typography

const SideBarProfile = () => {
    const sections = [
        {
            label: 'Информация',
            description: [
                'В этой вкладке пользователю предоставляется возможность редактировать личные данные, включая имя, фамилию, телефон и email.',
                'Также здесь можно видеть "Избранное" и "Скрытое," представляющие собой списки, отображаемые в виде тэгов.',
                'Кнопки "Сохранить данные" и "Удалить аккаунт" позволяют сохранить изменения или удалить аккаунт соответственно.'
            ]
        },
        {
            label: 'Магазины',
            description: ['Эта вкладка позволяет пользователю управлять своими магазинами, включая добавление, удаление и редактирование информации о каждом из них.']
        },
        {
            label: 'Сотрудники',
            description: ['Вкладка предназначена для управления сотрудниками. Пользователь может добавлять или удалять сотрудников, а также назначать им соответствующие роли и права доступа.']
        },
        {
            label: 'Подписка',
            description: [
                'В данной вкладке отображается информация о текущем тарифе пользователя, включая его тип, срок действия и количество доступных дополнительных магазинов и сотрудников.',
                'Здесь же можно выбрать новый тариф, нажав кнопку "Выбрать тариф."'
            ]
        },
        {
            label: 'Партнерская программа',
            description: ['Вкладка предоставляет информацию о партнерской программе и, вероятно, возможности для подключения или участия в ней.']
        }
    ]

    return (
        <div>
            <ReactPlayer width={'560px'} controls={true} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
            <Title className='mt-3' level={2}>Описание страницы "Профиль"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Профиль"</span> представлено несколько вкладок, которые позволяют управлять личной информацией и связанными с ней данными.
            </Text>
            <Title level={3}>Основные разделы страницы:</Title>
            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>
            <Text className='font-bold text-xs'>
                Эта страница предоставляет пользователю удобный способ для управления личной информацией, а также возможностями взаимодействия с магазинами и сотрудниками.
            </Text>
        </div>
    )
}

export default SideBarProfile
