import {EyeOutlined, HeartOutlined} from '@ant-design/icons'
import {Checkbox, Space, Switch, Table, Typography} from 'antd'
import React, {useState} from 'react'
import {formatCurrency, formatDays, formatDays2, formatPercent, formatQuantity} from "../../../utils/formats";

const {Text} = Typography

const getColumns = (isOzon, is_our) => {
    const isMobile = window.innerWidth <= 769
    return [
        {
            title: 'Товар',
            key: 'product',
            width: 300,
            fixed: isMobile ? '' : 'left',
            render: (_, record) => {
                return record.is_size ? null : (
                <Space direction="vertical" size={0}>
                    <Space align="start" size={5}>
                        <img src={record.img || 'assets/images/logo.svg'} style={{width: '60px', height: 'auto', marginTop: '8px'}} alt="Product"/>
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                            <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                            <Text><strong>Название:</strong> {record.name}</Text>
                            <Text><strong>Карточка:</strong> {record.imt_id}</Text>
                        </Space>
                    </Space>
                </Space>
            )}
        },
        // Информация о товаре
        {
            title: 'Информация о товаре',
            width: 300,
            render: (_, record) => {
                return record.is_size ? (
                    <Space direction="vertical" size={0}>
                        <Text><strong>Размер:</strong> {record.size_name}</Text>
                    </Space>
                ) : (
                    <Space size={12} className="cost-info-cell" style={{width: '100%',}}>
                        <Space direction="vertical" size={0}>

                            <Text><strong>Бренд:</strong> {record.brand}</Text>
                            <Text><strong>Категория:</strong> {record.category}</Text>
                            <Text><strong>Модель:</strong> {record.model}</Text>
                            <Text><strong>Материал:</strong> {record.material}</Text>

                        </Space>
                        <Space direction="vertical" size={0}>
                              <Text><strong>Сезон:</strong> {record.sezon}</Text>
                            <Text><strong>Статус:</strong> {record.status}</Text>
                            <Text><strong>Цвет:</strong> {record.color}</Text>
                            <Text><strong>Рейтинг:</strong> {record.rating}</Text>
                            <Text><strong>Объем:</strong> {record.value}</Text>
                        </Space>
                    </Space>
                );
            }
        },
        {
            title: 'Остатки',
            width: 120,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Всего:</strong> {formatQuantity(record.full)}</Text>
                    <Text><strong>FBO:</strong> {formatQuantity(record.ost)}</Text>
                    <Text><strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}</Text>
                    <Text><strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}</Text>
                </Space>
            )
        },
        {
            title: 'Себестоимость',
            width: 200,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    {record.is_size ? null : <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes)}</Text>}
                    <Text><strong>Себес остатков:</strong> {formatCurrency(record.sebes * record.full)}</Text>
                    <Text><strong>Деньги в товаре:</strong> {formatCurrency(record.ost_sum)}</Text>
                </Space>
            )
        },
        is_our ?
        {
            title: 'Другие остатки',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Остаток МС:</strong> {formatQuantity(record.ms_ost || 0)}</Text>
                    <Text><strong>Транзит:</strong> {formatQuantity(record.ms_transfer || 0)}</Text>
                    <Text><strong>Шьется:</strong> {formatQuantity(record.ms_production || 0)}</Text>
                </Space>
            )
        } : null,
        {
            title: 'Цена',
            width: 170,
            render: (_, record) => {
                return record.is_size ? null : (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Цена:</strong> {formatCurrency(record.price_with_wb_discount)}</Text>
                        <Text><strong>Наценка:</strong> {formatPercent(record.nacenka_unit)}</Text>
                        <Text><strong>Маржинальность:</strong> {formatPercent(record.marzha_unit)}</Text>
                        <Text><strong>Прибыль:</strong> {formatCurrency(record.profit_unit)}</Text>

                    </Space>
                )
            }
        },
        {
            title: 'Показатели',
            width: 170,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    {record.is_size ? null :<Text><strong>ABC:</strong> {record.abc}</Text>}
                    <Text><strong>Оборачиваемость:</strong> {formatDays2(record.oborot_orders)}</Text>
                    <Text><strong>Запас дней:</strong> {formatDays2(record.oborot_buyouts)}</Text>
                </Space>
            )
        },
        {
            title: 'Заказы',
            width: 170,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Заказы в день:</strong> {formatQuantity(record.orders_per_day)}</Text>
                    <Text><strong>Выкупы в день:</strong> {formatQuantity(record.buyouts_per_day)}</Text>
                    <Text><strong>Процент выкупа:</strong> {formatPercent(record.buyout_percent)}</Text>
                </Space>
            )
        },


        {
            title: 'Расходы на единицу',
            width: 170,
            render: (_, record) => {
                return record.is_size ? (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Логистика:</strong> {formatCurrency(record.delivery_cost_unit)}</Text>
                    </Space>
                    ) : (

                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Логистика:</strong> {formatCurrency(record.delivery_cost_unit)}</Text>
                        <Text><strong>Хранение:</strong> {formatCurrency(record.store_cost_unit)}</Text>
                        <Text><strong>Маркетинг:</strong> {formatCurrency(record.budget_unit)}</Text>
                        <Text><strong>Себес итого:</strong> {formatCurrency(record.sebes_itog)}</Text>
                    </Space>
                )
            }
        },
        {
            title: 'Реклама',
            width: 250,
            render: (_, record) => {
                return record.is_size ? null : (
                    <Space size={12} className="cost-info-cell" style={{width: '100%',}}>
                        <Space direction="vertical" size={0}>
                            <Text><strong>Бюджет:</strong> {formatCurrency(record.budget)}</Text>
                            <Text><strong>Показы:</strong> {formatQuantity(record.views)}</Text>
                            <Text><strong>Клики:</strong> {formatQuantity(record.clicks)}</Text>
                            <Text><strong>Корзина:</strong> {formatQuantity(record.add_to_cart)}</Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                            <Text><strong>Заказы:</strong> {formatQuantity(record.adv_orders)}</Text>
                            <Text><strong>CTR:</strong> {formatPercent(record.ctr)}</Text>
                            <Text><strong>CPC:</strong> {formatPercent(record.cpc)}</Text>
                            <Text><strong>ДРР:</strong> {formatPercent(record.drr)}</Text>
                        </Space>
                    </Space>
                )
            }
        },

    ]
}

const expandData = (cards) => {
    return cards.map((card) => {
        card['children'] = []
        for (let barcode in card.by_barcode) {
            let item = card.by_barcode[barcode]
            item.category = card.category
            item.material = card.material
            item.model = card.model
            item.color = card.color
            item.abc = card.abc
            item.sezon = card.sezon
            item.status = card.status
            item.article = card.article
            item.is_size = true
            card['children'].push(item)
        }
        return card
    })
}

export default function TableAnal({cards, loading, isOzon, is_our}) {
    let columns = getColumns(isOzon, is_our)

    let DataSource = cards
    if (cards) {
        DataSource = expandData(cards)
    }

    return (
        <>

            <Table
                columns={columns}
                dataSource={DataSource}
                size={'small'}
                className='adverts-table2'
                scroll={{
                    y: 800
                }}
                rowClassName={(record, index) => `my-custom-row ${record.is_nm_id_only ? 'border_top' : ''}`}
                loading={loading}
                pagination={{defaultPageSize: 30, showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`}}
            />
        </>
    )
}
